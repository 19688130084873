import React from 'react'

export default function IconoMapa() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <g
                id="Grupo_2554"
                transform="translate(-510.998 -782.994)"
            >
                <path
                    id="Trazado_2449"
                    class="st0"
                    d="M588.9,823.9l-6.8-6.8c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l0,0l-6,6l0,0l-15.5,15.5   c-0.2,0.2-0.4,0.5-0.4,0.9l-1.3,8.1c-0.1,0.9,0.4,1.7,1.3,1.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l8.1-1.3   c0.3-0.1,0.6-0.2,0.9-0.4l21.6-21.5C589.5,825.5,589.5,824.5,588.9,823.9L588.9,823.9z M565.5,845.1L560,846l0.9-5.5l14-14l4.6,4.6   L565.5,845.1z M581.7,828.9l-4.6-4.6l3.8-3.8l4.6,4.6L581.7,828.9z"
                ></path>
                <path
                    id="Trazado_2450"
                    class="st0"
                    d="M581.2,837.1c-0.9,0-1.6,0.7-1.6,1.6v21.2h-43.9v-53.7h43.9v6.6c0,0.9,0.7,1.6,1.6,1.5   c0.8,0,1.5-0.7,1.5-1.5v-8.2c0-0.9-0.7-1.6-1.6-1.6c0,0,0,0,0,0h-47c-0.9,0-1.6,0.7-1.6,1.6v56.9c0,0.9,0.7,1.6,1.6,1.6h47   c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0v-22.7C582.8,837.8,582.1,837.1,581.2,837.1L581.2,837.1z"
                ></path>
                <path
                    id="Trazado_2453"
                    class="st0"
                    d="M551,845.3l-6,6l-2.4-2.4c-0.6-0.6-1.6-0.6-2.2,0l0,0c-0.6,0.6-0.6,1.6,0,2.2l0,0l3.5,3.5   c0.6,0.6,1.6,0.6,2.2,0c0,0,0,0,0,0l7.1-7.1c0.6-0.6,0.6-1.6,0-2.2l0,0C552.6,844.7,551.6,844.7,551,845.3L551,845.3z"
                ></path>
                <path
                    id="Trazado_2453-2"
                    class="st0"
                    d="M551,829.5l-6,6l-2.4-2.4c-0.6-0.6-1.6-0.6-2.2,0l0,0c-0.6,0.6-0.6,1.6,0,2.2l0,0l3.5,3.5   c0.6,0.6,1.6,0.6,2.2,0c0,0,0,0,0,0l7.1-7.1c0.6-0.6,0.6-1.6,0-2.2l0,0C552.6,828.8,551.6,828.8,551,829.5L551,829.5z"
                ></path>
                <path
                    id="Trazado_2453-3"
                    class="st0"
                    d="M551,813.6l-6,6l-2.4-2.4c-0.6-0.6-1.6-0.6-2.2,0l0,0c-0.6,0.6-0.6,1.6,0,2.2l0,0l3.5,3.5   c0.6,0.6,1.6,0.6,2.2,0l7.1-7.1c0.6-0.6,0.6-1.6,0-2.2l0,0C552.6,813,551.6,813,551,813.6L551,813.6z"
                ></path>
            </g>
        </svg>
    )
}
const mapStyle = [
  {
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#264b53"
      },
      {
        weight: 0.5
      }
    ]
  },
  {
    featureType: "landscape.man_made",
    stylers: [
      {
        color: "#d7e9ed"
      }
    ]
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        color: "#afd3da"
      }
    ]
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape.natural",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.attraction",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.business",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#afd3da"
      }
    ]
  },
  {
    featureType: "poi.business",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.government",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.medical",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#81b9c5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#81b9c5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#81b9c5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#305c63"
      }
    ]
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.school",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "poi.sports_complex",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#afd3da"
      }
    ]
  },
  {
    featureType: "poi.sports_complex",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#31616a"
      }
    ]
  },
  {
    featureType: "poi.sports_complex",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#32636d"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.text",
    stylers: [
      {
        color: "#19373e"
      },
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#96c5cf"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#7ab6c3"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#afd3da"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#264b53"
      }
    ]
  },
  {
    featureType: "water",
    stylers: [
      {
        color: "#a0cbd4"
      }
    ]
  }
];

export default mapStyle;
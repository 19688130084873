import {
  combineReducers
} from 'redux';
import eventsReducer from '../reducers/events';
import notesReducer from '../reducers/notes';
import sectionReducer from '../reducers/sections';
import testReducer from '../reducers/test';
import peopleReducer from '../reducers/people';
import mapsReducer from '../reducers/maps';
import resourcesReducer from '../reducers/resources';
import slidersReducer from '../reducers/sliders';
import countryReducer from '../reducers/country';
import user from './user'
import plan from './plan';
import menu from './menu'


export default combineReducers({
  events: eventsReducer,
  notes: notesReducer,
  sections: sectionReducer,
  test: testReducer,
  people: peopleReducer,
  maps: mapsReducer,
  country: countryReducer,
  user,
  resourcesReducer,
  plan,
  slidersReducer,
  menu
});
import React from "react";
import { connect } from "react-redux";
import Evaluacion from "../components/Evaluacion";

class EvaluacionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.resultTest = this.resultTest.bind(this);
  }


  resultTest() {
    this.props.history.push("/resultado-test");
  }

  render() {
    return (
      <Evaluacion
        resultTest={this.resultTest}
        props={this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(EvaluacionContainer);

import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { toggleMenuRecursos } from '../store/actions';

export default function RecursosButton(props) {

    const subMenuRecursosClass = useSelector((state) => state.menu.subMenuRecursos, shallowEqual)


    const dispatch = useDispatch();


    const handleToggleMenuRecursos = () => {
        dispatch(toggleMenuRecursos(subMenuRecursosClass))
    }

    return (
        <div>
            {props.entrenamiento ? (
                <img
                    onClick={handleToggleMenuRecursos}
                    className="boton-recursos"
                    src={subMenuRecursosClass == 'sub-menu-recursos' ? "/img/boton-recursos-3.svg" : "/img/boton-recursos-2.svg"} alt="" />
            ) : (
                    <img
                        onClick={handleToggleMenuRecursos}
                        className="boton-recursos"
                        src={subMenuRecursosClass == 'sub-menu-recursos' ? "/img/boton-recursos.svg" : "/img/boton-recursos-1.svg"} alt="" />
                )}
        </div>
    )
}
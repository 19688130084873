import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import Menu from './Menu';
import Button from './ButtonMenu';
import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL, URL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser';
import { tracking } from '../store/actions/user'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'


export default function DiarioAsmaZero(props) {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)
  const selectedCountry = useSelector((state)=> state.country.selectedCountry)

  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);

  const notasDiario = useSelector((state) => state.notes.notes.filter((note) => note['id_section'] == '24' && note[selectedCountry.toLowerCase()] == '1'))

  const testimoniosArray = useSelector((state) =>
    state.notes.notes.filter((note) => note.id_section == 25 && note[selectedCountry.toLowerCase()] == '1'),
    shallowEqual
  );

  let idRandom = Math.floor(Math.random() * testimoniosArray.length);
  let testimonioRandom = testimoniosArray[idRandom];

  useEffect(() => {
    if (userState) {
      tracking(user['user_id'], '12', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/diario-asma-zero")

  }, [])


  return (
    <div className={internaClass}>
      <Helmet>
        <title>Diario Asma Zero</title>
        <meta name="description" content="Diario Asma zero" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            <Link style={{ textDecoration: 'none', color: '#35aea9' }} to="/">
              ASMA ZERO /{' '}
            </Link>{' '}
            DIARIO ASMA ZERO
          </p>
          <h1 className="text-center">Diario Asma Zero</h1>
        </div>
      </main>

      <section className="container diario-asmazero-home">
        <div className="container-diario row">
          <div className="diario-testimonios col-md-9">
            {notasDiario.slice(0, 1).map((nota) => (
              <div className="nota-diario">
                <img src={`${API_MATERIAL}/notes/media/${nota['file']}`} alt="" />
                <p className="name texto-bordo">{nota['author']['name']}</p>
                <Link
                  className="link"
                  to={`/nota/${nota['slug']}`}
                >
                  <h2 className="diario-title" >{nota['name']}</h2>
                </Link>
                <p className="description">
                  {ReactHtmlParser(nota['copete'])}
                </p>
              </div>
            ))}
            {testimonioRandom && (
              <div>
                <div className="testimonios-en-diario">
                  <div className="imagenes">
                    <img
                      className="testimonio-img-1"
                      src={`${API_MATERIAL}/notes_authors/big/${testimonioRandom['author']['file']}`}
                      alt=""
                    />
                    <img
                      className="testimonio-img-2"
                      src="/img/fondo-testimonios.png"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h2 className="frase">
                      A pulmón — “Historias
                      reales en 60 segundos”
                </h2>
                    <div className="name">
                      <img src="/img/barra-titulo-testimonios.svg" alt="" />
                      <Link
                        to={`/testimonios/${testimonioRandom['author']['name']}`}
                      >
                        <h5 className="testimonio-author-name" >{testimonioRandom['author']['name']}</h5>
                      </Link>
                    </div>
                    <p className="copete">{ReactHtmlParser(testimonioRandom['detail'])}</p>
                  </div>
                </div>
                <Link
                  className="mas-testimonios texto-verde"
                  to="/testimonios"
                >
                  + TESTIMONIOS
              </Link>
              </div>
            )}
          </div>
          <div className="col-md-3">

            <div className="mas-notas-diario">

              <div className="header">
                <h4 className="texto-bordo" >MÁS <span className="bold" >NOTAS</span></h4>
                <img src="/img/mas-notas-barra.svg" alt="" />
              </div>

              <div className="mas-notas">
                {notasDiario.slice(1).map((note) => (
                  <div className="note">
                    <img src={`${API_MATERIAL}/notes/media/${note['file']}`} alt="" />
                    <p className="texto-bordo">{note.author.name}</p>
                    <Link
                      style={{
                        color: '#10144b',
                      }}
                      to={`/nota/${note['slug']}`}>
                      <h5>{note.name}</h5>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {testimonioRandom && (
          <Modal
            className="modal-evaluacion"
            size="lg"
            show={show}
            onHide={handleClose}>
            <Modal.Body className="modal-evaluacion-body" closeButton>
              {ReactHtmlParser(testimonioRandom['video'])}
            </Modal.Body>
          </Modal>
        )}
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ModalIngresar from './ModalIngresar';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { logout, tracking } from '../store/actions/user';
import { AUTH_URL } from '../store/constants';
import { readCookie } from '../components/Cookie'
import Perfil from './Perfil';
import TagManager from 'react-gtm-module'
import { setSelectedCountry } from '../store/actions/country';

const initArgs = {
  gtmId: 'GTM-K46DRV3'
}

const tagManagerArgs = {
  dataLayer: {
    page: window.location.href
  },
  dataLayerName: 'PageDataLayer'
}


export default function NavbarInterna(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalPerfil, setModalPerfil] = React.useState(false);
  const userState = useSelector((state) => state.user.authState);
  const user = useSelector((state) => state.user.authData);
  const country = useSelector((state)=> state.country, shallowEqual);
  const dispatch = useDispatch();
  const handleSelect = (eventKey) => {
    if (eventKey == '1') {
      if (props.props.history) {
        props.props.history.push('/');
      } else {
        props.props.props.history.push('/');
      }
      dispatch(logout());
      tracking(user['user_id'], '4', '', user['name'], '')
    } else {
      setModalPerfil(true);
    }
  };
  const gotoLogin = () => {
    window.location = AUTH_URL;
    // console.log('redirecto to: ', AUTH_URL); 
  };

  let cookieToken = readCookie('azmed_user_token')



  useEffect(() => {
    TagManager.initialize(initArgs)
    TagManager.dataLayer(tagManagerArgs)
    //ReactGa.pageview(window.location.href)
  }, [])

  const handleSelectedCountry = (e) =>{
    dispatch(setSelectedCountry(e.target.id))
  }

  return (
    <div>
      <nav>
        <div className="navbar-left">
          <Link to="/" className="logo" style={{paddingTop:'2rem'}}>
            {' '}
            <img
              src="/img/logo-asmazero-blanco.png"
              className="logo-blanco"></img>{' '}
            <img src="/img/logo-asmazero-color.png" className="logo-color"></img>{' '}
          </Link>
        </div>
        {/* {userState && cookieToken ? (
          <div className="navbar-right interna">
            <img className="icono-user" src="/img/icono-user.png" alt="" />
            <a className="enfermeria texto-azul" href="#">
              <NavDropdown
                onSelect={handleSelect}
                title={user.given_name}
                id="nav-dropdown">
                <NavDropdown.Item eventKey="2">Perfil</NavDropdown.Item>
                <NavDropdown.Item eventKey="1">Salir</NavDropdown.Item>
              </NavDropdown>
            </a>
          </div>
        ) : (
            <div
              onClick={() => setModalShow(true)}
              className="navbar-right interna">
              <img className="icono-user" src="/img/icono-user.png" alt="" />
              <a className="enfermeria texto-azul" href="#">
                {' '}
            Ingresar{' '}
              </a>
            </div>
          )}

        <Perfil show={modalPerfil} onHide={() => setModalPerfil(false)} /> */}
        {/* <ModalIngresar
          ingresar={true}
          show={modalShow}
          onHide={() => setModalShow(false)}
        /> */}
        {/* <div className="container row flagContainer" style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%'}}>
            <div className="flags">
              <img src="/img/flag-col.png" alt="Colombia" className={`${country.selectedCountry == "CO" ? 'selectedFlag' : ''}`} width={30} height={20} onClick={handleSelectedCountry} id="CO"/>
              <p>CO</p>
            </div>
            <div className="flags">
              <img src="/img/flag-ec.png" alt="Ecuador" className={`${country.selectedCountry == "EC" ? 'selectedFlag' : ''}`}  width={30} height={20} onClick={handleSelectedCountry} id="EC"/>
              <p>EC</p>
            </div>
            <div className="flags">
              <img src="/img/flag-peru.png" alt="Colombia" className={`${country.selectedCountry == "PE" ? 'selectedFlag' : ''}`} width={30} height={20} onClick={handleSelectedCountry} id="PE"/>
              <p>PE</p>
            </div>
          </div> */}
      </nav>
    </div>
  );
}

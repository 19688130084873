import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import mapStyle from '../mapaStylesheets';
import '../Marker.css';
import useSupercluster from 'use-supercluster';
const Marker = ({ children }) => children;

const Pin = ({ item, itemSelect, setMarkerSelect }) => {
  let selected = itemSelect && itemSelect === item.id_map;
  let className = selected ? 'pin selected' : 'pin';
  return (
    <>
      {selected && (
        <div className="information">
          <p className="nombre">{item.name}</p>
          <p className="datos">
            <a href={`https://www.google.com.ar/maps/dir//${item.latitude},${item.length}/`} target="_blank">{[item.address, item.location, item.province].join(', ')}</a>
            <br></br>
            {!!item.postal_code ? 'C.P. ' + item.postal_code : ''}
            <br></br>
            {item.phone ? <a href={`tel:${item.phone}`}>Tel: {item.phone}</a> : ''}
          </p>
        </div>
      )}
      <img
        src={`/img/mapa/pin-${item.id_map_category}.png`}
        alt={item.name}
        className={className}
      />
    </>
  );
};

const MapaComponent = (props) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const points = props.markers.map((item) => ({
    type: 'Feature',
    properties: { cluster: false, ...item },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(item.length), parseFloat(item.latitude)],
    },
  }));
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 14 },
  });
  const mapOptions = {
    styles: mapStyle,
  };
  // console.log('MapaComponent - clusters', zoom, clusters, props.markers);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBCoQENQI5QHYoYnBs6r98FZF0zSPzXCCg' }}
      center={[
        Number(props.region?.center?.lat),
        Number(props.region?.center?.lng),
      ]}
      defaultZoom={14}
      options={mapOptions}
      yesIWantToUseGoogleMapApiInternals
      onChange={({ center, zoom, bounds, marginBounds }) => {
        console.log('MapaComponent - onChange');
        setZoom(zoom);
        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        props.setRegion({ center, zoom, bounds, marginBounds });
      }}
      onChildClick={(item) => {
        console.log('MapaComponent - onChildClick', item);
        props.setMarkerSelect(item);
      }}
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}>
      {clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: pointCount,
        } = cluster.properties;
        // console.log('MapaComponent - isCluster', isCluster, pointCount);

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}>
              <div
                className="cluster-marker"
                style={{
                  width: `${10 + (pointCount / points.length) * 20}px`,
                  height: `${10 + (pointCount / points.length) * 20}px`,
                }}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20,
                  );
                  mapRef.current.setZoom(expansionZoom);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}>
                {pointCount}
              </div>
            </Marker>
          );
        }

        return (
          <Pin
            key={cluster.properties.id_map}
            item={cluster.properties}
            itemSelect={props.markerSelect}
            lat={cluster.properties.latitude}
            lng={cluster.properties.length}
          />
        );
      })}
    </GoogleMapReact>
  );
};

export default MapaComponent;

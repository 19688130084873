import React from 'react'
import MenuPrincipal from './MenuPrincipal'
import SubMenuEducacion from './SubMenuEducacion'
import SubMenuHerramientas from "./SubMenuHerramientas";
import SubMenuEntrenamiento from "./SubMenuEntrenamiento";
import SubMenuQuienesSomos from "./SubMenuQuienesSomos";
import SubMenuDiario from "./SubMenuDiario";
import SubMenuAsmaE from './SubMenuAsmaE';
import { connect } from 'react-redux';
import { setMenuClass } from '../store/actions'


class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }
    render() {
        return (
            <div>
                <MenuPrincipal
                    sections={this.props.sections}
                />
                <SubMenuEducacion
                    sections={this.props.sections}
                />
                <SubMenuHerramientas
                    sections={this.props.sections}
                />
                <SubMenuQuienesSomos
                    sections={this.props.sections}
                />
                <SubMenuDiario
                    sections={this.props.sections}
                />
                {/* <SubMenuEntrenamiento
                /> */}
                <SubMenuAsmaE
                    sections={this.props.sections}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sections: state.sections.sections,
})

const mapDispatchToProps = (dispatch) => ({
    setMenuClass: (data) => dispatch(setMenuClass(data))
})

export default connect(mapStateToProps, null)(Menu)
import React from "react";
import { connect } from "react-redux";
import Mapa from "../components/Mapa";

class MapaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Mapa
        props={this.props}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(null, null)(MapaContainer);

import {
  SET_DESPERTAR,
  SET_LIMITACION,
  SET_SINTOMAS,
  SET_NECESIDAD,
  RESET_TEST,
  SET_DESTINATARIO
} from "../constants";

export const setSintomas = (boolean) => ({
  type: SET_SINTOMAS,
  boolean
});

export const setDespertar = (boolean) => ({
  type: SET_DESPERTAR,
  boolean
});

export const setNecesidad = (boolean) => ({
  type: SET_NECESIDAD,
  boolean
});

export const setLimitacion = (boolean) => ({
  type: SET_LIMITACION,
  boolean
});

export const setDestinatarioRedux = (destinatario) => ({
  type: SET_DESTINATARIO,
  destinatario
});

export const resetTest = () => ({
  type: RESET_TEST,
  payload: null,
});
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AUTH_URL, AUTH_URL_REGISTER } from '../store/constants';
Amplify.configure(awsconfig);

export default function ModalIngresar(props) {
  const urlBack = useSelector((state) => state.user.urlBack);

  let [isLogin, setIsLogin] = useState(false);
  let [terminosClass, setTerminosClass] = useState('terminos-active');
  // console.log('ModalIngresar - ', terminosUso, terminosUsoAZ);

  //URL DEL AUTH DE ASTRAZENECA
  const URL = AUTH_URL + encodeURIComponent(urlBack);
  const URL_REGISTER = AUTH_URL_REGISTER + encodeURIComponent(urlBack);

  const singin = () => {
    window.location = URL;
    // console.log('redirecto to: ', AUTH_URL);
  };

  const singUp = () => {
    window.location = URL_REGISTER;
    // console.log('redirecto to: ', AUTH_URL_REGISTER);
  };


  useEffect(() => {
    setIsLogin(true);
    setTerminosClass('terminos-disabled');
  }, []);

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="ingresar-modal">
        <img src="/img/candado.svg" alt="" />
        <div className="container">
          <p className="miga-de-pan texto-verde">
            ASMA ZERO / {props.ingresar ? 'INGRESAR' : 'CONTENIDO EXCLUSIVO'}
          </p>
          {props.ingresar ? (
            <h1>
              Acceso exclusivo para <br></br> Profesionales de la Salud
            </h1>
          ) : (
              <h1>
                Contenido exclusivo para <br></br> Profesionales de la Salud.
              </h1>
            )}
          <p>
            Iniciá sesión con tu cuenta de AZMED o creá una cuenta desde AZMED y
            seguí navegando por Asma Zero para ver más contenidos.
          </p>

          <div className="container-buttons">
            <button type="submit" className="ingresar" onClick={() => singin()}>
              INGRESAR
            </button>
            <button
              type="submit"
              className="registrar"
              onClick={() => singUp()}>
              REGISTRARME
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

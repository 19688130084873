const readCookie = (o) => {
    for (var t = o + "=", e = document.cookie.split(";"), i = 0; i < e.length; i++) {
        for (var n = e[i];
            " " === n.charAt(0);) n = n.substring(1, n.length);
        if (0 === n.indexOf(t)) return n.substring(t.length, n.length)
    }
    return null
}

const setcookie = (o, t, e) => {
    var i = new Date();
    i.setSeconds(i.getSeconds() + 4 * e )
    document.cookie = o + "=" + t + ";path=/;expires=" + i.toGMTString()
}

const delcookie = (o) => {
    setcookie(o, "", -1)
}

export { readCookie, setcookie, delcookie }
import { FETCH_RESOURCES, API } from "../constants";
import axios from "axios";

const setResources = resources => ({
  type: FETCH_RESOURCES,
  resources
});

export const fetchResources = () => dispatch => {
  fetch(API)
    .then(res => res.json())
    .then(data => {
      // console.log("LA DATA DE LOS RECURSOS", data["notes_resources"]);
      dispatch(setResources(data["notes_resources"]));
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    });
};

import { FETCH_RESOURCES } from "../constants";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESOURCES:
      return Object.assign({}, state, {
        resources: action.resources
      });
    default:
      return state;
  }
};

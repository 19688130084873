import axios from "axios";
import { FETCH_PEOPLE, API } from "../constants";

const setPeople = people => ({
  type: FETCH_PEOPLE,
  people
});

export const fetchPeople = () => dispatch => {
  fetch(API)
    .then(res => res.json())
    .then(data => {
      dispatch(setPeople(data["sections_peoples"]));
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    });
};

import React, { useState, useEffect } from 'react';
import RedesIconos from './IconosRedesInterna';
import NavbarInterna from './NavbarInterna';
import MapaComponent from './MapaComponent';
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import { useSelector, shallowEqual } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Autocomplete from 'react-google-autocomplete';
import _ from 'lodash';
import { URL } from '../store/constants'
import { tracking } from '../store/actions/user'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'


const marketCat = {
  '1': 'Neumonólogos',
  '2': 'Alergistas',
  '3': 'Instituciones',
};

const parseLatLng = (value, fixed = 5) =>
  Number.parseFloat(value).toFixed(fixed);
const generateRegion = ({ latitude, longitude }) => ({
  center: {
    lat: latitude,
    lng: longitude,
  },
  bounds: {
    ne: { lat: latitude - 0.025, lng: longitude - 0.025 },
    nw: { lat: latitude - 0.025, lng: +longitude + +0.025 },
    se: { lat: +latitude + +0.025, lng: longitude - 0.025 },
    sw: { lat: +latitude + +0.025, lng: +longitude + +0.025 },
  },
});
export default function Mapa(props) {

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const selectedCountry = useSelector((state)=>state.country.selectedCountry,shallowEqual)
  
  const setCountry = () =>{
    switch (selectedCountry) {
      case "CO":
        return {
            latitude: 4.670854285606273,
            longitude: -74.09740264696222
        }
        break;
      case "EC":
        return {
          
          latitude:-0.24082119357330267,
          longitude: -78.49910341086805
          
        }
        break;
      case "PE":
        return {
          
          latitude:-12.0780783741817,
          longitude: -77.029469791063
          
        }
        break;
    }
  }

  const country = setCountry();


  const [filter, setFilter] = useState(false);
  const [markerSelect, setMarkerSelect] = useState(false);
  const [search, setSearch] = useState(false);
  const [regionCountry, setRegionCountry] = useState(country)
  const [region, setRegion] = useState(
    generateRegion(regionCountry),
  );
  if (filter === 'false') setFilter(false);

  useEffect(()=>{
    if( (selectedCountry != "") || (selectedCountry != null) ){
      switch (selectedCountry) {
        case "CO":
          setRegion({...region,
            center:{
            lat: 4.670854285606273,
            lng: -74.09740264696222}
          })
          break;
        case "EC":
          setRegion({...region,
            center:{
              lat:-0.24082119357330267,
              lng: -78.49910341086805
            }
          })
          break;
        case "PE":
          setRegion({...region,
            center:{
              lat:-12.0780783741817,
              lng: -77.029469791063
            }
          })
          break;
      }
    }
  },[selectedCountry])
  

  let { maps } = useSelector(({ maps }) => maps, shallowEqual);
  let mapsFiltered = maps.filter(loc => loc.country === selectedCountry)

  let markers = mapsFiltered ? mapsFiltered : [],
    bounds = region.bounds,
    boundLat = [bounds.ne.lat, bounds.nw.lat, bounds.se.lat, bounds.sw.lat],
    boundLng = [bounds.ne.lng, bounds.nw.lng, bounds.se.lng, bounds.sw.lng],
    maxLat = _.max(boundLat),
    minLat = _.min(boundLat),
    maxLng = _.max(boundLng),
    minLng = _.min(boundLng);
  // Filter by Category
  markers =
    filter !== false && markers.length > 0
      ? markers.filter(
        ({ id_map_category }) => id_map_category === String(filter),
      )
      : markers;
  // Search
  let markersResult =
    search.length > 1 && markers.length > 0
      ? markers.filter(
        ({ name, address, province }) =>
          name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          (address &&
            address.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (province &&
            province.toLowerCase().indexOf(search.toLowerCase()) !== -1),
      )
      : markers;
  // Filter by view
  // markers =
  //   !!search === false && markers.length > 0
  //     ? markers.filter((m) => {
  //       let lat = parseLatLng(m.latitude);
  //       let lng = parseLatLng(m.length);
  //       return minLat < lat && lat < maxLat && minLng < lng && lng < maxLng;
  //     })
  //     : markers;
  // markers = markers.length > 200 ? markers.slice(0, 200) : markers;
  // markersResult =
  //   markersResult.length > 40 ? markersResult.slice(0, 40) : markersResult;
  const geolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setRegion({
          ...region,
          center: {
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          },
        });
      });
    }
  };
  // console.log('Mapa Page - markers', markers.length);
  // console.log('Mapa Page - markersResult', markersResult.length);
  useEffect(() => {
    if (userState) {
      tracking(user['user_id'], '33', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/mapa")

  }, [])
  return (
    <div className={internaClass}>
      <Helmet>
        <title>Mapa Asma Zero</title>
        <meta name="description" content="Mapa Asma Zero" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            HERRAMIENTAS / MAPA ASMA ZERO
          </p>
          <h1 className="text-center">Mapa Asma Zero</h1>
          <p className="descripcion">
            Encontrá un especialista cerca tuyo. Sugerimos que antes de
            dirigirse personalmente a cualquiera de los profesionales o
            instituciones se realice un contacto telefónico o electrónico para
            prevenir cualquier inexactitud en la información.
          </p>
        </div>
      </main>
      <section className="mapa margin-bottom-7">
        <div className="container">
          <div className="row mapa-filtros margin-bottom-4">
            {/* <div className="col-md-4 mb-1">
              <div className="dropdown w-100">
                <NavDropdown
                  onSelect={setFilter}
                  title={filter !== false ? marketCat[filter] : 'Filtros'}
                  id="nav-dropdown">
                  <NavDropdown.Item eventKey={false}>
                    Sin Filtros
                  </NavDropdown.Item>
                  {Object.keys(marketCat).map((i) => (
                    <NavDropdown.Item eventKey={i}>
                      {marketCat[i]}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </div>
            </div> */}

            {/* <div className="col-md-4 mb-1">
              <div className="form-group m-0">
                <input
                  type="search"
                  className="localizacion form-control fondo-gris"
                  id="exampleInputPassword1"
                  placeholder="Buscar"
                  onChange={(event) =>
                    event.target.value == ''
                      ? setSearch(false)
                      : setSearch(event.target.value)
                  }></input>{' '}
                <Autocomplete
                  className="localizacion form-control"
                  placeholder="Buscar por ubicación"
                  onPlaceSelected={(place) => {
                    if (place.geometry) {
                      setRegion(
                        generateRegion({
                          latitude: place.geometry.location?.lat(),
                          longitude: place.geometry.location?.lng(),
                        }),
                      );
                    }

                    // console.log(place);
                  }}
                  types={['address']}
                  componentRestrictions={{ country: 'ar' }}
                />
              </div>
            </div>
            <div className="col-md-3 mb-1">
              <button
                type="button"
                className="btn ubicacion w-100  fondo-gris"
                onClick={() => geolocation()}>
                Usar ubicación actual
              </button>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-4">
              <ul className="lista">
                {(!!search ? markersResult : markers).map((item) => (
                  <li className="profesional" key={item.id_map}>
                    <p className="nombre">{item.name}</p>
                    <p className="datos" style={{marginBottom:'1rem'}}>
                      {[item.address, item.location, item.province].join(', ')}{' '}
                      <br></br>
                      {!!item.postal_code ? 'C.P. ' + item.postal_code : ''}
                      <br></br>
                      {item.phone ? 'Tel: ' + item.phone : ''}
                      <br></br>
                      
                    </p>
                    <a
                      href="#"
                      className="ubicacion"
                      style={{marginTop:'0.5rem'}}
                      onClick={(event) => {
                        event.preventDefault();
                        setMarkerSelect(item.id_map);
                        setRegion(
                          generateRegion({
                            latitude: parseLatLng(item.latitude),
                            longitude: parseLatLng(item.length),
                          }),
                        );
                      }}>
                      Ver ubicación
                    </a>
                    <br></br>
                    <a href={`https://www.google.com.ar/maps/dir//${item.latitude},${item.length}/`} target="_blank">Cómo ir</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-8 col-mapa mapa-contenedor">
              <MapaComponent
                region={region}
                setRegion={setRegion}
                markers={!!search ? markersResult : markers}
                markerSelect={markerSelect}
                setMarkerSelect={setMarkerSelect}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer codigo={codigoPacientes} fondoAzul={true}/>
    </div>
  );
}

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import NavbarInterna from "./NavbarInterna";
import RedesIconos from "./IconosRedesInterna";
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from "./Footer";
import { codigoPacientes } from "../codigos";
import ShareButton from "./ShareButtonComponent";
import { Element, scroller } from "react-scroll";
import { useSelector, shallowEqual } from 'react-redux'
import { API_MATERIAL, API_BACKEND } from '../store/constants'
import { tracking } from '../store/actions/user'



export default function Evaluacion(props) {
  const test = useSelector((state) => state.test, shallowEqual);
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)
  const destinatario = useSelector((state) => state.test.destinatario)


  let testCount = function (object) {
    let num = 0;
    for (const key in object) {
      if (object[key] == true) {
        num += 1;
      }
    }
    return num;
  };

  let count = testCount(props.test);
  let img;
  let control;
  let asmaZero = "www.AsmaZero.com.ar";
  let url;

  if (count === 0) {
    img = "img/buen-control.png";
    url = "https://asmazero.com.ar/img/buen-control.png";
    control = "Buen control del Asma";
  } else if (count > 0 && count < 3) {
    img = "img/control-parcial.png";
    url = "https://asmazero.com.ar/img/control-parcial.png";
    control = "Control parcial del Asma";
  } else {
    img = "img/mal-control.png";
    url = "https://asmazero.com.ar/img/mal-control.png";
    control = "Control insuficiente del Asma";
  }

  if (userState) {
    if (destinatario === 'personal') {
      tracking("", '34', '', "", window.location.href, "", control)
    } else if (destinatario === 'tercero') {
      tracking(user['user_id'], '34', '', user['name'], window.location.href, "", control)
    }
  }

  useEffect(() => {
    if (
      test.sintomas == null ||
      test.limitacion == null ||
      test.necesidad == null ||
      test.despertar == null
    ) {
      props.props.history.push("/evaluacion")
    }
    scroller.scrollTo("resultado");


  });

  return (
    <div className={internaClass}>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container-resultado">
          <p className="seccion text-center">
            HERRAMIENTAS / Evaluación de Control
          </p>
          <h1 className="text-center">Evaluación de Control de asma</h1>
        </div>
      </main>
      <Element name="resultado">
        <section id="resultado-test" className="resultado-test">
          <div className="row resultado-container">
            <div className="col-md-5">
              <div className="resultado-test-compartir">
                <p className="comparti-con-tu-medico texto-bordo">
                  Compartí el <br /> resultado con tu <br /> médico.
                </p>
                <img
                  src="img/frase-linea.svg"
                  className="frase-linea-compartir"
                ></img>
                <div className="compartir">
                  <ShareButton
                    email={true}
                    whatsapp={true}
                    resultadoControl={control}
                    resultadoImg={url}
                    url={control}
                    id="button-compartir"
                    subject="Evaluacion de Control del Asma"
                    quote="Hola, realicé la Evaluación de Control del asma en www.AsmaZero.com.ar y mi resultado fue: "
                  />
                  <p>Envía por Whatsapp o E-mail.</p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="fondo-test">
                <img className="fondo-cara" src={img} alt="" />

                <p className="resultado texto-verde">RESULTADO</p>

                <p className="resultado-azul texto-azul">{control}</p>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <section className="call-to-action">
        <div className="call-to-action-container">
          <div className="single-call-to-action responsive-call-mapa">
            <div className="image-container">
              <img src="/img/estetoscopio-icono.png" alt="" />
            </div>
            <div className="text-and-button">
              <p>Mostrale estos resultados a un Especialista.</p>
              <div className="call-button">
                <Link to="/mapa">
                  <button id="mapa-button">MAPA ASMA ZERO</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="single-call-to-action responsive-call-mapa">
            <div className="image-container">
              <img src="/img/wsp-icono-azul.png" alt="" />
            </div>
            <div className="text-and-button">
              <p>Conversá sobre estos resultados.</p>
              <div className="call-button">
                <a
                  href="https://api.whatsapp.com/send?phone=5491138703406&text=Hola%20Asma%20Zero"
                  target="_blank"
                >
                  <button id="mapa-button">ENFERMERÍA ONLINE</button>
                </a>
              </div>
            </div>
          </div>
          <div className="single-call-to-action responsive-call-mapa">
            <div className="image-container">
              <img src="/img/libro-azul-icono.png" alt="" />
            </div>
            <div className="text-and-button">
              <p>Aprendé más para mejorar estos resultados.</p>
              <div className="call-button">
                <Link to="/educacion">
                  <button id="mapa-button">EDUCACION</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-button-volver-realizar">
        <button onClick={props.repeatTest} className="">
          VOLVER A REALIZAR EL TEST
        </button>
        <p className="descripcion">
          Información basada en “GINA Assessment of Asthma Control". Disponible
          en www.ginasthma.com. Esta herramienta es utilizada para obtener una
          orientación respecto al nivel de control de la patología.{" "}
          <span style={{ fontWeight: "bold" }}>
            Esta no es una herramienta de diagnóstico. Siempre se debe consultar
            a un médico para obtener un diagnóstico o un nivel de control
            certero sobre la enfermedad.
          </span>
        </p>
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import Button from './ButtonMenu';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import MenuEntrenamiento from './MenuEntrenamiento';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { receiveValidUser, receiveMatch, tracking, setUrlBack } from '../store/actions/user'
import { readCookie } from '../components/Cookie'
import { API_MATERIAL, URL } from '../store/constants'
import Helmet from 'react-helmet'
import ReactHtmlParser from "react-html-parser";
import ReactGa from 'react-ga'


export default function HomeEntrenamiento(props) {

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const matchSection = props.match.params.slug
  const notes = useSelector((state) => state.notes.notes, shallowEqual)
  const resources = useSelector((state) => state.resourcesReducer.resources, shallowEqual);
  const sections = useSelector((state) => state.sections.sections, shallowEqual);
  const selectedSectionName = sections.filter((section) => section['slug'] == matchSection)
  const selectedSectionId = sections.filter((section) => section['slug'] == matchSection)

  const dispatch = useDispatch()


  let filterSection = function (array, id) {
    let arr = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i]['id_section'] == id) {
        arr.push(array[i]);
      }
    }
    return arr;
  };

  let setIdSection = function (matchParams) {
    let params = {
      id: 0,
      title: '',
    };
    if (matchParams === 'saba-transicion') {
      params.id = 37;
      params.title = 'Transición SABA a Rescate Anti-Inflamatorio';
    } else if (matchParams == 'rescate-y-mantenimiento') {
      params.id = 38;
      params.title = 'Rescate Anti-Inflamatorio & Mantenimiento';
    } else if (matchParams == 'simplificar-tratamiento-asma') {
      params.id = 39;
      params.title = 'Simplificar el Tratamiento del Asma';
    } else if (matchParams == 'manejo-de-crisis-asmatica') {
      params.id = 40;
      params.title = 'Manejo de Crisis Asmática';
    } else if (matchParams == 'educando-pacientes') {
      params.id = 41;
      params.title = 'Material Para Educar Pacientes';
    } else if (matchParams == 'recursos-para-profesionales') {
      params.id = 42;
      params.title = 'Recursos Para Profesionales';
    }
    return params;
  };

  let notesSection = filterSection(notes, setIdSection(matchSection)['id']);

  // VALIDO QUE ESTE REGISTRADO

  const readGet = (o) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  };
  let getCode = readGet('code');

  useEffect(() => {
    let cookieToken = readCookie('azmed_user_token')
    if (cookieToken == null && getCode == null) {
      dispatch(receiveValidUser(true))
      dispatch(setUrlBack(`${URL}entrenamiento-medico/${matchSection}`))
      props.history.push("/")
    }
    if (userState) {
      tracking(user['user_id'], selectedSectionId[0]['id_section'], '', user['name'], window.location.href)
    }
    ReactGa.pageview(`/entrenamiento-medico/${matchSection}`)

  }, [matchSection])

  return (
    <div className={internaClass}>
      <Helmet>
        <title>Asma Zero - Entrenamiento Médico</title>
        <meta name="description" content="Asma Zero - Entrenamiento Médico  " />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            <Link style={{ textDecoration: 'none', color: '#35aea9' }} to="/">
              ASMA ZERO /{' '}
            </Link>
            <Link
              style={{ textDecoration: 'none', color: '#35aea9' }}
              to="/"
              to="/entrenamiento-medico">
              ENTRENAMIENTO MÉDICO
            </Link>
          </p>
          {sections.length > 0 && (
            <h1 className="text-center">{selectedSectionName[0]['name']}</h1>
          )}
        </div>
      </main>

      <section className="container educacion-responsive-home diaro-asmazero-home">
        <div className="entrenamiento-home container-diario row">
          {matchSection == 'recursos-profesionales' ? (
            <div className="col-md-9">
              {resources && (
                <div className="row recurso-container">
                  {resources
                    .filter(
                      (recurso) =>
                        recurso.need_logged == '1' && recurso.status == 1,
                    )
                    .map((recurso) => (
                      <div
                        key={recurso.id_note_resource}
                        className="recurso col-12 col-xs-6 col-sm-6 col-md-4">
                        <img
                          className="imagen-recurso"
                          src={`${API_MATERIAL}/notes_resources/small/${recurso.photo}`}
                          alt=""
                        />
                        {recurso.url_canonical ? (
                          <div>
                            {recurso.url_canonical.length > 0 ? (
                              <a target="_blank" href={recurso.url_canonical}>
                                <h1 className="title">{recurso.name}</h1>
                              </a>
                            ) : (
                                <a
                                  download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                  target="_blank"
                                  href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}>
                                  <h1 className="title">{recurso.name}</h1>
                                </a>
                              )}
                          </div>
                        ) : (
                            <div>
                              <a
                                download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                target="_blank"
                                href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}>
                                <h1 className="title">{recurso.name}</h1>
                              </a>
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ) : (
              <div className="col-md-9">
                {notesSection.length > 0 && (
                  <div className="row">
                    {notesSection.map((nota) => (
                      <div key={nota.id_note} className="nota col-md-6">
                        {nota.author ? (
                          <Link to={`/entrenamiento/${nota['slug']}`}>
                            {nota.author.file ? (
                              <img
                                className="imagen-perfil-nota"
                                src={`${API_MATERIAL}/notes_authors/big/${nota.author['file']}`}
                                alt="una imagen"
                              />
                            ) : (
                                <img
                                  className="imagen-perfil-nota"
                                  src="/img/user-doctor.png"
                                  alt=""
                                />
                              )}
                          </Link>
                        ) : (
                            <Link to={`/entrenamiento/${nota['slug']}`}>
                              <img
                                className="imagen-perfil-nota"
                                src="/img/user-doctor.png"
                                alt=""
                              />
                            </Link>
                          )}
                        <div className="responsive-educacion-title">
                          <p className="name texto-bordo">
                            {nota.author ? nota.author.name : 'Asma Zero'}
                          </p>
                          <Link
                            style={{ textDecoration: 'none', color: '#10144b' }}
                            to={`/entrenamiento/${nota['slug']}`}>
                            <h1 className="educacion-title-nota" >{nota.name}</h1>
                          </Link>
                        </div>
                        <p>{ReactHtmlParser(nota.copete.slice(0, 150))}...</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          <div className="col-md-3">
            <MenuEntrenamiento
              notesSection={notesSection}
              matchSection={matchSection}
            />
          </div>
        </div>
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

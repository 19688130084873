import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { toggleNavigation } from '../store/actions'



export default function Button(props) {
    const dispatch = useDispatch();

    const buttonTogglerClass = useSelector((state) => state.menu.buttonTogglerClass, shallowEqual)
    const homeClass = useSelector((state) => state.menu.homeClass, shallowEqual)
    const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

    const handleToggleMenu = () => {
        dispatch(toggleNavigation(homeClass, internaClass))
    }

    return (
        <button
            id={props.id ? props.id : ''}
            onClick={handleToggleMenu}
            className={buttonTogglerClass}
            type="button"
            aria-controls="navbarHeader"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <div className="wrapper_button">
                <div id="nav-icon4" className="">
                    {' '}
                    <span></span> <span></span> <span></span>{' '}
                </div>
            </div>
        </button>
    )
}
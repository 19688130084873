import React from "react";
import { connect } from "react-redux";
import { testimoniosJSON } from "../json";
import SingleTestimonio from "../components/SingleTestimonio";

class SingleTestimonioContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let match = this.props.match.params.name;


    return (
      <SingleTestimonio
        props={this.props}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(null, null)(SingleTestimonioContainer);

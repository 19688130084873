import React from 'react';
import IconoAsma from './LineamientosIconos/IconoAsma'
import IconoEspirometria from './LineamientosIconos/IconoEspirometria'
import IconoEstetoscopio from './LineamientosIconos/IconoEstetoscopio'
import IconoMapa from './LineamientosIconos/IconoMapa'
import IconoPlan from './LineamientosIconos/IconoPlan'
import IconoPlanilla from './LineamientosIconos/IconoPlanilla'
import IconoTratamiento from './LineamientosIconos/IconoTratamiento'


export default function LineamientosResultado(props) {
  return (
    <div className="lineamientos-resultado-container">
      <h4 className="bold">{props.resultadoTexto}</h4>
      <div className="resultados">
        {props.resultados.map((item) => (
          <a href={item.url} target="_blank" >
            <div className="resultado">
              <div className="container-img">
                {item.icon == 'estetoscopio' && <IconoEstetoscopio />}
                {item.icon == 'mapa' && <IconoMapa />}
                {item.icon == 'planilla' && <IconoPlanilla />}
                {item.icon == 'tratamiento' && <IconoTratamiento />}
                {item.icon == 'plan' && <IconoPlan />}
                {item.icon == 'asma' && <IconoAsma />}
                {item.icon == 'espirometria' && <IconoEspirometria />}
              </div>
              <p>{item.title}</p>

            </div>
          </a>
        ))}
      </div>
      <div className="button-container">

        <div className="lineamientos-button-terminar">
          <a href="/herramientas/lineamientos-diagnostico">
            <button >FINALIZAR DIAGNÓSTICO</button>
          </a>
        </div>
      </div>
    </div>
  );
}


import React, { useState, useEffect } from 'react';
import ShareButton from './ShareButtonComponent';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { receiveValidUser, tracking, setUrlBack } from '../store/actions/user'
import { readCookie } from '../components/Cookie';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import ButtonCalendarComponent from './ButtonCalendar';
import { URL } from '../store/constants'
import ReactGa from 'react-ga'


const SingleEvento = (props) => {
  const evento = props.evento;
  const dispatch = useDispatch();
  const [showVivo, setShowVivo] = useState(false);
  const [inscribirseModal, setInscribirseModal] = useState(false);
  const user = useSelector((state) => state.user.authState, shallowEqual);
  const userData = useSelector((state) => state.user.authData, shallowEqual);


  const InitialDatos = {
    email: '',
    nombre: '',
    apellido: '',
    formName: 'evento',
  };

  const [datos, setDatos] = useState(InitialDatos);
  const [datosEnviados, setDatosEnviados] = useState(false);
  const [datosClases, setDatosClases] = useState({
    nombre: 'disabled',
    email: 'disabled',
    apellido: 'disabled',
  });

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const handleDatosClases = (string, key) => {
    let val = string;
    setDatosClases((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  // REQUIERO TODOS LOS CAMPOS Y SI ESTAN OK ENVIO LOS DATOS
  const requerirCampos = () => {
    let camposIncompletos = 0;
    let keys = ['nombre', 'email', 'apellido'];
    keys.map((key) => {
      if (datos[key] === '') {
        handleDatosClases('active', key);
        camposIncompletos++;
      } else {
        handleDatosClases('disabled', key);
      }
    });
    if (camposIncompletos == 0) {
      setDatosEnviados(true);
      // console.log("enviando datos: ", datos, evento);
      axios.post(
        'https://pd3i92cpeb.execute-api.us-east-1.amazonaws.com/prod/sendmail',
        { datos, evento },
      );
    }
  };

  //MODAL DE INSCRIPCION Y VER VIVO
  const handleCloseVivo = () => setShowVivo(false);
  const handleShowVivo = () => setShowVivo(true);
  const handleCloseInscribirse = () => {
    setInscribirseModal(false);
    setDatosEnviados(false);
  };
  const handleShowInscribirse = () => setInscribirseModal(true);

  const readGet = (o) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  };
  let getCode = readGet('code');

  //MODAL VALIDAR USER
  // const validUser = useSelector((state) => state.user.validUser)
  // const [modalValidUser, setModalValidUser] = React.useState(validUser)
  // const handleCloseValidUser = () => {
  //   setModalValidUser(false)
  //   dispatch(receiveValidUser(false))
  // }

  // VALIDO QUE ESTE REGISTRADO
  useEffect(() => {
    let cookieToken = readCookie('azmed_user_token')

    if (evento) {
      if (evento["professional"] == "1") {
        if (cookieToken == null && getCode == null) {
          props.props.history.push("/")
          dispatch(receiveValidUser(true))
          dispatch(setUrlBack(`${URL}eventos/${evento["id_event"]}/${evento["slug"]}`))
        }
      }
    }

    if (user) {
      tracking(userData['user_id'], '15', '', userData['name'], window.location.href)
    }
    // ReactGa.pageview(`${URL}eventos/${evento['slug']}`)

  }, [evento])

  return (
    <section id="home-eventos" className="fondo-azul">
      {evento && (
        <div className="container">
          <div className="evento-container">
            <div className="row">
              <div className="col-md-6">
                <div className="evento">
                  <h3>{evento["name"]}</h3>
                  <div className="date">
                    {" "}
                    <span className="fecha texto-blanco">
                      {evento["days"]}
                    </span>{" "}
                    <span className="hora texto-verde">{evento["schedule"] && evento["schedule"]}</span>{" "}
                  </div>
                  <h4 className="autores texto-verde">{evento["place"]}</h4>
                  {/* <a className="calendar" href={`https://calendar.google.com/calendar/r/eventedit?dates=20160917T001500Z/20160917T014500Z&location=Buenos+Aires&text=${evento.name}&details=`} target="_blank" >
                    <img src="/img/boton-calendario.png" alt="" />
                  </a> */}


                  <div className="compartir">
                    {evento['need_logged'] == '1' ? (
                      <ShareButton
                        id="button-compartir"
                        url={window.location.href}
                        quote={evento["name"]}
                        email={true}
                      // body={ReactHtmlParser(evento["detail"])}
                      />
                    ) : (
                        <ShareButton
                          id="button-compartir"
                          url={window.location.href}
                          body={ReactHtmlParser(evento["detail"])}
                          quote={evento["name"]}
                          email={true}
                          facebook={true}
                          twitter={true}
                          whatsapp={true}
                        />
                      )}
                  </div>
                  <img
                    className="linea-de-fondo"
                    src="/img/single-evento-linea.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="evento-detail col-md-6">
                <div className="descripcion">
                  <h4 className="autores texto-verde">DESCRIPCIÓN</h4>
                  <p className="texto-blanco">{ReactHtmlParser(evento["detail"])}</p>
                </div>
                <div className="dirigido">
                  <h4 className="autores texto-verde">DIRIGIDO A:</h4>
                  <p className="texto-blanco">{evento["directed"]}</p>
                </div>
                <div className="organiza">
                  <h4 className="autores texto-verde">ORGANIZA:</h4>
                  <p className="texto-blanco">Asma Zero</p>
                </div>
                <div className="single-evento-buttons">
                  <a target='_blank' href={evento['url_canonical']}>
                    <button className="boton-verde">
                      VER EN VIVO
                </button>
                  </a>
                  <div className="boton-blanco calendar-button">
                    <ButtonCalendarComponent
                      title={evento['name']}
                      detail={evento["detail"]}
                      place={evento['place']}
                      start={evento['modified']}
                    />
                  </div>

                  {/* <button
                    onClick={handleShowInscribirse}
                    className="boton-blanco"
                  >
                    INSCRIBIRSE{" "}
                  </button> */}
                </div>
              </div>
            </div>

            <Modal
              keyboard
              enforceFocus
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showVivo}
              onHide={handleCloseVivo}
              className="modal-vivo">
              <Modal.Body className="ingresar-modal">
                <img src="/img/icon-luna.svg" alt="" />
                <div className="container">
                  <p className="miga-de-pan texto-verde">ASMA ZERO / EVENTOS</p>
                  <h1>PRÓXIMAMENTE</h1>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={inscribirseModal}
              onHide={handleCloseInscribirse}
              className="modal-inscribirse">
              {datosEnviados ? (
                <Modal.Body className="ingresar-modal modal-body-send">
                  <div className="container">
                    <p className="miga-de-pan texto-verde">
                      ASMA ZERO / EVENTOS
                    </p>
                    <h1>INSCRIPCIÓN</h1>
                    <p>Su registración ha sido un éxito</p>
                  </div>
                </Modal.Body>
              ) : (
                  <Modal.Body
                    style={{ width: '80%' }}
                    className="ingresar-modal modal-body">
                    {/* <img src="/img/candado.svg" alt="" /> */}
                    <div className="container">
                      <p className="miga-de-pan texto-verde">
                        ASMA ZERO / EVENTOS
                    </p>
                      <h1>INSCRIPCIÓN</h1>
                      <p>Para inscribirse, completar los siguientas datos:</p>
                      <div className="formulario">
                        <label htmlFor="name">
                          Nombre:
                        <input
                            type="text"
                            id="name"
                            name="nombre"
                            onChange={handleInputChange}
                          />
                          <p className={datosClases.nombre}>Ingrese su nombre</p>
                        </label>
                        <label htmlFor="apellido">
                          Apellido:
                        <input
                            type="text"
                            id="apellido"
                            name="apellido"
                            onChange={handleInputChange}
                          />
                          <p className={datosClases.apellido}>
                            Ingrese su apellido
                        </p>
                        </label>
                        <label htmlFor="email">
                          E-mail:
                        <input
                            name="email"
                            onChange={handleInputChange}
                            type="email"
                            id="email"
                          />
                          <p className={datosClases.email}>Ingrese su Email</p>
                        </label>
                        <button onClick={requerirCampos}>Enviar</button>
                      </div>
                    </div>
                  </Modal.Body>
                )}
            </Modal>
            {/* <ModalValidar show={modalValidUser} onHide={handleCloseValidUser} /> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default SingleEvento;

import React from "react";
import { connect } from "react-redux";
import Educacion from "../components/Educacion";

class EducacionContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let matchSection = this.props.match.params.section;

    return (
      <Educacion
        props={this.props}
        matchSection={matchSection}
        match={matchSection}

      />
    );
  }
}

const mapStateToProps = state => ({
  notes: state.notes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, null)(EducacionContainer);

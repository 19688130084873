import {RECEIVE_PLANS, RECEIVE_PLAN, RESET_USER_DATA} from '../constants';
const initState = {
  planes: [],
  loadingPlan: false,
};

const plan = (state = initState, action) => {
  switch (action.type) {
    case RECEIVE_PLANS:
      return {...state, planes: action.data, loadingPlan: true};
    case RECEIVE_PLAN:
      return {...state, planes: [...state.planes, action.data]};
    case RESET_USER_DATA:
      return initState;
    default:
      return state;
  }
};
export default plan;

import React from "react";
import Styles from '../css/CancionesSpotify.module.css';

function CancionesSpotify() {
    return (
        <div className={Styles.canciones}>
            <ul>
                <li>
                    <a href={"https://open.spotify.com/episode/35M0ricsewJJ58i2apFK3F?si=bfccac6ccfe6497b"} target={"_blank"}>
                        <div className={"d-flex"}>
                            <img src="/img/post-cuentos.png" width={40} height={40} alt="Post cuentos para sanar"/>
                            <span className={Styles.titulo}>El Coche De Aire</span>
                        </div>
                        <div className={Styles.wrapper}>
                            <span>Cuentos para sanar</span>
                            <span>2 min 23 s</span>
                        </div>
                        <div className={Styles.heart}>
                            <img src="/img/heart.svg" alt="Corazón"/>
                        </div>
                    </a>
                </li>
                <li>
                    <a href={"https://open.spotify.com/episode/72ICHaLxDheFMy0gkwq1Sx?si=5bd1feb4268342cc"} target={"_blank"}>
                        <div className={"d-flex"}>
                            <img src="/img/post-cuentos.png" width={40} height={40} alt="Post cuentos para sanar"/>
                            <span className={Styles.titulo}>Dante El Elefante</span>
                        </div>
                        <div className={Styles.wrapper}>
                            <span>Cuentos para sanar</span>
                            <span>3 min 5 s</span>
                        </div>
                        <div className={Styles.heart}>
                            <img src="/img/heart.svg" alt="Corazón"/>
                        </div>
                    </a>
                </li>
                <li>
                    <a href={"https://open.spotify.com/episode/0tRP0LuJj2915gNIpRVBuq?si=13bb611e79084aa3"} target={"_blank"}>
                        <div className={"d-flex"}>
                            <img src="/img/post-cuentos.png" width={40} height={40} alt="Post cuentos para sanar"/>
                            <span className={Styles.titulo}>Simón El León</span>
                        </div>
                        <div className={Styles.wrapper}>
                            <span>Cuentos para sanar</span>
                            <span>3 min 5 s</span>
                        </div>
                        <div className={Styles.heart}>
                            <img src="/img/heart.svg" alt="Corazón"/>
                        </div>
                    </a>
                </li>
            </ul>
            <img className={`${Styles.elefante} img-fluid`} src="/img/elefante.svg" alt="Elefante"/>
        </div>
    );
}

export default CancionesSpotify;
import React, { useEffect } from "react";
import NavbarInterna from "./NavbarInterna";
import RedesIconos from "./IconosRedesInterna";
import NoteSeccionHome from './NoteSeccionHome'
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Menu from './Menu';
import Button from './ButtonMenu';
import { codigoPacientes } from "../codigos";
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { API_MATERIAL, URL } from '../store/constants'
import { receiveValidUser, tracking, setUrlBack } from '../store/actions/user'
import { readCookie } from '../components/Cookie'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'




const EntrenamientoMenu = (props) => {
    const userState = useSelector((state) => state.user.authState, shallowEqual);
    const user = useSelector((state) => state.user.authData, shallowEqual);
    const resources = useSelector((state) => state.resourcesReducer.resources, shallowEqual);
    const notes = useSelector((state) => state.notes.notes, shallowEqual);
    const sections = useSelector((state) => state.sections.sections, shallowEqual);
    const dispatch = useDispatch()

    const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
    const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

    // VALIDO QUE ESTE REGISTRADO

    const readGet = (o) => {
        return (
            decodeURIComponent(
                (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
                    window.location.href,
                ) || [null, ''])[1].replace(/\+/g, '%20'),
            ) || null
        );
    }
    let getCode = readGet('code')


    useEffect(() => {
        let cookieToken = readCookie('azmed_user_token')

        if (cookieToken == null && getCode == null) {
            dispatch(receiveValidUser(true))
            dispatch(setUrlBack(`${URL}entrenamiento-medico`))
            props.history.push("/")
        }

        if (userState) {
            tracking(user['user_id'], '36', '', user['name'], window.location.href)
        }
        ReactGa.pageview(`/entrenamiento-medico`)
    }, [])


    return (
        <div className={internaClass}>
            <Helmet>
                <title>Asma Zero - Entrenamiento Médico</title>
                <meta name="description" content="Asma Zero - Entrenamiento Médico  " />
            </Helmet>
            <div className={overlayClass}></div>
            <header>
                <div id="header-main" className="margin-bottom-7">
                    <NavbarInterna
                        props={props}
                    />
                </div>
                <Menu />
            </header>
            <Button />

            <RedesIconos />

            <main id="interna-banner-educacion">
                <div className="container">
                    <p className="seccion miga-de-pan text-center">
                        <Link
                            style={{ textDecoration: "none", color: "#35aea9" }}
                            to="/"
                        >
                            ASMA ZERO{" "}
                        </Link>{" "}
                  /{" "}
                        ENTRENAMIENTO MÉDICO</p>
                    <h1 className="text-center">Entrenamiento Médico</h1>
                </div>
            </main>
            <section className="container home-educacion">
                {sections.filter((section) => section['parent_id_section'] == '36' && section['status'] == '1').sort(function (a, b) {
                    return (a['field_order'] - b['field_order'])
                }).map((section) => (
                    <div>
                        {section['slug'] == 'recursos-profesionales' ? (
                            <div className="category">
                                <div className="title-button">
                                    <Link to="/entrenamiento-medico/recursos-profesionales">
                                        <h1 className="category-title texto-verde">
                                            RECURSOS PARA PROFESIONALES
                     </h1>
                                    </Link>

                                </div>
                                <div className="content-and-button">
                                    <div className="desktop-button-mas button-mas-notas">
                                        <Link style={{ textDecoration: 'none' }} to="/entrenamiento-medico/recursos-profesionales">
                                            <button className="mas-notas">+ RECURSOS</button>
                                        </Link>
                                    </div>
                                    {resources && (
                                        <div
                                            style={{ marginTop: "1rem" }}
                                            className="row container-recurso"
                                        >
                                            {resources.filter(recurso => recurso.need_logged == "1" && recurso.status == 1).slice(0, 4).map((recurso) => (
                                                <div
                                                    key={recurso.id_note_resource}
                                                    className="recurso col-12 col-xs-6 col-sm-6 col-md-3"
                                                >
                                                    <img className="imagen-recurso" src={`${API_MATERIAL}/notes_resources/small/${recurso.photo}`} alt="" />
                                                    {recurso.url_canonical ? (
                                                        <div>
                                                            {recurso.url_canonical.length > 0 ? (
                                                                <a target="_blank" href={recurso.url_canonical} >
                                                                    <h1 className="title">{recurso.name}</h1>
                                                                </a>
                                                            ) : (
                                                                    <a
                                                                        download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                                                        target="_blank"
                                                                        href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`} >
                                                                        <h1 className="title">{recurso.name}</h1>
                                                                    </a>
                                                                )}
                                                        </div>
                                                    ) : (
                                                            <div>
                                                                <a
                                                                    download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                                                    target="_blank"
                                                                    href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`} >
                                                                    <h1 className="title">{recurso.name}</h1>
                                                                </a>
                                                            </div>
                                                        )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                                <NoteSeccionHome
                                    title={section['name']}
                                    link={`entrenamiento-medico/${section['slug']}`}
                                    array={notes.filter(note => note.section['id_section'] == section['id_section'])}
                                    section={"entrenamiento"}
                                />
                            )}
                    </div>
                ))}
                <div className="category">
                    <h1 className="texto-verde"></h1>
                </div>
            </section>
            <Footer codigo={codigoPacientes} />
        </div>
    );
};

export default EntrenamientoMenu;
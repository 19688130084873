import React from "react";
import ResultadoTest from "../components/ResultadoTest";
import { resetTest } from "../store/actions/test";
import store from "../store";
import { connect } from "react-redux";

class ResultadoTestContainer extends React.Component {
  constructor(props) {
    super(props);

    this.repeatTest = this.repeatTest.bind(this);
  }

  repeatTest() {
    this.props.history.push("/evaluacion");
    store.dispatch(resetTest());
  }

  render() {
    return (
      <ResultadoTest
        test={this.props.test}
        repeatTest={this.repeatTest}
        props={this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  test: state.test,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, null)(ResultadoTestContainer);

import React, { useEffect, useState } from 'react'
import Menu from './Menu';
import Button from './ButtonMenu';
import RedesIconos from './IconosRedesInterna';
import NavbarInterna from './NavbarInterna';
import Footer from './Footer';
import Helmet from "react-helmet";
import { URL, AUTH_URL, HREF } from '../store/constants';
import { setUrlBack, receiveCookie, receiveValidUser } from '../store/actions'
import { readCookie } from '../components/Cookie'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import IframeComm from 'react-iframe-comm';
import ReactGa from 'react-ga'


const readGet = (o) => {
    return (
        decodeURIComponent(
            (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
                window.location.href,
            ) || [null, ''])[1].replace(/\+/g, '%20'),
        ) || null
    );
}
let getCode = readGet('code')


export default function MediTuDependenciaHcp(props) {
    const dispatch = useDispatch();
    const urlBack = useSelector((state) => state.user.urlBack);


    const userState = useSelector((state) => state.user.authState);
    const user = useSelector((state) => state.user.authData);

    const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
    const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

    const cookie = useSelector((state) => state.user.mtdCookie, shallowEqual)




    const [iframeSrc, setIframeSrc] = useState(`https://asmazero.com/meditudependencia/hcp.html`)

    // Iframe params

    const attributes = {
        src: iframeSrc,
        id: "medi-tu-dependencia",
        frameborder: "0",
        width: "100%",
    };

    const postMessageData = user ? user : [];

    // parent received a message from iframe
    const onReceiveMessage = () => {
        console.log("onReceiveMessage");
    };

    // iframe has loaded
    const onReady = () => {
        console.log("onReady");
    };

    if (window.screen.width < 768) {
        document.documentElement.scrollTop = 100
    }

    useEffect(() => {
        let cookieToken = readCookie('azmed_user_token')
        if (cookieToken == null && getCode == null) {
            dispatch(receiveValidUser(true))
            dispatch(setUrlBack(window.location.href))
            props.props.history.push("/")
        }
        if (userState) {
            //tracking(user['user_id'], props.note['section']['id_section'], props.note['id_note'], user['name'], window.location.href)
        }

        ReactGa.pageview(window.location.href)
    }, [userState])



    return (
        <div className={internaClass} >
            <Helmet>
                <title>Medí tu Dependencia</title>
                <meta name="description" content="Medi tu dependencia" />
            </Helmet>
            <div className={overlayClass}></div>
            <header>
                <div id="header-main" className="margin-bottom-7">
                    <NavbarInterna
                        props={props}
                    />
                </div>
                <Menu />
            </header>
            <Button />

            <RedesIconos />

            <section className="medi-tu-dependencia-hcp" >

                {cookie == null && (
                    <div id="cookies-container" className="fixed-bottom bg-black" >
                        <div className="container-fluid" id="cookie-message">
                            <div>
                                <p className="texto-blanco" > Estos no son consejos médicos. No dejes o cambies tu medicación para el asma sin consultar con un profesional de la salud. </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                {/* <a onClick={() => { dispatch(receiveCookie(true)) }} href="#" id="cookies_confirm" class="btn btn-primary bg-blue ml-4 p-2 btn-track" data-label="cookies_confirm" data-section="cookie_message">ACEPTO</a> */}
                                <a onClick={() => { dispatch(receiveCookie(true)) }} href='#' id="cookies_close" class="btn btn-primary bg-blue ml-4 p-2 btn-track" data-label="cookies_close" data-section="cookie_message">CERRAR</a> </div>
                        </div>
                    </div>
                )}

                <IframeComm
                    attributes={attributes}
                    postMessageData={postMessageData}
                    handleReady={onReady}
                    handleReceiveMessage={onReceiveMessage}
                />


            </section>
            {/* <Footer
                fondoNegro={true}
            /> */}

        </div>
    )
}
import { FETCH_MAPS, SET_GEOLOCATION, SET_FILTER_MAP } from "../constants";

const initialState = {
  maps: [
    {
      "id_map": "0",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"CO",
      "name": "Fundacion Santa Fe de Bogotá",
      "description": "Neumonología",
      "address": "Carrera 7 No. 117 - 15",
      "neighborhood": null,
      "location": "Bogotá",
      "province": "Bogotá",
      "directions":"https://goo.gl/maps/dk4HqTYhucSZaQNb7",
      "postal_code": "",
      "latitude": "4.695544921326053",
      "length": "-74.03277864012654",
      "email": null,
      "file": null,
      "phone": "+5716030303",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "1",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"CO",
      "name": "Fundación Neumológica Colombiana",
      "description": "Neumonología",
      "address": "Cra. 13b #161-85",
      "neighborhood": null,
      "location": "Bogotá",
      "province": "Bogotá",
      "directions":"https://goo.gl/maps/CcY8UMXoe2DtyQrr5",
      "postal_code": "",
      "latitude": "4.741579781181589",
      "length": "-74.03298415495253",
      "email": null,
      "file": null,
      "phone": "+5717428888",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "2",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"CO",
      "name": "Clinica General del Norte",
      "description": "Neumonología",
      "address": "Carrera 48 No. 70 - 38",
      "neighborhood": null,
      "location": "Barranquilla",
      "province": "Barranquilla",
      "directions":"https://goo.gl/maps/gDQD82cSC1vfEFQK6",
      "postal_code": "",
      "latitude": "10.99574462312517",
      "length": "-74.80107722475556",
      "email": null,
      "file": null,
      "phone": "+5753091666",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "3",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"CO",
      "name": "Clínica del Country",
      "description": "Neumonología",
      "address": "Carrera 16 # 82-95",
      "neighborhood": null,
      "location": "Bogotá",
      "province": "Bogotá",
      "directions":"https://goo.gl/maps/71AhFes5K9ot8LEd6",
      "postal_code": "",
      "latitude": "4.669911630979983",
      "length": "-74.05436232525153",
      "email": null,
      "file": null,
      "phone": "+5715300470",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "4",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"CO",
      "name": "Centro Médico Imbanaco",
      "description": "Neumonología",
      "address": "Carrera 38 Bis # 5B2 - 04",
      "neighborhood": null,
      "location": "Cali",
      "province": "Cali",
      "directions":"https://goo.gl/maps/wDrQNHVBanemxEkPA",
      "postal_code": "",
      "latitude": "3.4242885572459403",
      "length": "-76.54405601878611",
      "email": null,
      "file": null,
      "phone": "+5725186000",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "5",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"PE",
      "name": "Neumología Peruana",
      "description": "Neumonología",
      "address": "Centro comercial «El Polo» – Surco Edificio C, 3er Piso",
      "neighborhood": null,
      "location": "Lima",
      "province": "Lima",
      "directions":"https://goo.gl/maps/QJdowSsW9pWHVxrA9",
      "postal_code": "",
      "latitude": "-12.099413982912772",
      "length": "-76.96780504097497",
      "email": null,
      "file": null,
      "phone": "+51995623339",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "6",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"PE",
      "name": "Clinica San Martin",
      "description": "Neumonología",
      "address": "Avenida San Martin 596",
      "neighborhood": null,
      "location": "ICA",
      "province": "ICA",
      "directions":"https://goo.gl/maps/AnVLUTZ3pPe49Tmz9",
      "postal_code": "",
      "latitude": "-12.114524027878742",
      "length": "-76.98968922475558",
      "email": null,
      "file": null,
      "phone": "+5156224064",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "7",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"PE",
      "name": "Clínica Ricardo Palma",
      "description": "Neumonología",
      "address": "Av. Javier Prado Este 1066",
      "neighborhood": null,
      "location": "San Isidro",
      "province": "Lima",
      "directions":"https://goo.gl/maps/3P97xf5hyhHr72EW8",
      "postal_code": "",
      "latitude": "-12.090031785357429",
      "length": "-77.01622666336971",
      "email": null,
      "file": null,
      "phone": "+5112242224",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "8",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"PE",
      "name": "Clínica Internacional San Borja",
      "description": "Neumonología",
      "address": "Avenida Guardia Civil 385, San Borja",
      "neighborhood": null,
      "location": "San Borja",
      "province": "Lima",
      "directions":"https://goo.gl/maps/vZNv6cw8mrRMYi3Q8",
      "postal_code": "",
      "latitude": "-12.092314328086404",
      "length": "-77.00567530297579",
      "email": null,
      "file": null,
      "phone": "+5116196161",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "9",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"PE",
      "name": "Clínica Isis Trujillo",
      "description": "Neumonología",
      "address": "Urb Santa Inés Calle Los Zafiros 162",
      "neighborhood": null,
      "location": "Trujillo",
      "province": "Trujillo",
      "directions":"https://goo.gl/maps/BHzfJSEF5zzb4NHH9",
      "postal_code": "",
      "latitude": "-8.10300628400877",
      "length": "-79.03498537822021",
      "email": null,
      "file": null,
      "phone": "+5144345284",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "10",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"EC",
      "name": "Clínica Mariano Acosta",
      "description": "Neumonología",
      "address": "Av Mariano Acosta 1126",
      "neighborhood": null,
      "location": "Ibarra",
      "province": "Ibarra",
      "directions":"https://goo.gl/maps/T7EySAkASNDMvYiW9",
      "postal_code": "",
      "latitude": "0.3493583036580609",
      "length": "-78.12108901683435",
      "email": null,
      "file": null,
      "phone": "+59362642211",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "11",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"EC",
      "name": "Hospital Clínica San Francisco",
      "description": "Neumonología",
      "address": "Cdla. Kennedy Norte, Av. Andrade Coello y Juan Rolando (esq.)",
      "neighborhood": null,
      "location": "Guayaquil",
      "province": "Guayaquil",
      "directions":"https://goo.gl/maps/fgw93BKWCqzKcx827",
      "postal_code": "",
      "latitude": "-2.167715353769729",
      "length": "-79.89322575445657",
      "email": null,
      "file": null,
      "phone": "+59342595400",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "12",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"EC",
      "name": "Medigroup Centros Médicos del Hospital Clínica San Francisco",
      "description": "Neumonología",
      "address": "Cdla. Kennedy Norte, Av. Miguel H. Alcívar 51 y Av. C. L. Plaza Dañín",
      "neighborhood": null,
      "location": "Guayaquil",
      "province": "Guayaquil",
      "directions":"https://goo.gl/maps/U26w1nPSZgp7BYXt5",
      "postal_code": "",
      "latitude": "-2.169986422451375",
      "length": "-79.89368001322435",
      "email": null,
      "file": null,
      "phone": "+59343904500",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
    {
      "id_map": "13",
      "id_setting_country": "10",
      "id_map_category": "3",
      "country":"EC",
      "name": "Centro Médico Medilink",
      "description": "Neumonología",
      "address": "6 de Marzo 3402 y Camilo Destruge",
      "neighborhood": null,
      "location": "Guayaquil",
      "province": "Guayaquil",
      "directions":"https://goo.gl/maps/TqWbr5sqCAfKmFDT7",
      "postal_code": "",
      "latitude": "-2.2111533936584813",
      "length": "-79.89068429900013",
      "email": null,
      "file": null,
      "phone": "+59342448700",
      "date": "2019-04-07 00:00:00",
      "modified": "2019-04-07 00:00:00",
      "status": "1"
    },
  ],
  filterMap: null,
  profesionalesMap: null,
  geolocation: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAPS:
      return Object.assign({}, state, { maps: action.maps });
    case SET_GEOLOCATION:
      return Object.assign({}, state, { geolocation: action.boolean });
    case SET_FILTER_MAP:
      return Object.assign({}, state, { filterMap: action.filter });
    default:
      return state;
  }
};

import axios from "axios";
import { FETCH_SECTIONS, API } from "../constants";

const setSections = sections => ({
  type: FETCH_SECTIONS,
  sections
});

export const fetchSections = () => dispatch => {
  fetch(API)
    .then(res => res.json())
    .then(data => {
      dispatch(setSections(data["sections"]));
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    });
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import { codigoMedicos } from '../codigos';
import {
  storeNewPlan,
  loadPlans,
  tracking,
  receiveValidUser,
  setUrlBack,
} from '../store/actions';
import moment from 'moment';
import axios from 'axios';
import { readCookie } from '../components/Cookie';
import ShareButton from './ShareButtonComponent';
import { URL, HREF } from '../store/constants';
import Helmet from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import ReactGa from 'react-ga'


let date = new Date();

const initialState = {
  nombre: '',
  apellido: '',
  email: '',
  medicacion_Mantenimiento: '',
  dosis_mañana_mantenimiento: '',
  dosis_noche_mantenimiento: '',
  alivio_radio: '',
  alivio_medicacion: '',
  alivio_dosis: '',
  corticoides_orales: '',
  otros_medicamentos: '',
  proxima_consulta: '',
  proximo_lugar: '',
  recibe_mail: 'si',
  emailProfesional: '',
  nombreProfesional: '',
  formName: 'plan',
  fecha: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
  empty_mantenimiento: true
};

const initialRequeridos = {
  nombre: 'disabled',
  email: 'disabled',
  apellido: 'disabled',
  alivio_medicacion: 'disabled',
  dosis_mañana_mantenimiento: 'disabled',
  dosis_noche_mantenimiento: 'disabled',
  alivio_radio: 'disabled',
  alivio_dosis: 'disabled',
};

export default function PlanDeAccion(props) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const { planes, loadingPlan } = useSelector(({ plan }) => plan, shallowEqual);




  const [planEnviado, setPlanEnviado] = useState(false);
  const handleClosePlanEnviado = () => setPlanEnviado(false)

  const overlayClass = useSelector(
    (state) => state.menu.overlayClass,
    shallowEqual,
  );
  const internaClass = useSelector(
    (state) => state.menu.internaClass,
    shallowEqual,
  );

  let [planesState, setPlanesState] = useState(planes);
  let [loading, setLoading] = useState(false);
  const [datos, setDatos] = useState(initialState);
  const [datosRequeridosClases, setDatosRequeridosClases] = useState(
    initialRequeridos,
  );
  const [resultado, setResultado] = useState(false);

  useEffect(() => {
    validUser();
    user &&
      user.email &&
      dispatch(loadPlans({ userType: 'hsp', id: user.email, email: user.email }));
    console.log(user);
    if (user) {
      tracking(user['user_id'], '35', '', user['name'], window.location.href);
    }
    ReactGa.pageview(window.location.href)

  }, [user]);


  // CAMBIA FORMATO DE FECAH
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1], day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  const readGet = (o) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  };
  let getCode = readGet('code');

  const validUser = () => {
    let cookieToken = readCookie('azmed_user_token');
    // console.log(cookieToken)
    if (cookieToken == null && getCode == null) {
      dispatch(receiveValidUser(true));
      dispatch(setUrlBack(`${URL}herramientas/plan-de-accion`));
      props.history.push('/');
    }
  };

  if (loadingPlan && !loading && planes.length > planesState.length) {
    setPlanesState(planes);
  }
  if (loadingPlan && loading && planes.length > planesState.length) {
    console.log('Se agrego un nuevo plan');
    loading && setLoading(false);
    // setDatos(initialState);
    // setResultado(true);
  }
  const SendPlan = () => {

    setLoading(true);
    let plan = {
      id_user: user.email,
      name: datos.nombre + ' ' + datos.apellido,
      patient: datos.email,
      mediaction_name: datos.medicacion_Mantenimiento,
      morning_dose: datos.dosis_mañana_mantenimiento,
      night_dose: datos.dosis_noche_mantenimiento,
      rescue_radio: datos.alivio_radio,
      rescue_other: datos.alivio_medicacion,
      rescue_dose: datos.alivio_dosis,
      comment: datos.otros_medicamentos,
      share_my_email: datos.recibe_mail,
      next_consultation_day: moment(
        datos.proxima_consulta,
        'DD/MM/YYYY',
      ).format('YYYY-MM-DD'),
      next_consultation_place: datos.proximo_lugar,
    };

    //dispatch(storeNewPlan(plan));
    datos['emailProfesional'] = user.email;
    datos['nombreProfesional'] = user.name;
    // console.log(datos.proxima_consulta)
    // console.log(formatDate(datos.proxima_consulta));
    datos.proxima_consulta = formatDate(datos.proxima_consulta)
    if (datos.recibe_mail === 'si') {
      datos.recibe_mail = true;
    } else {
      datos.recibe_mail = false;
    }
    if (datos.alivio_radio === 'otro_medicamento') {
      datos.alivio_radio = true;
      datos.empty_mantenimiento = false;
    } else if (datos.alivio_radio === 'mismo_medicamento') {
      datos.alivio_radio = false;
      datos.empty_mantenimiento = false;
    } else {
    }
    axios.post(
      'https://pd3i92cpeb.execute-api.us-east-1.amazonaws.com/prod/az-prod-sendmail-prod-mailing',
      JSON.stringify({ datos }),
    );
    setPlanEnviado(true);

  };

  const requerirCampos = () => {
    let camposIncompletos = 0;
    let keys = [
      'nombre',
      'apellido',
      'email',
      'alivio_medicacion',
      // 'dosis_mañana_mantenimiento',
      // 'dosis_noche_mantenimiento',
      //'alivio_radio',
      'alivio_dosis',
    ];

    if (datos['alivio_radio'] == '') {
      // console.log('Alivio radio esta vacio');
    } else {
      // console.log('PUSHEO LAS KEYS DE DOSIS');
      keys.push('dosis_mañana_mantenimiento');
      keys.push('dosis_mañana_mantenimiento');
      keys.push('alivio_radio');
      // console.log('ARRAY KEYS', keys);
    }

    for (let i = 0; i < keys.length; i++) {
      if (
        datos[keys[i]] === '' ||
        (datos[keys[i]] === 'otro_medicamento' &&
          datos.medicacion_Mantenimiento === '')
      ) {
        let val = 'active';
        setDatosRequeridosClases((prevState) => {
          return { ...prevState, [keys[i]]: val };
        });
        camposIncompletos++;
      } else {
        let val = 'disabled';
        setDatosRequeridosClases((prevState) => {
          return { ...prevState, [keys[i]]: val };
        });
      }
    }
    if (camposIncompletos == 0) {
      // console.log(datos)
      setResultado(true);
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const volverButton = () => {
    setResultado(false);
    window.scrollTo(0, 0);
  };

  const terminarButton = () => {
    SendPlan();
    setDatos(initialState);
    setResultado(false);
    window.scrollTo(0, 0);
    tracking(user['user_id'], '35', '', user['name'], "", "", "Plan Enviado");
  };



  return (
    <div className={internaClass}>
      <Helmet>
        <title>Plan de Acción</title>
        <meta name="description" content="Plan de Acción" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            HERRAMIENTAS / PLAN DE ACCIÓN
          </p>
          <h1 className="text-center">Plan de Acción</h1>
        </div>
      </main>
      { !resultado ? (
        <div>
          <section className="plan-de-accion-home">
            <div>
              <div className="datos-paciente fondo-verde row">
                <div className="column-one col-md-6">
                  <p className="complete">
                    Complete el siguiente Plan de Acción con su paciente
                  </p>

                  <img src="/img/frase-linea-azul.svg" alt="" />
                </div>
                <div className="column-two col-md-6">
                  <p className="datos texto-azul">
                    DATOS DESTINATARIO DEL PLAN
                  </p>
                  <p className="obligatorio">
                    Los campos con * son obligatorios
                  </p>
                  <div className="formulario">
                    {/* */}
                    <label for="name">
                      <input
                        placeholder="Nombre/s *"
                        type="text"
                        id="name"
                        name="nombre"
                        onChange={handleInputChange}
                        value={datos.nombre}
                        style={{
                          color: 'white',
                          textTransform: 'uppercase',
                        }}></input>
                      <p className={datosRequeridosClases.nombre}>
                        Nombre es Requerido
                      </p>
                    </label>
                    <label for="lastname">
                      <input
                        placeholder="Apellido/s *"
                        type="text"
                        id="lastname"
                        name="apellido"
                        onChange={handleInputChange}
                        value={datos.apellido}
                        style={{
                          color: 'white',
                          textTransform: 'uppercase',
                        }}></input>
                      <p className={datosRequeridosClases.apellido}>
                        Apellido es Requerido
                      </p>
                    </label>
                    <label for="email">
                      <input
                        placeholder="E-mail *"
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleInputChange}
                        value={datos.email}
                        style={{
                          color: 'white',
                          textTransform: 'uppercase',
                        }}></input>
                      <p className={datosRequeridosClases.email}>
                        Email es Requerido
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row indicaciones-form">
                <div className="col-md-6"></div>
                <div className="indicaciones col-md-6">
                  <label className="label-gris" for="date">
                    FECHA{' '}
                    <input
                      className="medicacion-nombre"
                      type="date"
                      id="date"
                      name="fecha"
                      onChange={handleInputChange}
                      value={moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                      style={{
                        color: '#9c9d9d',
                        textTransform: 'uppercase',
                      }}></input>
                  </label>
                  <h1 className="texto-verde">INDICACIONES</h1>
                  <div className="rescate">
                    <p className="sub-titles texto-azul">TERAPIA DE RESCATE</p>

                    <label for="medicacion" className="label-gris">
                      <input
                        placeholder="Nombre Medicamento *"
                        name="alivio_medicacion"
                        onChange={handleInputChange}
                        style={{ color: '#9c9d9d', textTransform: 'uppercase' }}
                        className="medicacion-nombre"
                        type="text"
                        value={datos.alivio_medicacion}
                      />
                      <p className={datosRequeridosClases.alivio_medicacion}>
                        Medicación Requerida
                      </p>
                    </label>
                    <label for="dosis-maxima" className="label-gris">
                      <input
                        placeholder="Dosis Máximas Diarias *"
                        name="alivio_dosis"
                        onChange={handleInputChange}
                        style={{ color: '#9c9d9d', textTransform: 'uppercase' }}
                        className="medicacion-nombre"
                        type="text"
                        value={datos.alivio_dosis}
                      />
                      <p className={datosRequeridosClases.alivio_dosis}>
                        Dósis Requerida
                      </p>
                    </label>
                  </div>
                  <p className="sub-titles texto-azul">
                    TERAPIA DE MANTENIMIENTO (OPCIONAL){' '}
                  </p>
                  <p className="mantenimiento-sub-heading">
                    Si se indicó ics/formoterol para Rescate y el
                    paciente es Leve{' '}
                    <span className="referencias">(Pasos 1-2 GINA)</span> ,
                    puede utilizarla según necesidad,
                    <span className="referencias">
                      {' '}
                      sin necesidad de agregar Mantenimiento (1,2)
                    </span>{' '}
                  </p>
                  <div className="formulario mantenimiento-mobile">
                    <div className="checkbox">
                      <label className="container-label" for="contactChoice1">
                        <input
                          type="radio"
                          id="contactChoice1"
                          name="alivio_radio"
                          value="mismo_medicamento"
                          checked={datos.alivio_radio === 'mismo_medicamento'}
                          onChange={handleInputChange}
                          style={{ color: '#9c9d9d', textTransform: 'uppercase' }}
                        />
                        <span className="checkmark"></span>
                        Indicar para Mantenimiento misma terapia que Rescate.
                        <p className="referencia-3">
                          Sólo ics/formoterol se encuentra aprobada en la
                          argentina para esta estrategia.{' '}
                          <span className="referencias"></span>
                        </p>
                      </label>
                    </div>
                    <div className="checkbox">
                      <label className="container-label" for="contactChoice2">
                        <input
                          type="radio"
                          id="contactChoice2"
                          name="alivio_radio"
                          value="otro_medicamento"
                          checked={datos.alivio_radio === 'otro_medicamento'}
                          onChange={handleInputChange}
                          style={{ color: '#9c9d9d', textTransform: 'uppercase' }}
                        />
                        <span className="checkmark"></span>
                        Utilizar otro medicamento
                      </label>
                    </div>
                    {datos.alivio_radio == 'otro_medicamento' && (
                      <label id="nueva-medicacion" for="medicacion" className="label-gris">
                        <input
                          id="mantenimiento-nombre"
                          placeholder="Nombre Medicamento *"
                          name="medicacion_Mantenimiento"
                          onChange={handleInputChange}
                          style={{ color: '#9c9d9d', textTransform: 'uppercase' }}
                          className="medicacion-nombre"
                          type="text"
                          value={datos.medicacion_Mantenimiento}
                        />
                        <p className={datosRequeridosClases.alivio_radio}>
                          Medicación Requerida
                        </p>
                      </label>
                    )}


                    <label className="label-gris" for="cantidad">
                      INDIQUE DOSIS DIARIAS
                      <div className="dosis-mantenimiento-input">
                        <div className="input-icons">
                          <img src="/img/plan-mañana.png" alt="" />
                          <input
                            type="text"
                            id="name"
                            name="dosis_mañana_mantenimiento"
                            onChange={handleInputChange}
                            value={datos.dosis_mañana_mantenimiento}
                            style={{
                              color: '#9c9d9d',
                            }}
                            id="mañana-input"
                            class="input-field"
                          />

                          <p className="horario">por la mañana</p>
                        </div>
                        <div className="input-icons">
                          <img src="/img/plan-noche.png" alt="" />
                          <input
                            type="text"
                            id="name"
                            name="dosis_noche_mantenimiento"
                            onChange={handleInputChange}
                            value={datos.dosis_noche_mantenimiento}
                            style={{
                              color: '#9c9d9d',
                            }}
                            class="input-field"
                            type="text" />
                          <p className="horario">por la noche</p>
                        </div>
                      </div>
                    </label>
                  </div>


                  <div className="comentarios">
                    <p className="sub-titles">
                      Comentarios / Otros medicamentos
                    </p>
                    <textarea
                      name="otros_medicamentos"
                      onChange={handleInputChange}
                      style={{ color: '#9c9d9d', textTransform: 'uppercase' }}
                      rows="10"
                      cols="50"
                      value={datos.otros_medicamentos}></textarea>
                  </div>

                  <p className="sub-titles texto-azul">
                    ¿Querés que el destinatario del plan reciba tu dirección de
                    correo electrónico?
                  </p>
                  <div className="checkbox">
                    <label
                      className="recibe-medico container-label"
                      for="emailSi">
                      <input
                        type="radio"
                        id="emailSi"
                        name="recibe_mail"
                        value="si"
                        checked={datos.recibe_mail === 'si'}
                        onChange={handleInputChange}
                      />
                      <span className="checkmark"></span>
                      Si
                    </label>
                    <label
                      className="recibe-medico container-label"
                      for="emailNo">
                      <input
                        type="radio"
                        id="emailNo"
                        name="recibe_mail"
                        value="no"
                        checked={datos.recibe_mail === 'no'}
                        onChange={handleInputChange}
                      />
                      <span className="checkmark"></span>
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="row proxima-consulta">
                <div className="icono-calendario col-md-6">
                  <img src="/img/icono-calendario.png" alt="" />
                </div>
                <div className="formulario col-md-6">
                  <h1 className="texto-blanco">Próxima consulta</h1>
                  <label for="date">
                    FECHA{' '}
                    <input
                      type="date"
                      id="date"
                      name="proxima_consulta"
                      onChange={handleInputChange}
                      style={{ color: '#white', textTransform: 'uppercase' }}
                      value={datos.proxima_consulta}></input>
                  </label>
                  <label for="place">
                    LUGAR{' '}
                    <input
                      type="text"
                      id="place"
                      name="proximo_lugar"
                      onChange={handleInputChange}
                      style={{ color: 'white', textTransform: 'uppercase' }}
                      value={datos.proximo_lugar}></input>
                  </label>
                </div>
              </div>
              <div className="row footer-form">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <p className="referencias">
                    Referencias: <br></br>
                    1. GINA 2020. Disponible en{' '}
                    <a
                      className="referencia-link"
                      href="http://cli.re/GINA"
                      target="_blank">
                      http://cli.re/GINA
                    </a>{' '}
                    <br></br>
                    2. O’Byrne PM, et al. N Engl J Med. 2018;378:1865-1876
                    <br></br>
                    {/* 3. Kuna P, et al. Int J Clin Pract. 2007;61:725-736<br></br> */}
                  </p>

                  <button onClick={requerirCampos} className="btn">
                    SIGUIENTE
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section className="recursos-en-plan-de-accion">
            <h1 className="title">Videos clave para el Plan de Acción</h1>
            <div className="row recursos">
              {props.recursos.map((recurso) => (
                <div className="col-md-4 recurso">
                  <a
                    style={{
                      color: '#10144b',
                    }}
                    href={recurso.link}
                    target="_blank">
                    <img src={recurso.image} alt="" />
                    <h1>{recurso.title}</h1>
                  </a>
                </div>
              ))}
            </div>
            {/* <p className="codigo" >AR-2573 10/2020</p> */}
          </section>
        </div>
      ) : (
          <div>
            <section className="plan-de-accion-resultado">
              <div className="guia-plan fondo-verde row">
                <div className="column-one col-md-6">
                  <p className="complete texto-blanco">
                    Si usas un Plan de Acción por escrito, tenés 4 veces menos
                    probabilidades de ser internado por asma.
                </p>
                  <p className="adams">
                    Adams et al; Factores asociados con admisiones hospitalarias y
                    visitas de emergencia repetidas para adultos con asma; Thorax.
                    2000; 55:566-573.
                </p>
                  <img src="/img/frase-linea-azul.svg" alt="" />
                </div>
                <div className="column-two col-md-6">
                  <p className="como-utilizar texto-azul">
                    ¿Cómo utilizar este plan?
                </p>
                  <div className="instrucciones row">
                    <div className="col-md-6">
                      <div className="paso">
                        <div className="imagenes">
                          <img src="/img/numero-uno.png" alt="" />
                          <img
                            className="contorno-blanco"
                            src="/img/numero-uno-imagen.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Guardalo en algún lugar fácil de encontrar para vos y tu
                          familia.
                      </p>
                      </div>
                      <div className="paso">
                        <div className="imagenes">
                          <img src="/img/numero-tres.png" alt="" />
                          <img
                            className="contorno-blanco"
                            src="/img/numero-tres-imagen.png"
                            alt=""
                          />
                        </div>
                        <p>Chequealo y completalo regularmente.</p>
                      </div>
                    </div>
                    <div className="col-md-6">

                      <div className="paso">
                        <div className="imagenes">
                          <img src="/img/numero-dos.png" alt="" />
                          <img
                            className="contorno-blanco"
                            src="/img/numero-dos-imagen.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Guardá una foto del Plan en tu celular y compartilo por
                          whatsapp con tu familia.
                      </p>
                      </div>
                      <div className="paso">
                        <div className="imagenes">
                          <img src="/img/numero-cuatro.png" alt="" />
                          <img
                            className="contorno-blanco"
                            src="/img/numero-cuatro-imagen.png"
                            alt=""
                          />
                        </div>
                        <p>Llevalo a cada consulta con tu médico.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p
                style={{
                  width: '85%',
                  textAlign: '-webkit-right',
                  paddingRight: '1rem',
                  marginTop: '1rem',
                  color: '#19bfb9',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                }}>
                PLAN DE ACCION DE {datos.nombre + ' ' + datos.apellido}
              </p>
              <div className="row indicaciones-resultado">
                <div className="col-md-6"></div>
                <div className="indicaciones col-md-6">
                  <h1 className="title texto-verde">INDICACIONES</h1>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="indicacion">
                        <h1 className="indicacion-title">Rescate</h1>
                        <p style={{ textTransform: 'uppercase' }}>
                          {datos.alivio_medicacion}
                        </p>

                        <p>HASTA {datos.alivio_dosis} DOSIS DE ALIVIO DIARIAS</p>
                        <p></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {datos['alivio_radio'] != '' && (
                        <div className="indicacion">
                          <h1 className="indicacion-title">Mantenimiento</h1>

                          <p style={{ textTransform: 'uppercase' }}>
                            {datos.alivio_radio === 'otro_medicamento'
                              ? datos.medicacion_Mantenimiento
                              : datos.alivio_medicacion}
                          </p>
                          <p>
                            {datos.dosis_mañana_mantenimiento} DOSIS POR LA MAÑANA
                        </p>
                          <p>
                            {datos.dosis_noche_mantenimiento} DOSIS POR LA NOCHE
                        </p>
                        </div>
                      )}

                      <div className="indicacion">
                        <h1 className="indicacion-title">Otros medicamentos</h1>
                        <p style={{ textTransform: 'uppercase' }}>
                          {datos.otros_medicamentos}
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cuadro">
                <div className="row fondo-verde cuadro-head">
                  <div className="col-md-6">
                    <p>Si me siento...</p>
                  </div>
                  <div className="col-md-3">
                    <p>Estoy...</p>
                  </div>
                  <div className="col-md-3">
                    <p>Debo...</p>
                  </div>
                </div>
                <div className="row resultado-bien">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="mobile-cuadro fondo-verde">
                          <p>Si me siento...</p>
                        </div>
                        <img src="/img/cara-feliz-test.png" alt="" />
                        <p className="estado negrita">Bien</p>
                      </div>
                      <div className="col-md-7">
                        <ul className="sintomas">
                          <li className="negrita">Respiro tranquilo.</li>
                          <li>No siento silbidos.</li>
                          <li>Puedo caminar sin agitarme.</li>
                          <li>Duermo sin problemas respiratorios.</li>
                          <li>
                            Uso poco los rescates (hasta dos veces por semana)
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mobile-cuadro fondo-verde">
                      <p>Estoy...</p>
                    </div>
                    <p className="negrita">BIEN Y TENGO MI ASMA CONTROLADA</p>
                  </div>
                  <div className="col-md-3">
                    <div className="mobile-cuadro fondo-verde">
                      <p>Debo...</p>
                    </div>
                    <p>
                      Continuar con la medicación según lo indicado y acudir a la
                      consulta de control acordada con mi médico.
                  </p>
                  </div>
                </div>
                <div className="row resultado-alerta">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="mobile-cuadro fondo-amarillo">
                          <p>Si me siento...</p>
                        </div>
                        <img src="/img/cara-normal-naranja.png" alt="" />
                        <p className="estado negrita">Alerta</p>
                      </div>
                      <div className="col-md-7">
                        <ul className="sintomas">
                          <li className="negrita">Agotado.</li>
                          <li>Siento alguna dificultad para respirar.</li>
                          <li>
                            No puedo realizar todo lo que habitualmente hago.
                        </li>
                          <li>Necesito mas de dos rescates por semana.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mobile-cuadro fondo-amarillo">
                      <p>Estoy...</p>
                    </div>
                    <p className="negrita">EMPEORANDO UN POCO</p>
                  </div>
                  <div className="col-md-3">
                    <div className="mobile-cuadro fondo-amarillo">
                      <p>Debo...</p>
                    </div>
                    <p className="negrita">RESCATE</p>
                    <p style={{ textTransform: 'uppercase' }}>
                      {datos.alivio_medicacion}
                    </p>
                    <p>HASTA {datos.alivio_dosis} DOSIS DE RESCATE DIARIAS</p>
                  </div>
                </div>
                <div className="row resultado-peligro">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="mobile-cuadro fondo-rojo">
                          <p>Si me siento...</p>
                        </div>
                        <img src="/img/cara-triste-roja.png" alt="" />
                        <p className="estado negrita">Peligro</p>
                      </div>
                      <div className="col-md-7">
                        <ul className="sintomas">
                          <li className="negrita">
                            Con dificultad para respirar.
                        </li>
                          <li>Siento muchos ruidos en el pecho al caminar.</li>
                          <li>Siento dificultad para inhalar.</li>
                          <li>
                            No puedo realizar ninguna actividad por falta de aire.
                        </li>
                          <li>
                            Utilicé mayor cantidad de rescates recomendados.
                        </li>
                          <li>
                            Los síntomas no mejoran a pesar del uso de los
                            rescates.
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mobile-cuadro fondo-rojo">
                      <p>Estoy...</p>
                    </div>
                    <p>NUEVAMENTE EN CRISIS.</p>
                  </div>
                  <div className="col-md-3">
                    <div className="mobile-cuadro fondo-rojo">
                      <p>Debo...</p>
                    </div>
                    <p className="negrita">
                      CONSULTAR A MI MEDICO INMEDIATAMENTE:
                  </p>
                    <p style={{ marginBottom: '1rem' }}>{userState && user.name}</p>
                    <p
                      style={{ marginBottom: '1rem' }}
                      className="ambulancia negrita">
                      CONCURRIR A LA GUARDIA.
                  </p>
                    <p className="ambulancia negrita"> LLAMAR A UNA AMBULANCIA</p>
                  </div>
                </div>
              </div>
              <div className="informacion-util">
                <div className="title row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <h1 className="texto-verde ">INFORMACIÓN ÚTIL</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="iconos col-md-6 col-4">
                    <img
                      className="icono-negro"
                      src="/img/calendario-negro.png"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-8">
                    <h2 className="negrita texto-negro">Próxima Consulta</h2>
                    {datos.proxima_consulta && (
                      <p className="texto-negro">Fecha: {formatDate(datos.proxima_consulta)}</p>
                    )}
                    <p className="texto-negro">Lugar: {datos.proximo_lugar}</p>
                    <p className="texto-negro">Dr. {userState && user.name}</p>

                    {datos.recibe_mail == 'si' && (
                      <p className="texto-negro">
                        E-mail: {user.email}
                      </p>
                    )}
                    <div className="compartir">
                      <ShareButton
                        id="button-compartir"
                        plan={true}
                        email={true}
                        datos={datos}
                        emailProfesional={user.email}
                        nombreProfesional={user.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">

                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">

                  </div>
                </div>
                <div className="buttons row">
                  <div className="col-md-5"></div>
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-6">
                        <button onClick={volverButton} className="btn">
                          EDITAR INFO
                      </button>
                      </div>
                      <div className="col-md-6">
                        <button onClick={terminarButton} className="btn">
                          TERMINAR
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <p className="container codigo" style={{ marginBottom: '2rem' }} >AR-2573 10/2020</p> */}

          </div>
        )}
      <Footer codigo='AR-2573 10/2020' />
      <Modal
        keyboard
        enforceFocus
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={planEnviado}
        onHide={handleClosePlanEnviado}>
        <Modal.Body className="ingresar-modal">
          <div className="container">
            <p className="texto-verde">ASMA ZERO / HERRAMIENTAS</p>
            <h1>Plan de Acción</h1>
            <p>El Plan fue enviado satisfactoriamente</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

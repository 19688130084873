import { FETCH_SECTIONS } from "../constants";

const initialState = {
  sections: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SECTIONS:
      return Object.assign({}, state, { sections: action.sections });
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const ShareButton = (props) => {
  const testProps = {
    result: props.resultadoControl,
    img: props.resultadoImg,
  };

  const [showEmailShare, setShowEmailShare] = useState(false);
  const handleShowEmailShare = () => setShowEmailShare(true);
  const handleCloseEmailShare = () => {
    setShowEmailShare(false);
    setDatosEnviados(false);
  };

  const initialDatos = {
    name: '',
    email: '',
    nameProfesional: '',
    emailProfesional: '',
    formName: 'evaluacion control',
  };

  const [datos, setDatos] = useState(initialDatos);
  const [datosEnviados, setDatosEnviados] = useState(false);
  const [planEnviado, setPlanEnviado] = useState(false);
  const handleClosePlanEnviado = () => setPlanEnviado(false);
  const [datosClases, setDatosClases] = useState({
    name: 'disabled',
    email: 'disabled',
    nameProfesional: 'disabled',
    emailProfesional: 'disabled',
  });

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const handleDatosClases = (string, key) => {
    let val = string;
    setDatosClases((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const requerirCampos = () => {
    let camposIncompletos = 0;
    let keys = ['name', 'email', 'nameProfesional', 'emailProfesional'];
    keys.map((key) => {
      if (datos[key] === '') {
        handleDatosClases('active', key);
        camposIncompletos++;
      } else {
        handleDatosClases('disabled', key);
      }
    });
    if (camposIncompletos == 0) {
      axios.post(
        'https://pd3i92cpeb.execute-api.us-east-1.amazonaws.com/prod/sendmail',
        { datos, testProps },
      );
      setDatos(initialDatos);
      setDatosEnviados(true);
    }
  };

  const sendPlan = () => {
    setPlanEnviado(true);
    let datos = props.datos;
    datos['emailProfesional'] = props.emailProfesional;
    datos['nombreProfesional'] = props.nombreProfesional;
    // console.log(datos)
    axios.post(
      'https://pd3i92cpeb.execute-api.us-east-1.amazonaws.com/prod/sendmail',
      JSON.stringify({ datos }),
    );
  };

  return (
    <NavDropdown className="share-button-component" title="" id={props.id}>
      {props.facebook && (
        <NavDropdown.Item >
          <FacebookShareButton
            children="Facebook"
            url={props.url}
            quote={props.quote}
            eventKey="4.1"
          >
            <FacebookIcon style={{ marginRight: '.5rem' }} size={25} borderRadius={"50%"} />
         Facebook
       </FacebookShareButton>
        </NavDropdown.Item>
      )}
      {props.twitter && (
        <NavDropdown.Item eventKey="4.2">
          {/*  */}
          <TwitterShareButton
            children="Twitter"
            url={props.url}
            title={props.quote}
          >
            <TwitterIcon style={{ marginRight: '.5rem' }} size={25} borderRadius={"50%"} />
          Twitter
        </TwitterShareButton>
        </NavDropdown.Item>
      )}
      {props.whatsapp && (
        <NavDropdown.Item eventKey="4.3">

          <WhatsappShareButton
            children="Whatsapp"
            url={props.url}
            title={props.quote}
          >
            <WhatsappIcon style={{ marginRight: '.5rem' }} size={25} borderRadius={"50%"} />
         Whatsapp
       </WhatsappShareButton>
        </NavDropdown.Item>
      )}
      {props.email && (
        <NavDropdown.Item eventKey="4.4">

          {props.testResult || props.plan ? (
            <div onClick={props.plan ? sendPlan : handleShowEmailShare} style={{ display: "inline", marginLeft: "0.5rem" }} >E-mail</div>
          ) : (
              <EmailShareButton
                children="E-mail"
                url={props.url}
                subject={props.subject}
                body={props.quote}
              >
                <EmailIcon style={{ marginRight: '.5rem' }} size={25} borderRadius={"50%"} />
              Email
              </EmailShareButton>
            )}
        </NavDropdown.Item>
      )}
      <Modal
        centered
        onHide={handleCloseEmailShare}
        show={showEmailShare}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-inscribirse">
        {/* <Modal.Header className="header" closeButton>
          <h4 className="title">COMPARTIR RESULTADO</h4>
        </Modal.Header> */}
        <Modal.Body className="ingresar-modal modal-body">
          <div className="container">
            <p className="texto-verde">ASMA ZERO / EVALUACION DE CONTROL</p>
            <h1>COMPARTIR RESULTADO</h1>
            <p>Datos del paciente:</p>
            <div className="formulario">
              <label htmlFor="name">
                Nombre:
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                  value={datos.name}
                />
                <p className={datosClases.name}>Ingrese su nombre</p>
              </label>
              <label htmlFor="email">
                Email:
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  value={datos.email}
                />
                <p className={datosClases.email}>Ingrese su Email</p>
              </label>
              <p>Datos del profesional:</p>
              <label htmlFor="nameProfesional">
                Nombre:
                <input
                  type="text"
                  id="nameProfesional"
                  name="nameProfesional"
                  onChange={handleInputChange}
                  value={datos.nameProfesional}
                />
                <p className={datosClases.nameProfesional}>
                  Ingrese nombre del Profesional
                </p>
              </label>
              <label htmlFor="emailProfesional">
                E-mail:
                <input
                  name="emailProfesional"
                  onChange={handleInputChange}
                  type="emailProfesional"
                  id="emailProfesional"
                  value={datos.emailProfesional}
                />
                <p className={datosClases.emailProfesional}>
                  Ingrese email del Profesional
                </p>
              </label>
              {datosEnviados ? (
                <p className="datos-enviados">
                  Su test fue enviado correctamente
                </p>
              ) : (
                  <button onClick={requerirCampos}>Enviar</button>
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        keyboard
        enforceFocus
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={planEnviado}
        onHide={handleClosePlanEnviado}>
        <Modal.Body className="ingresar-modal">
          <div className="container">
            <p className="texto-verde">ASMA ZERO / HERRAMIENTAS</p>
            <h1>Plan de Acción</h1>
            <p>El Plan fue enviado satisfactoriamente</p>
          </div>
        </Modal.Body>
      </Modal>
    </NavDropdown>
  );
};

export default ShareButton;

import React from 'react'

export default function IconoAsma() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <g
                id="Menú-mediTuDependencia"
                transform="translate(-453.425 -736.779)"
            >
                <path
                    id="Trazado_1015"
                    class="st0"
                    d="M472.9,773.8c-1.5,0.8-2.2,2.5-1.8,4.2l8,32.6v2.5c0,2.4,1.9,4.3,4.3,4.3h31.4   c2.4,0,4.3-1.9,4.3-4.3v-11.3c0-2.4-1.9-4.3-4.3-4.3h-16.4l-6.1-24.6c-0.4-1.6-1.8-2.8-3.5-2.9l0,0l-3-12.5l0,0   c-0.5-2.2-2.8-3.6-5-3.1l-7.8,1.9c-2.2,0.5-3.6,2.8-3.1,5c0,0,0,0,0,0l0,0L472.9,773.8z M512.3,800.6h2.4c0.6,0,1.1,0.5,1.1,1.2   V813c0,0.6-0.5,1.1-1.1,1.2h-2.4V800.6z M499.1,800.6h10.1v13.6h-25.8c-0.1,0-0.2,0-0.3,0c-0.5-0.1-0.9-0.6-0.9-1.1V810   c0-5.2,4.2-9.4,9.4-9.4L499.1,800.6L499.1,800.6z M488.5,773.1L488.5,773.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1   c0.1,0.1,0.3,0.2,0.3,0.4l5.9,23.9h-3.5c-4.5,0-8.7,2.5-10.9,6.4l-6.5-26.7c-0.1-0.3,0.1-0.7,0.4-0.8l0,0L488.5,773.1z M473,759.8   c0.1-0.2,0.4-0.4,0.6-0.5l7.8-1.9c0.5-0.1,1.1,0.2,1.2,0.8c0,0,0,0,0,0l0,0l3,12.4l-6,1.5l-0.5,0.1l-3.3,0.8l-3-12.4   C472.8,760.3,472.9,760,473,759.8L473,759.8z"
                ></path>
                <path
                    id="Trazado_1016"
                    class="st0"
                    d="M535.6,805.8h-10.7c-0.9,0-1.5,0.8-1.5,1.6c0,0.8,0.7,1.4,1.5,1.5h10.7   c0.9,0,1.5-0.8,1.5-1.6C537.1,806.5,536.4,805.9,535.6,805.8L535.6,805.8z"
                ></path>
                <path
                    id="Trazado_1017"
                    class="st0"
                    d="M524.9,802.3l10-3.9c1.8-0.7,1-3.7-0.8-3l-10,3.9C522.3,800,523.1,803,524.9,802.3z"
                ></path>
                <path
                    id="Trazado_1018"
                    class="st0"
                    d="M534.9,816.2l-10-3.9c-1.9-0.7-2.7,2.3-0.8,3l10,3.9C536,819.9,536.8,816.9,534.9,816.2z"
                ></path>
            </g>
        </svg>
    )
}
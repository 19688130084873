import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import RedesIconos from "./IconosRedesInterna";
import NavbarInterna from "./NavbarInterna";
import Footer from "./Footer";
import Menu from './Menu';
import Button from './ButtonMenu';
import { codigoPacientes } from "../codigos";
import Eventos from "./Eventos";
import SingleEvento from "./SingleEvento";
import { useSelector, shallowEqual } from "react-redux";
import ModalIngresar from "../components/ModalIngresar";
import moment from 'moment'
import _ from 'lodash'
import Helmet from 'react-helmet'



export default function EventosPage(props) {

  const [modalShow, setModalShow] = React.useState(false);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const eventos = useSelector((state) => state.events, shallowEqual)
  const selectedCountry = useSelector((state)=> state.country.selectedCountry);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)
  
  let eventoSlug = props.match.params.slug;
  let eventoId = props.match.params.id;

  const newEvents = _.filter(eventos.allEvents, e => moment(e.date).isAfter());

  let masEventos = false;

  const handleSingleEvento = (arrayEventos, eventoSlug, eventoId) => {
    let singleEvento;
    if (arrayEventos) {
      singleEvento = arrayEventos.filter(
        (evento) => evento["slug"] == eventoSlug && evento["id_event"] == eventoId && evento[selectedCountry.toLowerCase()] == '1'
      )
      return singleEvento[0]
    }
  }

  let singleEvento = handleSingleEvento(eventos.allEvents, eventoSlug, eventoId)


  return (
    <div className={internaClass}>
      <Helmet>
        <title>Asma Zero - Eventos</title>
        <meta name="description" content="Asma Zero - Eventos" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna
            props={props}
          />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <div style={{ backgroundColor: "#10144b" }}>
        <div id="interna-banner" className="margin-bottom-7">
          <div className="container">
            <p className="miga-de-pan seccion text-center">
              <Link style={{ textDecoration: "none", color: "#35aea9" }} to="/">
                ASMA ZERO{" "}
              </Link>{" "}
              /{" "}
              <Link
                style={{ textDecoration: "none", color: "#35aea9" }}
                to="/eventos"
              >
                EVENTOS{" "}
              </Link>
            </p>
            <h1 id="evento-page-title" className="text-center texto-blanco">
              Eventos Asma Zero
            </h1>

            <p className="text-center copete-eventos texto-blanco">
              {" "}
              Presenciales y digitales, para capacitación continua. <br></br>
              {!user && (
                <span>
                  <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem' }} id="evento-link" onClick={() => setModalShow(true)} ><strong>Inicia Sesión</strong></a> para acceder a eventos exclusivos para Profesionales de la Salud.
                </span>
              )}
            </p>
          </div>
        </div>

        {eventoSlug ? (
          <SingleEvento props={props} evento={singleEvento} />
        ) : (
            <div>
              {eventos.allEvents && (
                <div>
                  {user ? (
                    <div>

                      <Eventos masEventos={masEventos} eventos={eventos.allEvents} />

                    </div>
                  ) : (
                      <div>

                        <Eventos masEventos={masEventos} eventos={eventos.disloggedEvents} />

                      </div>
                    )}
                </div>
              )}
            </div>
          )}
      </div>
      <ModalIngresar ingresar={true} show={modalShow} onHide={() => setModalShow(false)} />
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

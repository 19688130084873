import React from 'react'

export default function LineamientosCuadro(props) {
    return (
        <div>
            <div className="container-cuadro-lineamientos">
                <div className="fila texto-blanco fondo-verde">
                    <div className="columna"></div>
                    <div className="columna">
                        <p className="bold" >Intermitente</p>
                    </div>
                    <div className="columna">
                        <p className="bold" >Persistente Leve</p>
                    </div>
                    <div className="columna">
                        <p className="bold" > Moderada</p>
                    </div>
                    <div className="columna">
                        <p className="bold" >Persistente Grave</p>
                    </div>
                </div>
                <div className="fila fondo-blanco texto-azul">
                    <div className="columna">
                        <p className="bold" >Sintomas diurnos</p>
                    </div>
                    <div className="columna">
                        <p>NO (2 veces o menos a la semana)</p>
                    </div>
                    <div className="columna">
                        <p>Más de 2 veces a la semana</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas a diario</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas continuos (varias veces al día)</p>
                    </div>
                </div>
                <div className="fila fondo-gris texto azul">
                    <div className="columna">
                        <p className="bold" >Medicación de alivio (agonista B2 - adregénico de acción corta)</p>
                    </div>
                    <div className="columna">
                        <p>NO (2 veces o menos a la semana)</p>
                    </div>
                    <div className="columna">
                        <p>Más de 2 veces a la semana</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas a diario</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas continuos (varias veces al día)</p>
                    </div>
                </div>
                <div className="fila fondo-blanco texto-azul">
                    <div className="columna">
                        <p className="bold" >Síntomas nocturnos</p>
                    </div>
                    <div className="columna">
                        <p>No más de 2 veces al mes
</p>
                    </div>
                    <div className="columna">
                        <p>No más de 2 veces al mes
</p>
                    </div>
                    <div className="columna">
                        <p>Más de una vez a la semana
</p>
                    </div>
                    <div className="columna">
                        <p>Frecuentes</p>
                    </div>
                </div>
                <div className="fila fondo-gris texto azul">
                    <div className="columna">
                        <p className="bold" >Limitación de la atividad</p>
                    </div>
                    <div className="columna">
                        <p>Ninguna
</p>
                    </div>
                    <div className="columna">
                        <p>Algo
</p>
                    </div>
                    <div className="columna">
                        <p>Bastante
</p>
                    </div>
                    <div className="columna">
                        <p>Mucha</p>
                    </div>
                </div>
                <div className="fila fondo-blanco texto-azul">
                    <div className="columna">
                        <p className="bold" >Función pulmonar (FEV1 o PEF) % teórico</p>
                    </div>
                    <div className="columna">
                        <p>>80%
</p>
                    </div>
                    <div className="columna">
                        <p>>80%
</p>
                    </div>
                    <div className="columna">
                        <p>>60% >80%
</p>
                    </div>
                    <div className="columna">
                        <p>>60%</p>
                    </div>
                </div>
                <div className="fila fondo-gris texto azul">
                    <div className="columna">
                        <p className="bold" >Exacerbaciones</p>
                    </div>
                    <div className="columna">
                        <p>Ninguna
</p>
                    </div>
                    <div className="columna">
                        <p> Una o ninguna al año
</p>
                    </div>
                    <div className="columna">
                        <p> Dos o más al año
</p>
                    </div>
                    <div className="columna">
                        <p>Dos o más al año</p>
                    </div>
                </div>
                <div className="textos-verdes texto-verde ">
                    <p>FEV1: volumen espiratorio forzado en el primer segundo: PEF: Flujo respiratorio máximo.</p>
                    <p>GEMA. Tabla 2.9 "Clasificación de la gravedad del asma en adultos (antes de recibir tratamiento)". Disponible en
http://www.gemasma.com/control-gravedad-del-asma-adulto/</p>
                </div>
            </div>


            <div className="lineamientos-cuadro-mobile">
                <div className="fondo-verde texto-blanco fila" >
                    <div className="columna"></div>
                    <div className="columna">Intermitente</div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Sintomas diurnos</p>
                    </div>
                    <div className="columna">
                        <p>NO (2 veces o menos a la semana)</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Medicación de alivio (agonista B2 - adregénico de acción corta)</p>
                    </div>
                    <div className="columna">
                        <p>NO (2 veces o menos a la semana)</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Síntomas nocturnos</p>
                    </div>
                    <div className="columna">
                        <p>No más de 2 veces al mes
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Limitación de la atividad</p>
                    </div>
                    <div className="columna">
                        <p>Ninguna
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Función pulmonar (FEV1 o PEF) % teórico</p>
                    </div>
                    <div className="columna">
                        <p>>80%
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Exacerbaciones</p>
                    </div>
                    <div className="columna">
                        <p>Ninguna
</p>
                    </div>
                </div>


                <div className="fondo-verde texto-blanco fila" >
                    <div className="columna"></div>
                    <p className="bold" >Persistente Leve</p>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Sintomas diurnos</p>
                    </div>
                    <div className="columna">
                        <p>Más de 2 veces a la semana</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Medicación de alivio (agonista B2 - adregénico de acción corta)</p>
                    </div>
                    <div className="columna">
                        <p>Más de 2 veces a la semana</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Síntomas nocturnos</p>
                    </div>
                    <div className="columna">
                        <p>No más de 2 veces al mes
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Limitación de la atividad</p>
                    </div>
                    <div className="columna">
                        <p>Algo
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Función pulmonar (FEV1 o PEF) % teórico</p>
                    </div>
                    <div className="columna">
                        <p>>80%
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Exacerbaciones</p>
                    </div>
                    <div className="columna">
                        <p> Una o ninguna al año
</p>
                    </div>
                </div>


                <div className="fondo-verde texto-blanco fila" >
                    <div className="columna"></div>
                    <p className="bold" > Moderada</p>

                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Sintomas diurnos</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas a diario</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Medicación de alivio (agonista B2 - adregénico de acción corta)</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas a diario</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Síntomas nocturnos</p>
                    </div>
                    <div className="columna">
                        <p>Más de una vez a la semana
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Limitación de la atividad</p>
                    </div>
                    <div className="columna">
                        <p>Bastante
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Función pulmonar (FEV1 o PEF) % teórico</p>
                    </div>
                    <div className="columna">
                        <p>>60% >80%
</p>
                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Exacerbaciones</p>
                    </div>
                    <div className="columna">
                        <p> Dos o más al año
</p>
                    </div>
                </div>


                <div className="fondo-verde texto-blanco fila" >
                    <div className="columna"></div>
                    <p className="bold" >Persistente Grave</p>

                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Sintomas diurnos</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas continuos (varias veces al día)</p>

                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Medicación de alivio (agonista B2 - adregénico de acción corta)</p>
                    </div>
                    <div className="columna">
                        <p>Sintomas continuos (varias veces al día)</p>

                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Síntomas nocturnos</p>
                    </div>
                    <div className="columna">
                        <p>Frecuentes</p>

                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Limitación de la atividad</p>
                    </div>
                    <div className="columna">
                        <p>Mucha</p>

                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Función pulmonar (FEV1 o PEF) % teórico</p>
                    </div>
                    <div className="columna">
                        <p>>60%</p>

                    </div>
                </div>
                <div className="fila">
                    <div className="columna">
                        <p className="bold" >Exacerbaciones</p>
                    </div>
                    <div className="columna">
                        <p>Dos o más al año</p>

                    </div>
                </div>
            </div>
        </div>
    )
}
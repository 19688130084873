import {
  RESET_USER_DATA,
  SET_USER_DATA,
  SET_USER_STATE,
  SET_VALID_USER,
  SET_MATCH,
  SET_URL_BACK,
  API_TRACKING,
  SET_COOKIE
} from "../constants";
import {
  Auth
} from "aws-amplify";
import {
  delcookie
} from '../../components/Cookie';


const resetUserData = () => ({
  type: RESET_USER_DATA,
});

const receiveAuthData = (authData) => ({
  type: SET_USER_DATA,
  authData,
});

const receiveAuthState = (authState) => ({
  type: SET_USER_STATE,
  authState,
});

export const setUrlBack = url => ({
  type: SET_URL_BACK,
  url
})

export const receiveMatch = match => ({
  type: SET_MATCH,
  match
})

export const receiveValidUser = validUser => ({
  type: SET_VALID_USER,
  validUser
})

export const receiveCookie = cookie => ({
  type: SET_COOKIE,
  cookie
})

export const storeAuthData = authData => dispatch => {
  dispatch(receiveAuthData(authData));
};

export const storeAuthState = (authState) => (dispatch) => {
  dispatch(receiveAuthState(authState));
};

export const logout = () => (dispatch) => {
  dispatch(resetUserData());
  delcookie('azmed_user_token');
  // Auth.signOut()
  //   .then(() => dispatch(receiveAuthState(false)))
  //   .catch(() => false);
};

export const tracking = async (userId, sectionId, noteId, userName, ip, token, valor) => {
  let datos = {
    id_user: userId,
    id_section: sectionId,
    id_note: noteId,
    name: userName,
    from: "web",
    variable: "test",
    valor: valor,
    ip: ip,
    token: token,
    formName: "track"
  }

  const rawResponse = await fetch(API_TRACKING, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      datos
    })
  });

  const content = await rawResponse.text()

  console.log(content)

  // axios.post("https://4gyvcb3ftd.execute-api.us-east-1.amazonaws.com/prod/users/track", JSON.stringify({
  //   datos
  // }))
  // axios.post("https://vjy58n8yh1.execute-api.us-east-1.amazonaws.com/default/tracking-prod-hello", {
  //       mode: 'no-cors'
  //     },
  //     JSON.stringify({
  //       datos
  //     }))
  //   .then(results => console.log(results))
  //   .catch(error => console.error(error))

}
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RedesIconos from "./IconosRedesInterna";
import NavbarInterna from "./NavbarInterna";
import Footer from "./Footer";
import { codigoPacientes } from "../codigos";
import { animateScroll as scroll } from "react-scroll";
import axios from "axios";
import Menu from './Menu';
import Button from './ButtonMenu';
import { useSelector, shallowEqual } from 'react-redux';
import { URL } from '../store/constants'
import { tracking } from '../store/actions/user'
import Helmet from "react-helmet";
import ReactGa from 'react-ga'



export default function Contacto(props) {

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);

  let [send, setSend] = useState(false);
  const initialDatos = {
    "nombre": "",
    "email": "",
    "asunto": "",
    "consulta": "",
    "formName": "contacto"
  };
  const [datos, setDatos] = useState(initialDatos);
  const [datosClases, setDatosClases] = useState({
    nombre: "disabled",
    email: "disabled",
    asunto: "disabled",
    consulta: "disabled",
  });

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const handleDatosClases = (string, key) => {
    let val = string;
    setDatosClases((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const requerirCampos = () => {
    let camposIncompletos = 0;
    let keys = ["nombre", "email", "asunto", "consulta"];
    keys.map((key) => {
      if (datos[key] === "") {
        handleDatosClases("active", key);
        camposIncompletos++;
      } else {
        handleDatosClases("disabled", key);
      }
    });
    if (camposIncompletos == 0) {
      setSend(true);
      scroll.scrollToTop(0, 0);
      axios.post("https://pd3i92cpeb.execute-api.us-east-1.amazonaws.com/prod/az-prod-sendmail-prod-mailing", JSON.stringify({ datos }));
    }
  };

  useEffect(() => {
    if (userState) {
      tracking(user['user_id'], '17', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/contacto")

  }, [])

  return (
    <div className={internaClass}>
      <Helmet>
        <title>Asma Zero - Contacto</title>
        <meta name="description" content="Asma Zero - Contacto" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna
            props={props}
          />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <div className="contacto-fondo"></div>
      <main id="interna-banner">
        <div className="container">
          <p className="miga-de-pan seccion text-center"><Link
            style={{ textDecoration: "none", color: "#35aea9" }}
            to="/"
          >
            ASMA ZERO{" "}
          </Link>{" "}
                  /{" "} CONTACTO</p>
          <h1 className="text-center">Contactanos</h1>
        </div>
      </main>

      <section>
        <div className="container contacto position-relative">
          <div className="contacto-fondo-2 fondo-verde"></div>
          <div className="row">
            <div className="col-md-5">
              <h3 className="titulo texto-blanco mb-4">
                {send
                  ? "¡Muchas gracias! El mensaje ha sido enviado. Responderemos lo antes posible"
                  : "Por favor, completá el siguiente formulario para enviarnos tus consultas o comentarios."}
              </h3>
              <img
                src="/img/frase-linea-azul.svg"
                className="frase-linea w-100 mb-5"
              ></img>
            </div>
            {!send && (
              <div className="col-md-7 formulario texto-blanco">
                <div className="row">
                  <div className="col-md-6">
                    <label for="nombre">
                      NOMBRE*
                      <input
                        onChange={handleInputChange}
                        type="text"
                        id="nombre"
                        name="nombre"
                      ></input>
                      <p className={datosClases.nombre}>Ingrese su Nombre</p>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label for="email">
                      EMAIL*
                      <input
                        onChange={handleInputChange}
                        type="email"
                        id="email"
                        name="email"
                      ></input>
                      <p className={datosClases.email}>Ingrese su Email</p>
                    </label>
                  </div>
                  <div className="col-md-12">
                    <label for="asunto">
                      ASUNTO*
                      <input
                        onChange={handleInputChange}
                        type="text"
                        id="asunto"
                        name="asunto"
                      ></input>
                      <p className={datosClases.asunto}>ingrese un Asunto</p>
                    </label>
                  </div>
                  <div className="col-12 mb-4">
                    <label id="textarea-contacto" for="mensaje">
                      CONSULTAS O COMENTARIOS*
                      <textarea
                        onChange={handleInputChange}
                        id="mensaje"
                        name="consulta"
                        rows="10"
                      ></textarea>
                      <p className={datosClases.consulta}>
                        Ingrese un Comentario
                      </p>
                    </label>
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                    <button onClick={requerirCampos}>ENVIAR</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/*<section>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="offset-md-5 col-md-4">
              <p style={{ fontSize: "1.5rem" }} className="texto-verde">
                Para reportar un evento adverso de un producto de AstraZeneca
                por favor hacer{" "}
                <a
                  href="https://aereporting.astrazeneca.com/content/WebsiteServices/Global/286-globalaereporting-com/ar/ar/home.html?Argentina"
                  target="_blank"
                  className="texto-azul"
                >
                  CLICK ACÁ
                </a>{" "}

              </p>
            </div>
          </div>
        </div>
      </section>*/}
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

import {
  FETCH_NOTES
} from "../constants";

const initialState = {
  notes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTES:
      return Object.assign({}, state, {
        notes: action.notes.filter((note) => note.show_on_web == "1"),
        bannerHomeDislogged: filterArrayNotes(
          action.notes.filter(
            (note) =>
            note.show_on_web == "1" &&
            note.show_on_home == "1" &&
            note.professional == "0" &&
            note.member == "0" &&
            note.id_section != "24" &&
            note.id_section != "25"
          )
        ),
        bannerHomeLoggedIn: action.notes.filter(
          (note) =>
          note.show_on_web == "1" &&
          note.professional == "1" &&
          note.show_on_home == "1"
        ),
      });
    default:
      return state;
  }
};

const filterArrayNotes = (arrayNotes) => {
  let newArray = [];
  for (let i = 0; i < 3; i++) {
    let randomNum = Math.floor(Math.random() * arrayNotes.length);
    let note = arrayNotes.splice(randomNum, 1);
    newArray.push(note[0]);
  }
  return newArray;
};
import {
  SET_DESPERTAR,
  SET_LIMITACION,
  SET_SINTOMAS,
  SET_NECESIDAD,
  RESET_TEST,
  FETCH_QUESTIONS,
  SET_DESTINATARIO
} from "../constants";

const initialState = {
  sintomas: null,
  limitacion: null,
  necesidad: null,
  despertar: null,
  destinatario: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS:
      return Object.assign({}, state, {
        questions: action.questions
      })
    case SET_DESPERTAR:
      return Object.assign({}, state, {
        despertar: action.boolean
      });
    case SET_NECESIDAD:
      return Object.assign({}, state, {
        necesidad: action.boolean
      });
    case SET_LIMITACION:
      return Object.assign({}, state, {
        limitacion: action.boolean
      });
    case SET_SINTOMAS:
      return Object.assign({}, state, {
        sintomas: action.boolean
      });
    case SET_DESTINATARIO:
      return Object.assign({}, state, {
        destinatario: action.destinatario
      });
    case RESET_TEST:
      return Object.assign({}, state, {
        despertar: action.payload,
        necesidad: action.payload,
        limitacion: action.payload,
        sintomas: action.payload,
      });
    default:
      return state;
  }
};
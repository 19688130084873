import React from 'react'

export default function IconoPlan() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <g
                id="Menú-plandeaccion"
                transform="translate(-619.731 -736.711)"
            >
                <path
                    id="Trazado_1401"
                    class="st0"
                    d="M698.8,797L698.8,797c0.2-0.3,0.3-0.7,0.2-1.1c-0.1-0.4-0.3-0.7-0.6-0.9l-12.2-7.8   c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4l12.2-7.9c0.3-0.2,0.6-0.5,0.6-0.9c0.1-0.4,0-0.8-0.2-1.1l-7.7-11.9   c-0.4-0.7-1.3-0.9-2-0.5c0,0,0,0,0,0l0,0l-9.9,6.4c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.2-0.3-0.2-0.4v-11.8c0-0.8-0.7-1.5-1.5-1.5   h-14.2c-0.8,0-1.5,0.7-1.5,1.5V770c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.1-0.6-0.1l-9.9-6.4c-0.3-0.2-0.7-0.3-1.1-0.2   c-0.4,0.1-0.7,0.3-0.9,0.6l-7.7,11.9c-0.2,0.3-0.3,0.7-0.2,1.1c0.1,0.4,0.3,0.7,0.6,0.9l12.2,7.9c0.1,0.1,0.2,0.3,0.2,0.4   c0,0.2-0.1,0.3-0.2,0.4l-12.2,7.9c-0.3,0.2-0.6,0.5-0.6,0.9c-0.1,0.4,0,0.8,0.2,1.1l7.7,11.9c0.2,0.3,0.5,0.6,0.9,0.7   c0.3,0.1,0.6,0,0.9-0.1l10.1-6.5c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.4v11.8c0,0.8,0.7,1.5,1.5,1.5   h14.2c0.8,0,1.5-0.7,1.5-1.5v-11.8c0-0.2,0.1-0.3,0.2-0.4l0,0c0.2-0.1,0.4-0.2,0.6-0.1l10,6.5c0.7,0.4,1.6,0.2,2-0.5L698.8,797z    M689.7,805.7L689.7,805.7c-0.2,0.3-0.5,0.3-0.8,0.1l-11.3-7.3c-0.7-0.4-1.6-0.2-2,0.4c-0.2,0.2-0.2,0.5-0.2,0.8v13.5   c0,0.3-0.2,0.5-0.5,0.5h0h-10.2c-0.3,0-0.5-0.2-0.5-0.5l0,0v-13.5c0-0.8-0.7-1.5-1.5-1.5c-0.3,0-0.6,0.1-0.8,0.2l-11.3,7.3   c-0.2,0.2-0.6,0.1-0.7-0.2l-5.5-8.6c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.3,0.3-0.4l13-8.4c0.3-0.2,0.6-0.5,0.6-0.9   c0.1-0.4,0-0.8-0.2-1.1c-0.1-0.2-0.3-0.3-0.4-0.4l-13.1-8.4c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.2,0.1-0.3l5.6-8.6   c0.1-0.2,0.4-0.3,0.7-0.2l11.4,7.4c0.7,0.4,1.6,0.3,2-0.4c0.2-0.2,0.2-0.5,0.2-0.8v-13.5c0-0.3,0.2-0.5,0.5-0.5h0h10.2   c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0l0,0v13.5c0,0.8,0.7,1.5,1.5,1.5c0.3,0,0.6-0.1,0.8-0.2l11.3-7.3c0.2-0.1,0.5-0.1,0.7,0.1   l5.6,8.6c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.3-0.2,0.4l-13,8.4c-0.7,0.4-0.9,1.3-0.4,2c0.1,0.2,0.3,0.3,0.4,0.4l13.1,8.4   c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3L689.7,805.7z"
                ></path>
            </g>
        </svg>
    )
}
import React from 'react'

export default function IconoMapa() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <g
                id="barra-direccion"
                transform="translate(-566.514 -737.199)"
            >
                <path
                    id="Trazado_5"
                    class="st0"
                    d="M616.5,754.7c-0.2,0-0.5,0-0.7,0c-14.7,0.5-26.3,12.5-26.3,27.2c0.3,20.4,25.6,36.8,26.7,37.5   c0.6,0.4,1.3,0.4,1.9,0c1.1-0.7,25.8-18,25.4-38.4C643.1,766.4,631.2,754.7,616.5,754.7z M617.5,815.6c-0.1,0.1-0.2,0.1-0.4,0.1   c-0.1,0-0.3,0-0.4-0.1c-5.5-3.9-23.5-17.9-23.8-33.8c-0.2-13,10.2-23.8,23.2-24l0,0c0.1,0,0.2,0,0.4,0c12.9,0,23.4,10.3,23.6,23.2   C640.4,796.9,622.9,811.5,617.5,815.6L617.5,815.6z"
                ></path>
                <path
                    id="Trazado_6"
                    class="st0"
                    d="M616.5,771.3h-0.2c-5.6,0.1-10.1,4.7-9.9,10.3c0.1,5.5,4.6,9.9,10.1,9.9h0.2   c5.6-0.1,10-4.7,9.9-10.3C626.5,775.7,622,771.3,616.5,771.3L616.5,771.3z M616.6,788.2h-0.1c-3.7,0-6.7-3-6.7-6.7   c0-3.7,2.9-6.7,6.6-6.7h0.1c3.7,0,6.7,3,6.7,6.7C623.2,785.1,620.3,788.1,616.6,788.2z"
                ></path>
            </g>
        </svg>
    )
}
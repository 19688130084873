import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

const MenuQuieneSomos = props => {

    const sections = useSelector((state) => state.sections.sections);
    const selectedCountry = useSelector((state)=> state.country.selectedCountry);

    return (
        <div className="container-menu-quienes-somos">
            <div className="integrante">
                {props.pageImage && <img src={props.pageImage} alt="" />}
                <h1 className="texto-bordo">INTEGRANTES ASMA ZERO</h1>
                <ul className="sidebar-quienes-somos">
                    {sections.filter((section) => section['parent_id_section'] == '11' && section['status'] == '1' && section[selectedCountry.toLowerCase()] == '1').sort(function (a, b) {
                        return (a['field_order'] - b['field_order'])
                    }).map((section) => (
                        <li>
                            <Link
                                className={props.matchSection == section['slug'] ? 'nav-link-selected' : 'nav-link'}
                                aria-current="page"
                                onClick={props.closeSidebar}
                                title={section['name']}
                                to={`/quienes-somos/${section['slug']}`}
                            >
                                {section['name']}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="info-cel">
                {props.celImage && (
                    <div className="cel-button">
                        <img className="cel-image" src={props.celImage}></img>
                        <a href={props.href} target="_blank">
                            <button className="btn mas-info">MÁS INFO</button>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MenuQuieneSomos;
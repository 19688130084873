import React from 'react'

export default function IconoEstetoscopio() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <g
                id="Menú-entrenamiento"
                transform="translate(-399.875 -736.272)"
            >
                <path
                    id="Trazado_1309"
                    class="st0"
                    d="M470.6,757.4c0-0.2,0-0.5,0-0.7c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4   c1.5,0,2.9-0.6,4-1.7c7.3,4.9,9.2,14.8,4.3,22.1c-1.1,1.7-2.5,3.1-4.2,4.2l-6.6,4.5c-1.9-1.3-4.3-2-6.6-1.9   c-2.5-0.1-4.9,0.6-6.9,2.1l-6.9-4.7c-7.2-5-9.1-14.9-4.1-22.1c1.1-1.7,2.6-3.1,4.2-4.2c2,2.2,5.4,2.3,7.6,0.3   c2.2-2,2.3-5.4,0.3-7.6c-2-2.2-5.4-2.3-7.6-0.3c-1.1,1-1.7,2.4-1.7,3.9c0,0.2,0,0.5,0,0.7c-8.9,5.7-11.6,17.5-5.9,26.4   c1.4,2.2,3.2,4.1,5.3,5.5l7.2,5v0.1c0,2.5,2.8,4.6,6.8,5v5.7c0.1,7.1-5.6,13-12.7,13.1c-7.1,0.1-13-5.6-13.1-12.7v-8.1   c3.6-0.9,5.8-4.5,4.9-8.1s-4.5-5.8-8.1-4.9s-5.8,4.5-4.9,8.1c0.6,2.4,2.5,4.3,4.9,4.9v7.7c0,8.9,7.2,16.2,16.2,16.2   c8.9,0,16.2-7.2,16.2-16.2c0,0,0,0,0,0l0,0v-5.7c4-0.4,6.8-2.5,6.8-5c0-0.2,0-0.3,0-0.5l6.7-4.6c8.7-6,10.9-17.9,4.9-26.6   C474.6,760.6,472.8,758.7,470.6,757.4L470.6,757.4z M465.2,758.8c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1   c1.2,0,2.1,0.9,2.1,2.1c0,0,0,0,0,0C467.3,757.8,466.4,758.8,465.2,758.8C465.2,758.8,465.2,758.8,465.2,758.8z M461.2,794.4   c0,0.5-1.8,1.9-5.1,1.9s-5.1-1.4-5.1-1.9s1.8-1.9,5.1-1.9S461.2,793.9,461.2,794.4z M444.3,756.7c0-1.2,0.9-2.1,2.1-2.1   s2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1C445.3,758.8,444.3,757.8,444.3,756.7C444.3,756.7,444.3,756.7,444.3,756.7z M430.4,790.9   c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c0,0,0,0,0,0C428.9,787.4,430.4,789,430.4,790.9   C430.4,790.9,430.4,790.9,430.4,790.9L430.4,790.9z"
                ></path>
            </g>
        </svg>
    )
}
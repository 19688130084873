import React from 'react'

// ES Modules
import parse from 'html-react-parser';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from 'd3-ease'
import AnimatedProgressProvider from '../AnimatedProgressProvider'

export default function AnimatedCircleBar(props) {

    return (
        <div className="container-item col-4 col-sm-4 col-md-4">

            <AnimatedProgressProvider
                valueStart={0}
                valueEnd={props.item['procentaje']}
                duration={1.4}
                easingFunction={easeQuadInOut}
            >
                {value => {
                    const roundedValue = Math.round(value);

                    let pathColor;
                    let svgColorClass;
                    if (props.item['procentaje'] <= 25) {
                        pathColor = '#10144b'
                        svgColorClass = 'blue'
                    } else if (props.item['procentaje'] >= 26) {
                        pathColor = '#840729'
                        svgColorClass = 'red'
                    }

                    return (
                        <CircularProgressbarWithChildren
                            value={value}
                            // text={`${roundedValue}%`}
                            /* This is important to include, because if you're fully managing the
                      animation yourself, you'll want to disable the CSS animation. */
                            styles={buildStyles({
                                pathTransition: "none",
                                rotation: 0.5,
                                // textColor: pathColor,
                                pathColor: pathColor,
                                // trailColor: '#f4f1ef'
                            })}
                        >
                            <div className={`container-svg ${svgColorClass}`} >
                                {parse(props.item['icon'])}
                            </div>
                            <div className="container-value" style={{ fontSize: 34, color: pathColor }}>
                                {roundedValue}%
                                </div>
                        </CircularProgressbarWithChildren>
                    );
                }}
            </AnimatedProgressProvider>

            <p>{props.item['name']}</p>
        </div>
    )

}

import React from 'react';
import {API_MATERIAL} from "../store/constants";
import CancionesSpotify from "./CancionesSpotify";

function RecursosEducacion({resources, section, pais}) {
    return (
        <div>
            {resources && section === 'recursos-pacientes' ? (
                <div className="row recurso-container">
                    {resources
                        .filter(
                            (recurso) =>
                                recurso.need_logged === "0" && recurso.status === "1" && recurso[pais] === "1" && recurso['id_note_resource_type'] != "4",
                        )
                        .map((recurso) => (
                            <div
                                key={recurso.id_note_resource}
                                className="recurso col-12 col-xs-6 col-sm-6 col-md-4">
                                <img
                                    className="imagen-recurso"
                                    src={`${API_MATERIAL}/notes_resources/small/${recurso.photo}`}
                                    alt={recurso['detail']}
                                />
                                {recurso.url_canonical ? (
                                    <div>
                                        {recurso.url_canonical.length > 0 ? (
                                            <a target="_blank" href={recurso.url_canonical}>
                                                <h1 className="title">{recurso.name}</h1>
                                            </a>
                                        ) : (
                                            <a
                                                download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                                target="_blank"
                                                href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}>
                                                <h1 className="title">{recurso.name}</h1>
                                            </a>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <a
                                            download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                            target="_blank"
                                            href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}>
                                            <h1 className="title">{recurso.name}</h1>
                                        </a>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            ) : (
                <div className="row recurso-container">
                    {resources
                        .filter(
                            (recurso) =>
                                recurso.need_logged === "0" && recurso.status === "1" && recurso[pais] === "1" && recurso['id_note_resource_type'] == "4",
                        )
                        .map((recurso) => (
                            <div
                                key={recurso.id_note_resource}
                                className="recurso col-12">
                                {recurso.url_canonical ? (
                                    <div>
                                        {recurso.url_canonical.length > 0 ? (
                                            <a target="_blank" href={recurso.url_canonical}>
                                                <img
                                                    className="imagen-recurso img-fluid w-100"
                                                    src={`${API_MATERIAL}/notes_resources/media/${recurso.photo}`}
                                                    alt={recurso['detail']}
                                                />
                                                <p className="title mx-0">{recurso.name}</p>
                                            </a>
                                        ) : (
                                            <a
                                                download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                                target="_blank"
                                                href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}>
                                                <img
                                                    className="imagen-recurso img-fluid w-100"
                                                    src={`${API_MATERIAL}/notes_resources/media/${recurso.photo}`}
                                                    alt={recurso['detail']}
                                                />
                                                <p className="title">{recurso.name}</p>
                                            </a>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <a
                                            download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                            target="_blank"
                                            href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}>
                                            <img
                                                className="imagen-recurso img-fluid w-100"
                                                src={`${API_MATERIAL}/notes_resources/media/${recurso.photo}`}
                                                alt={recurso['detail']}
                                            />
                                            <p className="title mx-0">{recurso.name}</p>
                                        </a>
                                    </div>
                                )}
                            </div>
                        ))}
                    <CancionesSpotify />
                    <small style={{ fontSize: "12px" }}>MATPROM CO-8960 Prep 11-2022 <br/>
                        Si requiere mayor información favor comunicarse con el teléfono abajo. AstraZeneca Colombia SAS, Edificio NAOS, Avenida Carrera 9 No. 101-67, Oficina 601, Bogotá, D.C., tel.: 3257200. AstraZeneca Perú SA, Calle Las Orquídeas 675, Interior 802, San Isidro, Lima-Perú, tel.: 6101515. Productos propiedad de AstraZeneca y distribuidos por Dyvenpro Distribución y Venta de Productos S.A. Oficina Matriz: Urbanización Ciudad Colón, Mz 275, Solar 5, Etapa III, Edificio Corporativo 1, Guayaquil-Ecuador, tel.: 43731390. Oficina Regional: Avenida 6 de Diciembre N31-110 y Whymper, Edificio Torres Tenerife, Piso 7. Información aplicable a Colombia, Perú y Ecuador. La información que contiene este material es únicamente de caracter informativo, no pretende influenciar la prescripción o consumo de ningún medicamento y no reemplaza la consulta a un profesional de la salud para el diagnóstico o tratamiento de ningún síntoma o enfermedad.</small>
                </div>
            )}
        </div>
    );
}

export default RecursosEducacion;
import React from "react";
import { connect } from "react-redux";
import Home from "../components/Home";
import { fetchEvents } from "../store/actions/events";
import store from "../store";
import Axios from "axios";

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svgClass: "white",
      headerClass: "header-main",
      buttonTogglerId: "top-off"
    };

    this.handleNavbar = this.handleNavbar.bind(this);
  }

  componentDidMount() {
    window.onscroll = () => this.handleNavbar();
  }

  handleNavbar() {
    if (document.documentElement.scrollTop > 50 && window.screen.width > 768) {
      this.setState({
        headerClass: "header-fixed-main",
        buttonTogglerId: "top-on"
      });
    } else {
      this.setState({
        headerClass: "header-main",
        buttonTogglerId: "top-off"
      });
    }
    if (
      (document.documentElement.scrollTop > 300 &&
        document.documentElement.scrollTop < 2200) ||
      (document.documentElement.scrollTop >= 2850 &&
        document.documentElement.scrollTop < 3800)
    ) {
      this.setState({
        svgClass: "blue"
      });
    } else {
      this.setState({
        svgClass: "white"
      });
    }
  }

  render() {
    return (
      <Home
        headerClass={this.state.headerClass}
        buttonTogglerId={this.state.buttonTogglerId}
        handleNavbar={this.handleNavbar}
        svgClass={this.state.svgClass}
      />
    );
  }
}


export default connect(null, null)(HomeContainer);

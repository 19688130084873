import React from "react";
import { useSelector } from "react-redux";
import { API_MATERIAL } from '../store/constants'
import FooterDefault from './FooterDefault'
import FooterColombia from './FooterColombia'
import FooterEcuador from './FooterEcuador'
import FooterPeru from './FooterPeru';



export default function Footer(props) {
  const user = useSelector((state) => state.user.authData);
  const selectedCountry = useSelector((state) => state.country.selectedCountry)

  return (
    <footer style={{marginTop:`${props.marginTop && (window.screen.width < 768) ? '-15rem' :''}`}}>
      <section className={props.fondoNegro ? "fondo-negro" : props.fondoAzul ? "fondo-azul" : "fondo-azul"}>
        <div className="container footer-top">
          {" "}
          {
            selectedCountry === "CO" ?
              <FooterColombia/>
            :
            <>
              {
               selectedCountry === "PE" ?
                <FooterPeru/>
                :
                <>
                  {
                    selectedCountry === "EC" ?
                      <FooterEcuador/>
                    :
                      <FooterDefault/>
                  }
                </>
              }
            </>
          }
        </div>
      </section>
      <section className="footer-bottom">
        <div className="container">
          <div >

            <ul className="footer-logos">
              <li className="logo">
                <a href="https://www.astrazeneca.co" target="_blank">
                  <img src="/img/logo-aztrazeneca.png" alt={"logo astrazeneca"} />
                </a>
              </li>
            </ul>

          </div>
          <div className="text-center">
            <p>La información que contiene este material es únicamente de carácter informativo, no pretende influenciar la prescripción
              o consumo de ningún medicamento y no reemplaza la consulta a un profesional de la salud
              para el diagnóstico o tratamiento de ningún síntoma o enfermedad
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
}

import React from 'react'
import BarraRedesFooter from './BarraRedesFooter'
import {Link} from "react-router-dom";


const FooterEcuador = () => {
    return(
        <>
            <div className="row" style={{justifyContent:'space-evenly'}}>
                <div className="col-md-4">
                    <h5 style={{marginTop:10,display:'flex',justifyContent:'flex-start',width:'60%'}}> Síguenos <BarraRedesFooter/></h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2">MATPROM CO-9126</div>
                    <div className="mb-4">Prep: 12-2022</div>
                    <div className="mb-2">Si requiere mayor información favor comunicarse con el teléfono abajo. Productos propiedad de AstraZeneca y distribuidos por Dyvenpro Distribución y
                        Venta de Productos S.A. Oficina Matriz: Urbanización Ciudad Colón, Mz 275,
                        Solar 5, Etapa III, Edificio Corporativo 1, Guayaquil-Ecuador, tel.: 43731390.
                        Oficina Regional: Avenida 6 de Diciembre N31-110 y Whymper, Edificio Torres Tenerife, Piso 7.</div>
                    <div className="mb-2">Información aplicable a Ecuador</div>
                </div>

                <div className="col-md-4">
                    <h5 style={{marginTop:10}}>Información adicional</h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2"><a href="https://www.azmedical.ec/bienvenida/notificacion-legal-y-terminos-de-uso.html" target="_blank">Aviso Legal</a></div>
                    <div className="mb-2"><a href="https://www.globalprivacy.astrazeneca.com/es" target="_blank">Política de Privacidad Ecuador</a></div>
                    <div className="mb-2"><a href="https://policy.cookiereports.com/0f359fc3-es.html" target="_blank">Política de Cookies</a></div>
                    <div className="mb-2"><a href="https://www.azmedical.ec/bienvenida/PoliticadeprivacidadPeru.html" target="_blank">Cláusula de Privacidad de Eventos Adversos Ecuador</a></div>
                    <div className="mb-2"><a href="https://asmazero-citas-especialista.azurewebsites.net/" target="_blank">Agendar una cita</a></div>
                    {/* <div className="mb-2"><Link to={"/contacto"}>Contacto</Link></div> */}
                </div>

                <div className="col-md-4">
                    <h5 style={{marginTop:10}}>Acciones</h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2" style={{lineHeight:'1.5rem'}}>
                        En caso de evento adverso comuníquese a través del correo electrónico <a href={"mailto:farmacovigilancia.dyvenpro@grupodifare.com"}>farmacovigilancia.dyvenpro@grupodifare.com</a>, el móvil 24 horas (593)990093705, o el link <a
                        href={"https://aereporting.astrazeneca.com"} target={"_blank"} rel={"noreferrer"} referrerPolicy={"no-referrer"}>https://aereporting.astrazeneca.com</a> seleccionando el país. Link de Data Privacy para reporte de eventos adversos: <a
                        href={"https://bit.ly/2ZHT6W3"} target={"_blank"} rel={"noreferrer"} referrerPolicy={"no-referrer"}>https://bit.ly/2ZHT6W3</a>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default FooterEcuador;
import React from 'react';
import { Link } from "react-router-dom";

const FooterDefault = () =>{
    return(
        <div className="row">
            <div className="col-md-2 text-start">
              <p>
                MATPROM CO-4586
                Prep: 08-2020
                AstraZeneca Colombia SAS,
                Cra 7 # 71-21, Torre A, Piso 19,
                tel.: 3257200
              </p>
            </div>
            <div className="col-md-6 lh-lg">
              <ul style={{listStyle:'none'}}>
                <li>
                  <p>Información aplicable a Colombia</p>
                </li>
                <li>
                  <p>Copyright de las imágenes</p>
                </li>
                <li>
                  <a target="_blank" href="https://www.astrazeneca.co/politica-de-privacidad-colombia.html">
                    Política de privacidad general por país
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.astrazeneca.co/clausula-de-privacidad-de-eventos-adversos-colombia.html">
                    Cláusula de Privacidad de Eventos Adversos
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul style={{listStyle:'none'}}>
                <li>
                  <p>En caso de evento adverso repórtelo en Colombia a la línea 018000111561 o el link <a href="https://aereporting.astrazeneca.com/" target="_blank">https://aereporting.astrazeneca.com/</a> seleccionando el país.</p>  
                </li>
                <li>
                  <a href="https://www.astrazeneca.co/aviso-legal.html" target="_blank">
                    Aviso legal
                  </a>
                </li>
                <li>
                  <a href="https://www.astrazeneca.co/cookies.html" target="_blank">
                    Cookies
                  </a>
                </li>
                <li>
                  <Link to={"/contacto"}>Contacto</Link>
                </li>
              </ul>
            </div>
        </div>
    )
}

export default FooterDefault;
import React from "react";
import { connect } from "react-redux";
import PlanDeAccion from "../components/PlanDeAccion";
import { recursosJSON } from "../json";

class PlanDeAccionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }



  render() {
    let recursosFilter = function (array) {
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].planDeAccion) {
          arr.push(array[i]);
        }
      }
      return arr;
    };

    let recursos = recursosFilter(recursosJSON);

    return (
      <PlanDeAccion
        recursos={recursos}
        history={this.props.history}
        props={this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, null)(PlanDeAccionContainer);

import React from 'react'
import BarraRedesFooter from './BarraRedesFooter'
import {Link} from "react-router-dom";


const FooterPeru = () => {
    return(
        <>
            <div className="row" style={{justifyContent:'space-evenly'}}>
                <div className="col-md-4">
                    <h5 style={{marginTop:10,display:'flex',justifyContent:'flex-start',width:'60%'}}> Síguenos <BarraRedesFooter/></h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2" >MATPROM CO-9126</div>
                    <div className="mb-4">Prep: 12-2022</div>
                    <div className="mb-2">Si requiere mayor información favor comunicarse con el teléfono abajo. AstraZeneca Perú SA, Calle Las Orquídeas 675, Interior 802, San Isidro, Lima-Perú, tel.: 6101515.</div>
                    <div className="mb-2">Información aplicable a Perú</div>
                </div>

                <div className="col-md-4">
                    <h5 style={{marginTop:10}}>Información adicional</h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2"><a href="https://www.azmedical.pe/bienvenida/notificacion-legal-y-terminos-de-uso.html" target="_blank">Aviso Legal</a></div>
                    <div className="mb-2"><a href="https://www.azmedical.pe/bienvenida/aviso-de-privacidad.html" target="_blank">Cláusula de Privacidad de Eventos Adversos Perú</a></div>
                    <div className="mb-2"><a href="https://www.globalprivacy.astrazeneca.com/es" target="_blank">Política de Privacidad Perú</a></div>
                    <div className="mb-2"><a href="https://policy.cookiereports.com/9cdc319f-es.html" target="_blank">Política de Cookies</a></div>
                    <div className="mb-2"><a href="https://asmazero-citas-especialista.azurewebsites.net/" target="_blank">Agendar una cita</a></div>
                    {/* <div className="mb-2"><Link to={"/contacto"}>Contacto</Link></div> */}
                </div>

                <div className="col-md-4">
                    <h5 style={{marginTop:10}}>Acciones</h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2" style={{lineHeight:'1.5rem'}}>
                        En caso de evento adverso ingrese a <a href={"https://aereporting.astrazeneca.com"} target={"_blank"} rel={"noreferrer"} referrerPolicy={"no-referrer"}>https://aereporting.astrazeneca.com</a> y seleccione en país Perú, o escriba al correo electrónico <a
                        href={"mailto:PatientSafety.Peru@astrazeneca.com"}>PatientSafety.Peru@astrazeneca.com</a>. Link de Data Privacy para reporte de eventos adversos: <a
                        href={"https://bit.ly/35p2CRf"} target={"_blank"} rel={"noreferrer"} referrerPolicy={"no-referrer"}>https://bit.ly/35p2CRf</a>.
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default FooterPeru;
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AUTH_URL, AUTH_URL_REGISTER } from '../store/constants';
Amplify.configure(awsconfig);

export default function ModalCitaMedica(props) {
  const urlBack = useSelector((state) => state.user.urlBack);

  let [isLogin, setIsLogin] = useState(false);
  
  // console.log('ModalCitaMedica - ', terminosUso, terminosUsoAZ);

  

 

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="ingresar-modal">
        <img src="/img/telefono.svg" alt="" />
        <div className="container">
          
          {props.ingresar ? (
            <h1 class="text-center">
             ¿Necesitas agendar una cita<br></br>con un médico especialista?
            </h1>
          ) : (
              <h1 class="text-center">
               Contenido exclusivo para <br></br> Profesionales de la Salud.
              </h1>
            )}
          

          <div className="container-button text-center mt-4 mb-4">
            <a 
              href="https://asmazero-citas-especialista.azurewebsites.net/"
              target='_blank'
              class="btn-agendar-cita">
              ¡AGÉNDALA ACÁ!
            </a>
          </div>
          <p class="text-xxs text-center">
          Si requiere mayor información favor comunicarse con el teléfono abajo AstraZeneca Colombia S.A.S Edificio NAOS, 
          Avenida Carrera 9 No. 101-67, Oficina 601, Bogotá, D.C. tel.: 60 1 3257200. Información aplicable para Colombia. 
          La información que contiene este material es únicamente de carácter informativo, no pretende influenciar la prescripción 
          o consumo de ningún medicamento y no reemplaza la consulta a un profesional de la salud para el diagnóstico o tratamiento 
          de ningún síntoma o enfermedad.
          </p>
          <p class="text-xs text-matprom text-center">MATPROM CO-9739 Prep 03.2022</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { API_MATERIAL } from '../store/constants'
import { useSelector, shallowEqual } from 'react-redux';


const Recursos = (props) => {
  // console.log(props.resources);

  const userState = useSelector((state) => state.user.authState, shallowEqual);

  const filterResources = (originalArray, prop, userState) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      if (userState) {
        if (lookupObject[i]["name"] != "test") {
          newArray.push(lookupObject[i]);
        }
      } else {
        if (lookupObject[i]["name"] != "test" && lookupObject[i]["need_logged"] == "0") {
          newArray.push(lookupObject[i]);
        }
      }
    }
    return newArray;
  };

  return (
    <div>
      <div className="recursos-title">
        {/* <Link
          style={{
            color: "#840729",
          }}
          to={props.slug}
        > */}
        <h4>RECURSOS RELACIONADOS</h4>
        {/* </Link> */}
      </div>
      <div className="recursos-relacionados">
        {filterResources(props.resources, "id_note_resource", userState).map((recurso) => (
          <div className="recurso">
            {recurso.url_canonical.length > 0 ? (
              <a
                style={{
                  color: "#10144b",
                }}
                href={recurso.url_canonical}
                target="_blank"
              >
                <div className="link-recursos">
                  <img className="imagen-recurso" src="/img/icono-link.png" alt="" />
                  <h1>{recurso.name}</h1>
                </div>
              </a>
            ) : (
                <a
                  style={{
                    color: "#10144b",
                  }}
                  href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                  download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                  target="_blank"
                >
                  <div className="link-recursos">
                    <img className="imagen-recurso" src="/img/icono-descarga.png" alt="" />
                    <h1>{recurso.name}</h1>
                  </div>
                </a>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recursos;

/*
<div>
              <div className="recurso">
                <a
                  style={{
                    color: "#10144b",
                  }}
                  download="Que-es-el-asma-flyer"
                  href="/pdfs/que_es_el_asma.pdf"
                >
                  <div className="link-recursos">
                    <img src="/img/icono-descarga.png" alt="" />
                    <h1>Qué es el asma - Flyer</h1>
                  </div>
                </a>
              </div>
              <div className="recurso">
                <a
                  style={{
                    color: "#10144b",
                  }}
                  download="Plan-de-accion"
                  href="/pdfs/plan_de_accion.pdf"
                  target="_blank"
                >
                  <div className="link-recursos">
                    <img src="/img/icono-descarga.png" alt="" />
                    <h1>Plan de Acción</h1>
                  </div>
                </a>
              </div>
              <div className="recurso">
                <Link
                  style={{
                    color: "#10144b",
                  }}
                  to="/mapa"
                >
                  <div className="link-recursos">
                    <img src="/img/icono-link.png" alt="" />
                    <h1>Mapa Asma Zero</h1>
                  </div>
                </Link>
              </div>
            </div>*/

import { FETCH_SLIDERS } from '../constants'

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SLIDERS:
            return Object.assign({}, state, {
                sliders: action.sliders
            });
        default:
            return state;
    }
}
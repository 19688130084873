import {
  FETCH_PEOPLE,
  FETCH_EXPERTS
} from "../constants";

const initialState = {
  people: [],
  experts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PEOPLE:
      return Object.assign({}, state, {
        people: action.people
      });
    case FETCH_EXPERTS:
      return Object.assign({}, state, {
        experts: action.experts
      });
    default:
      return state;
  }
};
import React from "react";
import { connect } from "react-redux";
import DaleVozIframe from "../components/DaleVozIframe"

class DaleVozContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <DaleVozIframe
          props={this.props}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(null, null)(DaleVozContainer);

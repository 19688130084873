import React from 'react';

const EmbeddedSite = () => {
  return (
    <div>
      <iframe
        title="Embedded Site"
        src="/dalevozatuspulmones/index.html"
        width="100%"
        height="900px"
        frameBorder="0"
      />
    </div>
  );
};

export default EmbeddedSite;

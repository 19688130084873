import { FETCH_COUNTRY, SET_SELECTED_COUNTRY } from '../constants'

const initialState = {
  selectedCountry: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRY:
      return {...action.country, selectedCountry:state.selectedCountry}
    case SET_SELECTED_COUNTRY:
      return {...state, selectedCountry:action.selected}
    default:
      return state;
  }
};

import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { closeSidebar, toggleMenuAsmaE } from '../store/actions'



export default function SubMenuAsmaE(props) {

  const subMenuAsmaEClass = useSelector((state) => state.menu.subMenuAsmaE, shallowEqual)
  const subMenuDiarioClass = useSelector((state) => state.menu.subMenuDiario, shallowEqual)
  const dispatch = useDispatch();

  const handleCloseSidebar = () => {
    dispatch(closeSidebar())
  }

  const handleToggleMenu = () => {
    dispatch(toggleMenuAsmaE(subMenuAsmaEClass))
  }

  return (
    <nav className={subMenuAsmaEClass} id="navigation">
      {props.sections.length && (
        <div className="menu-principal-container">
          <div className="sub-menu-container-asma-e">
            <h1 className="sub-menu-title">CONOCER MÁS SOBRE ASMA-E</h1>
            <div className="sub-menu-ss2020-container">
              <ul id="menu-ss2020" className="sub-menu-slider-right">
                <li className="sub-menu-item">
                    <a
                    onClick={handleCloseSidebar}
                    href="https://www.asmae.co/home/what-is-easthma.html"
                    target="_blank"
                    className="nav-link"
                    >
                    ¿Qué es asma-e?
                    </a>
                </li>
                <li className="sub-menu-item">
                    <a
                    onClick={handleCloseSidebar}
                    href="https://www.asmae.co/home/how-do-i-know-if-i-have-easthma.html"
                    target="_blank"
                    className="nav-link"
                    >
                    ¿Quién tiene asma-e?
                    </a>
                </li>
                <li className="sub-menu-item">
                    <a
                    onClick={handleCloseSidebar}
                    href="https://www.asmae.co/home/what-treatments-are-right-for-easthma.html"
                    target="_blank"
                    className="nav-link"
                    >
                    ¿Cómo es el manejo del asma-e?
                    </a>
                </li>
                <li className="sub-menu-item">
                    <a
                    onClick={handleCloseSidebar}
                    href="https://www.asmae.co/home/lungprint.html"
                    target="_blank"
                    className="nav-link"
                    >
                    Crear mi Huella Pulmonar
                    </a>
                </li>
              </ul>
            </div>
            <div
              onClick={handleToggleMenu}
              className="swiper-button-prev asma-e swiper-button-white"
            >
              <h2
                onClick={handleToggleMenu}
                className="sub-menu-back-button-asma-e"
              >
                Volver
              </h2>
            </div>
            <div className="menu-redes">
              {" "}
              <a
                href="https://www.instagram.com/otgwesteurope/"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>{" "}
              <a
                href="https://www.facebook.com/OTG.West.Europe/"
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>{" "}
              <a
                href="https://www.youtube.com/user/OTGWestEurope"
                target="_blank"
              >
                <i className="fab fa-youtube"></i>
              </a>{" "}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import { API_MATERIAL, URL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser';
import Menu from './Menu';
import Button from './ButtonMenu';
import { tracking } from '../store/actions'
import { useSelector, shallowEqual } from 'react-redux';
import Helmet from "react-helmet";
import ReactGa from 'react-ga'
import MenuQuieneSomos from './MenuQuienesSomos'



export default function CirculoAsmaZero(props) {

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  // VARIABLES DE CONTENIDO
  const sections = useSelector((state) => state.sections.sections.filter((section) => section['parent_id_section'] == '11' && section['status'] == '1', shallowEqual));
  const people = useSelector((state) => state.people.people)
  const sectionMatch = sections.filter((section) => section['slug'] == props.match)[0]
  const peopleMatch = people.filter((p) => p['id_section'] == sectionMatch['id_section'])
  const expertsArray = useSelector((state) => state.people.experts, shallowEqual);

  // VARIABLES DE USUARIO
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);


  useEffect(() => {
    if (userState) {
      tracking(user['user_id'], sectionMatch['id_section'], '', user['name'], window.location.href)
    }
    ReactGa.pageview(`/quienes-somos/${props.match}`)

  }, [props.match])


  return (
    <div className={internaClass}>
      {sectionMatch && (
        <Helmet>
          <title>Quiénes somos - {sectionMatch['name']}</title>
          <meta name="description" content="Quiénes somos - asmazero" />
        </Helmet>
      )}
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            <Link style={{ textDecoration: 'none', color: '#35aea9' }} to="/">
              ASMA ZERO /{' '}
            </Link>{' '}
            <Link
              style={{ textDecoration: 'none', color: '#35aea9' }}
              to="/quienes-somos/circulo-asma-zero">
              QUIENES SOMOS
            </Link>{' '}
          </p>
          {sectionMatch && (
            <h1 className="title text-center">{sectionMatch['name']}</h1>
          )}
          {props.match == "expertos-asma-zero" && expertsArray && (
            <h4 className="expertos-header" >Los siguientes expertos participan del programa Asma Zero liderando el desarrollo de contenido para la WEB y APP</h4>
          )}
        </div>
      </main>

      <section className="container quienes-somos-home">

        {/* PERFIL DEL PRESIDENTE DE ASRTAZENECA */}
        {peopleMatch.length == 1 && (
          <div>
            <div className="fondo-verde">
              <div className="presidente-perfil">
                <div className="img-title">
                  <img
                    src={`${API_MATERIAL}/sections_peoples/big/${peopleMatch[0].file}`}
                    alt=""
                    className="imagen-perfil-nota"
                  />
                  <p className="texto-blanco">
                    {peopleMatch[0]['letter_title']}
                  </p>
                  <h1 className="texto-blanco">{peopleMatch[0].name}</h1>
                  <img
                    className="subrayado"
                    src="/img/linea-azul-qs.png"
                    alt=""
                  />
                  {peopleMatch[0].email && (
                    <a href={`mailto: ${peopleMatch[0].email}`}>
                      <img
                        className="icono-mensaje"
                        src="/img/icono-mensaje.png"
                        alt=""
                      />
                    </a>
                  )}
                </div>
                <div className="texto-presidente text-column-1">
                  {ReactHtmlParser(peopleMatch[0]['letter_column_1'])}
                </div>
                <div className="texto-presidente text-column-2">
                  {ReactHtmlParser(peopleMatch[0]['letter_column_2'])}
                  <img
                    className="firma"
                    src={`${API_MATERIAL}/sections_peoples/big/${peopleMatch[0]['signature']}`}
                    alt=""
                  />
                  <p className="texto-blanco">
                    {peopleMatch[0]['signature_clarification']}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}


        <div className="container-quienes-somos row">
          <div className="container-people col-md-9">


            {/* SECCIÓN CIRCULO ASMAZERO O CIRCULO ASMAZERO KIDS */}
            {peopleMatch.length > 1 && (
              <div className="row">
                {peopleMatch.map((people) => (
                  <div className="people col-md-4">
                    <img
                      className="imagen-perfil-nota"
                      src={`${API_MATERIAL}/sections_peoples/big/${people['file']}`}
                      alt=""
                    />
                    <h1>{people.name}</h1>
                    <p className="detalle">{ReactHtmlParser(people.detail)}</p>
                    <div className="hobbie">
                      <img src="/img/icono-corazon.png" alt="" />
                      &nbsp;
                      <p className="texto-verde">{people.hobbie}</p>
                    </div>
                    {people.email && (
                      <div className="iconos-redes">
                        <a href={`mailto: ${people.email}`}>
                          <img src="/img/icono-mensaje.png" alt="" />
                        </a>
                        {/* <img src="/img/icono-linkedin.png" alt="" /> */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* SECCIÓN DE EXPERTOS */}
            {props.match == "expertos-asma-zero" && expertsArray && (
              <div className="row">
                {expertsArray.map((expert) => (
                  <div className="people col-md-4">

                    {expert.file ? (
                      <img
                        className="imagen-perfil-nota"
                        src={`${API_MATERIAL}/notes_authors/big/${expert['file']}`}
                        alt=""
                      />
                    ) : (
                        <img
                          className="imagen-perfil-nota"
                          src="/img/user-doctor.png"
                          alt=""
                        />
                      )}
                    <h1>{expert.name}</h1>
                    <p className="detalle">{ReactHtmlParser(expert.detail)}</p>

                    {expert.email && (
                      <div className="iconos-redes">
                        <a href={`mailto: ${expert.email}`}>
                          <img src="/img/icono-mensaje.png" alt="" />
                        </a>
                        {/* <img src="/img/icono-linkedin.png" alt="" /> */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* SECCIÓN ASTRAZENECA */}
            {peopleMatch.length == 1 && (
              <div className="presidente-content">
                <div className="content">
                  {ReactHtmlParser(sectionMatch['detail'])}
                </div>
              </div>
            )}


          </div>
          <div className="col-md-3">
            <MenuQuieneSomos
              pageImage={props.pageImage}
              matchSection={props.match}
              celImage={props.celImage}
              href={props.href}
            />
          </div>
        </div>
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

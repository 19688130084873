import React, { useEffect } from "react";
import ShareButton from "./ShareButtonComponent";
import ButtonCalendarComponent from './ButtonCalendar'
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { tracking } from '../store/actions/user'
import { URL } from '../store/constants'
import ReactGa from 'react-ga'


const Eventos = (props) => {

  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const selectedCountry = useSelector((state)=> state.country.selectedCountry);

  useEffect(() => {
    if (userState && !props.tracking) {
      tracking(user['user_id'], '15', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/eventos")

  }, [])

  return (
    <section id="home-eventos" className="fondo-azul">
      <div className="container">
        <header>
          {props.masEventos && (
            <div className="title">
              <Link to="/eventos" style={{ textDecoration: "none" }} >
                <h2 className="texto-blanco">Eventos Asma Zero</h2>{" "}
              </Link>
              <img
                className="icono-calendario-title"
                src="img/icono-calendario.png"
              ></img>
            </div>
          )}
          {props.masEventos && (
            <div className="button-eventos">
              <Link to="/eventos">
                <button className="eventos-mas">+ EVENTOS</button>
              </Link>
            </div>
          )}
        </header>
        <div className="eventos-container">
          {props.eventos.length > 0 ? (
            <div className="row">
              {props.eventos.filter(evento=>evento[selectedCountry.toLowerCase()] == '1').map((evento) => (
                <div className="evento col-md-6">
                  <Link
                    style={{
                      color: "white",
                    }}
                    to={`/eventos/${evento["id_event"]}/${evento["slug"]}`}
                  >
                    <h3>{evento["name"]}</h3>
                  </Link>
                  <div className="date">
                    {" "}
                    <span className="fecha texto-blanco">
                      {evento["days"]}
                    </span>{" "}
                    <span className="hora texto-verde">{evento["schedule"]}</span>{" "}
                  </div>
                  <h4 className="autores texto-verde">{evento["place"]}</h4>
                  <div className="calendar-button">
                    <ButtonCalendarComponent
                      title={evento['name']}
                      detail={evento["detail"]}
                      place={evento['place']}
                      start={evento['modified']}
                    />
                  </div>

                  <div className="compartir">
                    {evento['need_logged'] == '1' ? (
                      <ShareButton
                        id="button-compartir"
                        url={`${URL}eventos/${evento["id_event"]}/${evento["slug"]}`}
                        quote={evento["name"]}
                        email={true}
                      // body={ReactHtmlParser(evento["detail"])}
                      />
                    ) : (
                        <ShareButton
                          id="button-compartir"
                          url={`${URL}eventos/${evento["id_event"]}/${evento["slug"]}`}
                          body={ReactHtmlParser(evento["detail"])}
                          quote={evento["name"]}
                          email={true}
                          facebook={true}
                          twitter={true}
                          whatsapp={true}
                        />
                      )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
              <h1 className="texto-blanco no-events">
                Aún no hay Eventos programados
              </h1>
            )}
        </div>
      </div>{" "}
    </section>
  );
};

export default Eventos;

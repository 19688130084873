import React from 'react'

export default function IconoEspirometria() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <g
                id="Grupo_1"
                transform="translate(-675.653 -736.52)"
            >
                <path
                    id="Trazado_4"
                    class="st0"
                    d="M754.6,759.6c-0.2-0.3-0.6-0.5-1-0.5h-8.9v-1.3c0-2.1-1.7-3.8-3.8-3.8h-40.6   c-2.1,0-3.8,1.7-3.8,3.8v58.7c0,1.4,1.1,2.5,2.5,2.5h43.2c1.4,0,2.5-1.1,2.5-2.5v-2.5c3.5,0,6.3-2.8,6.3-6.3v-36.8l3.7-9.9   C754.9,760.4,754.9,760,754.6,759.6z M742.2,816.5H699v-2.5h43.2V816.5z M712.7,789.1c-1.8,0-3.5,0.7-4.9,1.8v-29.3h9.7v29.3   C716.3,789.7,714.5,789.1,712.7,789.1z M717.6,796.4c0,2.7-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h0   C715.4,791.6,717.6,793.8,717.6,796.4L717.6,796.4z M712.7,803.8c1.8,0,3.5-0.7,4.9-1.8v4.4h-9.7v-4.4   C709.2,803.1,711,803.8,712.7,803.8z M717.6,808.9v2.5h-9.7v-2.5L717.6,808.9z M725,772.2c-1.8,0-3.5,0.7-4.9,1.8v-12.4h9.7v12.4   C728.5,772.9,726.8,772.2,725,772.2L725,772.2z M729.8,779.6c0,2.7-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h0   C727.7,774.8,729.8,776.9,729.8,779.6L729.8,779.6z M725,786.9c1.8,0,3.5-0.7,4.9-1.8v21.2h-9.7v-21.2   C721.5,786.3,723.2,786.9,725,786.9z M729.9,808.9v2.5h-9.7v-2.5L729.9,808.9z M742.2,811.4h-9.7v-2.5h9.7V811.4z M742.2,806.3   h-9.7v-9.7c2.8,2.5,7,2.5,9.7,0V806.3z M732.5,791.1c0-2.7,2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8c0,2.7-2.2,4.8-4.8,4.8h0   C734.6,796,732.5,793.8,732.5,791.1L732.5,791.1z M742.2,785.6c-2.8-2.5-7-2.5-9.7,0v-24h9.7V785.6z M742.2,759.1h-35.5   c-0.7,0-1.3,0.6-1.3,1.3c0,0,0,0,0,0v51H699v-53.6c0-0.7,0.6-1.3,1.3-1.3c0,0,0,0,0,0h40.6c0.7,0,1.3,0.6,1.3,1.3c0,0,0,0,0,0   V759.1z M748.5,807.6c0,2.1-1.7,3.8-3.8,3.8v-7.9h3.8L748.5,807.6z M748.5,801h-3.8v-3.8h3.8L748.5,801z M748.5,794.7h-3.8v-3.8   h3.8L748.5,794.7z M748.5,788.3h-3.8v-3.8h3.8L748.5,788.3z M748.5,782h-3.8v-3.8h3.8L748.5,782z M748.5,775.6h-3.8v-3.8h3.8   L748.5,775.6z M748.9,769.3h-4.2v-7.6h7.1L748.9,769.3z"
                ></path>
            </g>
        </svg>
    )
}
import React, { useEffect } from 'react';
import YouTube from 'react-youtube-embed';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import ShareButton from './ShareButtonComponent';
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import { useSelector, shallowEqual } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { API_MATERIAL, URL } from '../store/constants';
import { tracking } from '../store/actions/user';
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'



export default function SingleTestimonio(props) {
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const testimoniosArray = useSelector((state) =>
    state.notes.notes.filter((note) => note.id_section == 25),
    shallowEqual
  );

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  let match = props.props.match.params.name;

  let searchTestimonio = function (array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i]['author']['name'] === match) {
        return array[i];
      }
    }
  };

  let othersTestimonios = function (array) {
    let arr = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i]['author']['name'] !== match) {
        arr.push(array[i]);
      }
    }

    return arr;
  };

  let testimonio = searchTestimonio(testimoniosArray);
  let otrosTestimonios = othersTestimonios(testimoniosArray);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  useEffect(() => {
    if (userState) {
      tracking(
        user['user_id'],
        testimonio['section']['id_section'],
        testimonio['id_note'],
        user['name'],
        window.location.href
      );
    }
    ReactGa.pageview(`/testimonios/${match}`)

  }, [match]);

  return (
    <div id="fondo-gris" className={internaClass}>
      <Helmet>
        <title>Asma Zero - Testimonios - {testimonio['author']['name']}</title>
        <meta name="description" content="Asma Zero - Testimonios" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="fondo-gris margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            <Link style={{ textDecoration: 'none', color: '#35aea9' }} to="/">
              ASMA ZERO{' '}
            </Link>{' '}
            /{' '}
            <Link
              style={{ textDecoration: 'none', color: '#35aea9' }}
              to="/testimonios">
              TESTIMONIOS{' '}
            </Link>
          </p>
          <h1 className="text-center">Testimonios</h1>
          <p className="a-pulmon texto-azul">
            A pulmón — “Historias reales en 60 segundos”
          </p>
        </div>
      </main>

      <section className="container fondo-gris single-testimonio">
        {testimoniosArray.length > 0 && (
          <div className="single-testimonio-container row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-6">
                  <div className="images">
                    <img
                      src="/img/testimonios-circle.png"
                      className="testimonios-circle"></img>
                    <img
                      className="primer-imagen"
                      src={`${API_MATERIAL}/notes_authors/big/${testimonio['author']['file']}`}
                      alt=""
                    />
                    <img
                      className="segunda-imagen"
                      src="/img/fondo-testimonios.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="container-text col-md-6">
                  <h1 className="texto-azul title">
                    {testimonio['author']['name']}
                  </h1>
                  <div className="video-testimonio-mobile">
                    <YouTube
                      className="Youtube"
                      aspectRatio="16:9"
                      id={testimonio['video'].slice(82, 93)}
                    />
                  </div>
                  <p className="texto-azul content ">
                    {ReactHtmlParser(testimonio['author']['detail'])}
                  </p>
                  <div className="compartir-testimonio" href="#">
                    <ShareButton
                      facebook={true}
                      twitter={true}
                      email={true}
                      whatsapp={true}
                      quote="A pulmón — “Historias reales en 60 segundos"
                      url={`${URL}testimonios/${testimonio.name}`}
                    />
                  </div>
                  <div className="frase texto-azul">
                    {ReactHtmlParser(testimonio['detail'])}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="video-testimonio col-md-9">
                  <YouTube
                    aspectRatio="16:9"
                    id={testimonio['video'].slice(82, 93)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="otros-testimonios-container">
                <Link
                  style={{
                    color: '#840729',
                  }}
                  to="/testimonios">
                  <h1 className="mas-testimonios texto-bordo">
                    MAS TESTIMONIOS
                  </h1>
                </Link>
                <div className="otros-testimonios">
                  {otrosTestimonios.map((testimonio) => (
                    <div className="testimonios-right">
                      <Link
                        style={{ textDecoration: 'none', color: '#10144b' }}
                        to={`/testimonios/${testimonio['author']['name']}`}>
                        <h1 className="texto-azul">
                          {testimonio['author']['name']}
                        </h1>
                      </Link>
                      <p className="texto-azul">
                        {ReactHtmlParser(testimonio['detail'])}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

import React from 'react';
import BarraRedesFooter from './BarraRedesFooter';
import { Link } from 'react-router-dom';

const FooterColombia = () => {
    return(
        <>
            <div className="row" style={{justifyContent:'space-evenly'}}>
                <div className="col-md-4">
                    <h5 style={{marginTop:10,display:'flex',justifyContent:'flex-start',width:'60%'}}> Síguenos <BarraRedesFooter/></h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2">MATPROM CO-9126</div>
                    <div className="mb-4">Prep: 12-2022</div>
                    <div className="mb-2">Si requiere mayor información favor comunicarse con el teléfono
                        abajo. AstraZeneca Colombia SAS. Edificio NAOS, Avenida Carrera 9
                        No. 101-67, Oficina 601, Bogotá, D.C., Tel.: 3257200.</div>
                    <div className="mb-2">Información aplicable a Colombia</div>
                </div>
                <div className="col-md-4">
                    <h5 style={{marginTop:10}}>Información adicional</h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2"><a href="https://www.azmedical.co/bienvenida/notificacion-legal-y-terminos-de-uso.html" target="_blank">Aviso Legal</a></div>
                    <div className="mb-2"><a href="https://www.astrazeneca.co/politica-de-privacidad-colombia.html" target="_blank">Política de Privacidad Colombia</a></div>
                    <div className="mb-2"><a href="https://www.azmedical.co/inicio/notificacion-sobre-cookies.html" target="_blank">Cookies</a></div>
                    <div className="mb-2"><a href="https://www.azmedical.co/bienvenida/clausula-de-privacidad-de-eventos-adversos.html" target="_blank">Cláusula de Privacidad de Eventos Adversos</a></div>
                    <div className="mb-2"><a href="https://asmazero-citas-especialista.azurewebsites.net/" target="_blank">Agendar una cita</a></div>
                    {/* <div className="mb-2"><Link to={"/contacto"}>Contacto</Link></div> */}
                </div>
                <div className="col-md-4">
                    <h5 style={{marginTop:10}}>Acciones</h5>
                    <hr style={{borderTopColor:'white'}}/>
                    <div className="mb-2" style={{lineHeight:'1.5rem'}}>
                        En caso de evento adverso repórtelo en Colombia a la línea 018000111561 o el link <a href={"https://aereporting.astrazeneca.com"} target={"_blank"} rel={"noreferrer"} referrerPolicy={"no-referrer"}>https://aereporting.astrazeneca.com</a> seleccionando el país. Link de Data Privacy para reporte de eventos adversos: <a
                        href={"https://bit.ly/2ZsHh5J"} target={"_blank"}>https://bit.ly/2ZsHh5J</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterColombia;
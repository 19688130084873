import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NavbarInterna from '../components/NavbarInterna';
import RedesIconos from '../components/IconosRedesInterna';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Button from '../components/ButtonMenu';
import { codigoPacientes } from '../codigos';
import { tracking, receiveValidUser, setUrlBack } from '../store/actions/user'
import { URL, HREF } from '../store/constants'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'
import { readCookie } from '../components/Cookie';


import { vecesAlaño1a3, vecesAlañoMas4, dosisAcumulativa500a2000, dosisAcumulativaMenos500, dosisAcumulativaMas2000, cantAñosCalculator } from '../CalculadoraValues'
import AnimatedCircleBar from '../components/AnimatedCircleBar';

// ES Modules
import parse from 'html-react-parser';



export default function CalculadoraCorticoidesContainer(props) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  const [cantVecesAñoRespuesta, setCantVecesAñoRespuesta] = useState('')
  const [cantVecesAño, setCantVecesAño] = useState('1 a 3 veces por año')

  const [dosisAcumulativaRespueta, setDosisAcumulativaRespuesta] = useState('')

  const [cantAñosCalculatorRespuesta, setCantAñosCalculatorRespuesta] = useState('')

  const [inputYears, setInputYears] = useState('')


  const handleInputChange = (event) => {

    if (event.target.value > 0 && event.target.value <= 10) {
      setInputYears(event.target.value)
    } else if (event.target.value == 0) {
      setInputYears(1)
    } else if (event.target.value > 10) {
      setInputYears(10)
    }
  }

  const readGet = (o) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  };
  let getCode = readGet('code');

  const validUser = () => {
    let cookieToken = readCookie('azmed_user_token');
    // console.log(cookieToken)
    if (cookieToken == null && getCode == null) {
      dispatch(receiveValidUser(true));
      dispatch(setUrlBack(`${URL}herramientas/medidor-corticoides-sistemicos`));
      props.history.push('/');
    }
  };


  const verConclucionesReactGaEvents = () => {
    console.log('ver resultado react-ga events')
    ReactGa.event({
      category: 'CTA',
      action: 'Ver Resultado',
      label: 'Calculadora Corticoides'
    });
  }


  useEffect(() => {
    validUser();
    if (user) {
      tracking(user['user_id'], '56', '', user['name'], window.location.href);
    }

  }, [])


  return (
    <div className={internaClass}>
      <Helmet>
        <title>Medidor de riesgos asociado a los Corticoides Sistémicos</title>
        <meta name="description" content="Medidor de riesgos asociado a los Corticoides Sistémicos" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            HERRAMIENTAS / MEDIDOR DE RIESGOS ASOCIADO A LOS CORTICOIDES SISTÉMICOS
          </p>
          <h1 className="text-center">Medidor de riesgos asociado a los Corticoides Sistémicos</h1>
        </div>
      </main>

      <section className="medidor-riesgos-container  margin-bottom-7">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <p className="titulo texto-bordo mb-4">
                Responda estas preguntas con su paciente
              </p>
              <img src="/img/frase-linea-bordo.png" className="w-100"></img>
            </div>
            <div className="col-md-12 row container-pregunta-respuesta">
              <div className="col-md-12 container-numero">
                <p className="numero">1.</p>
              </div>
              <div className="col-md-6">
                <div className="pregunta">
                  <div className="container-pregunta-numero">
                    <p className="texto">¿Cuántas veces en el año ha utilizado corticoides sistémicos?</p>
                  </div>
                  <div className="respuesta">
                    <button
                      onClick={() => {
                        setCantVecesAñoRespuesta(vecesAlaño1a3)
                        setCantVecesAño('1 a 3 veces por año')

                      }}
                    >
                      1 a 3 veces por año
                      </button>
                    <button
                      onClick={() => {
                        setCantVecesAñoRespuesta(vecesAlañoMas4)
                        setCantVecesAño('≥ 4 veces por año')
                      }}
                    >
                      ≥ 4 veces por año
                      </button>
                  </div>
                </div>
              </div>
              {cantVecesAñoRespuesta && (
                <div className="container-values col-md-6">
                  <h5>{cantVecesAñoRespuesta['title']}</h5>
                  <div className="row">
                    {cantVecesAñoRespuesta['values'].map(item => (
                      <AnimatedCircleBar
                        item={item}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12 row container-pregunta-respuesta">
              <div className="col-md-12 container-numero">
                <p className="numero">2.</p>
              </div>
              <div className="col-md-6">
                <div className="pregunta">
                  <div className="container-pregunta-numero">
                    <p className="texto">Cantidad de años de uso de corticoides sistémicos</p>
                    <p className="sub-title">
                      Introduzca los años de utilización de corticoides sistémicos independientemente de la cantidad
                                        </p>
                  </div>
                  <div className="cantidad-años-inputs">
                    <label for="cantidad-años">
                      <input
                        // value={inputYears}
                        onChange={handleInputChange}
                        type="number"
                        min="1"
                        max="10"
                      />
                                            (Valores entre 1 a 10 años)
                                        </label>

                    <button
                      onClick={() => setCantAñosCalculatorRespuesta(cantAñosCalculator(inputYears, cantVecesAño))}
                    >
                      Aceptar
                      </button>
                  </div>
                </div>
              </div>
              {cantAñosCalculatorRespuesta && (
                <div className="container-values col-md-6">
                  <h5>{cantAñosCalculatorRespuesta['title']}</h5>
                  <div className="row">
                    {cantAñosCalculatorRespuesta['values'].filter(item => item['procentaje']).map(item => (
                      <AnimatedCircleBar
                        item={item}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12 row container-pregunta-respuesta">
              <div className="col-md-12 container-numero">
                <p className="numero">3.</p>
              </div>
              <div className="col-md-6">
                <div className="pregunta">
                  <div className="container-pregunta-numero">
                    <p className="texto">Dosis acumulativa de corticoides orales</p>
                  </div>
                  <div className="respuesta">
                    <button
                      onClick={() => setDosisAcumulativaRespuesta(dosisAcumulativaMenos500)}
                    >
                      {`< 500mg`}
                    </button>
                    <button
                      onClick={() => setDosisAcumulativaRespuesta(dosisAcumulativa500a2000)}
                    >
                      500mg - 2000mg
                      </button>
                    <button
                      onClick={() => setDosisAcumulativaRespuesta(dosisAcumulativaMas2000)}
                    >
                      {`> 2000mg`}
                    </button>
                  </div>
                </div>
              </div>
              {dosisAcumulativaRespueta && (
                <div className="container-values col-md-6">
                  {/* <h5>{dosisAcumulativaRespueta['title']}</h5> */}
                  {parse(dosisAcumulativaRespueta['title'])}
                  <div className="row">
                    {dosisAcumulativaRespueta['values'].map(item => (
                      <AnimatedCircleBar
                        item={item}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </section>

      {dosisAcumulativaRespueta && cantAñosCalculatorRespuesta && cantVecesAñoRespuesta && (
        <div className="col-md-12 fondo-verde container-ver-concluciones">
          <Link to="/herramientas/medidor-corticoides-sistemicos/conclusiones" >
            <button onClick={verConclucionesReactGaEvents} >VER CONCLUSIONES</button>
          </Link>
        </div>
      )}

      <Footer fondoAzul={true} codigo={codigoPacientes} />
    </div>
  );
}

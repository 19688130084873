import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL, URL } from '../store/constants';
import { tracking } from '../store/actions/user'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'


export default function Testimonios(props) {
  const selectedCountry = useSelector((state)=> state.country.selectedCountry);
  const testimoniosArray = useSelector((state) =>
    state.notes.notes.filter((note) => note.id_section == 25 && note[selectedCountry.toLowerCase()] == '1'),
    shallowEqual
  );

  const user = useSelector((state) => state.user.authState, shallowEqual);
  const userData = useSelector((state) => state.user.authData, shallowEqual);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {

    if (user) {
      tracking(userData['user_id'], '25', '', userData['name'], window.location.href)
    }

    ReactGa.pageview("/testimonios")

  }, [])

  return (
    <div className={internaClass}>
      <Helmet>
        <title>Asma Zero - Testimonios</title>
        <meta name="description" content="Asma Zero - Testimonios" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            <Link style={{ textDecoration: 'none', color: '#35aea9' }} to="/">
              ASMA ZERO{' '}
            </Link>{' '}
            /{' '}
            <Link
              style={{ textDecoration: 'none', color: '#35aea9' }}
              to="/testimonios">
              TESTIMONIOS{' '}
            </Link>
          </p>
          <h1 className="text-center">Testimonios</h1>
        </div>
      </main>

      <section className="container testimonios-home">
        <div className="container-testimonios row">
          <div className="todos-testimonios col-md-9">
            <div className="row">
              {testimoniosArray.map((testimonio) => (
                <div
                  key={testimonio['id_note']}
                  className="testimonio col-md-6">
                  <div className="images">
                    {/* <img
                      onClick={handleShow}
                      src="/img/icono-video.png"
                      className="play-button icono-video"
                    ></img>{" "} */}
                    <img
                      className="primer-imagen"
                      src={`${API_MATERIAL}/notes_authors/big/${testimonio['author']['file']}`}
                      alt=""
                    />
                    <img
                      className="segunda-imagen"
                      src="/img/fondo-testimonios.png"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <div className="title">
                      <Link
                        style={{ textDecoration: 'none', color: '#10144b' }}
                        to={`/testimonios/${testimonio['author']['name']}`}>
                        <h1 className="texto-azul">
                          {testimonio['author']['name']}
                        </h1>
                      </Link>
                      <hr></hr>
                    </div>
                    <p className="text-content texto-azul">
                      {ReactHtmlParser(testimonio['author']['detail'])}
                    </p>
                  </div>
                  {/* <Modal
                    className="modal-evaluacion"
                    size="lg"
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Body className="modal-evaluacion-body" closeButton>
                      {ReactHtmlParser(testimonio["video"])}
                    </Modal.Body>
                  </Modal> */}
                </div>
              ))}
            </div>
          </div>
          <div className="notas col-md-3">
            <Link
              style={{
                color: '#840729',
              }}
              to="/diario-asmazero">
              <h1>MÁS NOTAS</h1>
            </Link>
            {props.notesSection.length > 0 && (
              <div className="notas-container">
                {props.notesSection.filter(note=> note[selectedCountry.toLowerCase()] == '1').slice(0, 3).map((nota) => (
                  <div className="nota">
                    <Link
                      style={{
                        textDecoration: 'none',
                        color: '#10144b',
                      }}
                      to={`/nota/${nota['slug']}`}>
                      <h2 className="title">{nota.name}</h2>
                    </Link>
                    <p className="texto-bordo">{nota.author.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import Menu from './Menu';
import Button from './ButtonMenu';
import RedesIconos from './IconosRedesInterna';
import NavbarInterna from './NavbarInterna';
import Footer from './Footer';
import Helmet from "react-helmet";
import { URL, AUTH_URL, HREF } from '../store/constants';
import { setUrlBack, receiveCookie } from '../store/actions'
import { readCookie } from '../components/Cookie'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import IframeComm from 'react-iframe-comm';
import ReactGa from 'react-ga'
import {Redirect} from "react-router-dom";


export default function MediTuDependenciaPatients(props) {
    const dispatch = useDispatch();
    const urlBack = useSelector((state) => state.user.urlBack);

    const URL = AUTH_URL + encodeURIComponent(urlBack);

    const risk = props.match.params.id

    const userState = useSelector((state) => state.user.authState);
    const user = useSelector((state) => state.user.authData);

    const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
    const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

    const cookie = useSelector((state) => state.user.mtdCookie, shallowEqual)

    const country = useSelector((state)=> state.country, shallowEqual);

    console.log("Conuntry",country)


    const [iframeSrc, setIframeSrc] = useState(country.country === "CO" || country.selectedCountry === "CO" ? "https://www.asmazero.com/meditudependencia/patients.html?COL" : "https://www.asmazero.com/meditudependencia/patients.html")

    // Iframe params

    const attributes = {
        src: iframeSrc,
        id: "medi-tu-dependencia",
        frameBorder: "0",
        width: "100%",
    };

    const postMessageData = user ? user : [];

    // parent received a message from iframe
    const onReceiveMessage = () => {
        console.log("onReceiveMessage");
    };

    // iframe has loaded
    const onReady = () => {
        console.log("onReady");
    };

    ReactGa.pageview(window.location.href)

    if (window.screen.width < 768) {
        document.documentElement.scrollTop = 100
    }

    useEffect(()=>{
        if(country.country){
            if(country.country === "CO"){
                console.log("ES COLOMBIA")
                setIframeSrc("https://www.asmazero.com/meditudependencia/patients.html?COL")
            } else if (country.country === "PE"){
                console.log('ES PERU')
            } else if (country.country === "EC"){
                console.log('ES ECUADOR')
            } else {
            }
        }
    },[country])


    return (
        <div className={internaClass} >
            <Helmet>
                <title>Mide tu Dependencia</title>
                <meta name="description" content="Mide tu dependencia" />
            </Helmet>
            <div className={overlayClass}></div>
            <header>
                <div id="header-main" className="margin-bottom-7">
                    <NavbarInterna
                        props={props}
                    />
                </div>
                <Menu />
            </header>
            <Button />

            <RedesIconos />

            <section className="medi-tu-dependencia-patients" >

                {cookie == null && (
                    <div id="cookies-container" className="fixed-bottom bg-black" >
                        <div className="container-fluid" id="cookie-message">
                            <div>
                                <p className="texto-blanco" > Estos no son consejos médicos. No dejes o cambies tu medicación para el asma sin consultar con un profesional de la salud. </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                {/* <a onClick={() => { dispatch(receiveCookie(true)) }} href="#" id="cookies_confirm" class="btn btn-primary bg-blue ml-4 p-2 btn-track" data-label="cookies_confirm" data-section="cookie_message">ACEPTO</a> */}
                                <a onClick={() => { dispatch(receiveCookie(true)) }} href='#' id="cookies_close" className="btn btn-primary bg-blue ml-4 p-2 btn-track" data-label="cookies_close" data-section="cookie_message">CERRAR</a> </div>
                        </div>
                    </div>
                )}

                <IframeComm
                    attributes={attributes}
                    postMessageData={postMessageData}
                    handleReady={onReady}
                    handleReceiveMessage={onReceiveMessage}
                />


            </section>
            <Footer
                fondoNegro={true}
                marginTop={true}
            />

        </div>
    )
}
import React from "react";
import { connect } from "react-redux";
import DiarioAsmaZero from "../components/DiarioAsmaZero";
import { testimoniosJSON } from "../json";

class DiarioAsmaZeroContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let filterSection = function (array, id) {
      let arr = [];

      for (let i = 0; i < array.length; i++) {
        if (array[i]["id_section"] == id) {
          arr.push(array[i]);
        }
      }
      return arr;
    };

    let notesSection = filterSection(this.props.notes, 24);

    return (
      <DiarioAsmaZero
        props={this.props}
        notesSection={notesSection}
      />
    );
  }
}

const mapStateToProps = state => ({
  notes: state.notes.notes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, null)(DiarioAsmaZeroContainer);

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

const MenuEducacion = props => {

  const sections = useSelector((state) => state.sections.sections);
  const selectedCountry = useSelector((state)=>state.country.selectedCountry)

  return (
    <div className="container-menu-educacion">
      <h1 className="texto-bordo">OTRAS SECCIONES</h1>
      <ul className="sidebar-educacion">
        {sections.filter((section) => section['parent_id_section'] == '14' && section['status'] == '1' && section[selectedCountry.toLowerCase()] == '1').sort(function (a, b) {
          return (a['field_order'] - b['field_order'])
        }).map((section) => (
          <li>
            <Link
              className={props.matchSection == section['slug'] ? 'nav-link-selected' : 'nav-link'}
              aria-current="page"
              onClick={props.closeSidebar}
              title={section['name']}
              to={`/educacion/${section['slug']}`}
            >
              {section['name']}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuEducacion;

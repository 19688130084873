import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import Modal from 'react-bootstrap/Modal';
import ModalIngresar from '../components/ModalIngresar';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {API_MATERIAL, API, FETCH_COUNTRY, SET_SELECTED_COUNTRY} from '../store/constants';
import { Link } from 'react-router-dom';
import store from '../store';
import { setSelectedCountry } from '../store/actions/country';


//sections_sliders/media/
const paramsBanner = {
  spaceBetween: 30,
  effect: 'fade',
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: '.swiper-pagination.left',
    type: 'bullets',
    clickable: true
  }
};

export default function HomeBanner() {

  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const banners = useSelector((state)=> state.slidersReducer.sliders, shallowEqual)



  const [showBanner, setShowBanner] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const country = useSelector((state)=> state.country, shallowEqual);
  const [selectedCountry,setSelected] = useState('');


  const [videoContainer, setVideoContainer] = useState({});
  const [testMobileImage, setTestMobileImage] = useState("https://www.asmae.co/content/dam/open-digital/easthma/es-co/images/hero-banner-mobile.png")


  const handleTrueBanner = () => {
    setShowBanner(true);
  };
  const handleFalseBanner = () => setShowBanner(false);
  const dispatch = useDispatch();

  const filterBanner = (bannerArray, user) => {
    let newArray = [];

    if (user) {
      newArray = bannerArray.filter((banner) => banner['only_public'] != '1');
    } else {
      newArray = bannerArray.filter((banner) => banner['professional'] == '0');
    }

    return newArray;
  };

  useEffect(()=>{
    if (country.selectedCountry === "EC"){
      setSelected("Ecuador");
    }else if (country.selectedCountry === "PE"){
      setSelected("Perú");
    }else{
      setSelected("Colombia");
    }
  },[country])

  const handleSelectedCountry = (e) =>{
    dispatch(setSelectedCountry(e.target.id))
  }

  return (
    <main id="home-banner">
      {' '}
      <img src={"img/banner-misc.png"} className="banner-misc"  alt={"banner"}/>
      <div className="header-fondo"></div>

      {banners && (
        <>
          {/*  */}
          {
            banners.filter((banner)=> banner[`${country.selectedCountry.toLowerCase()}`] === '1').length > 1 ?
             (
              <Swiper {...paramsBanner} lazy preloadImages updateOnImagesReady>
                {
                  banners
                    .filter((banner) => banner[`${country.selectedCountry.toLowerCase()}`] === '1')
                    .reverse() // Invierte el orden del array
                    .map((banner, index) => {
                      return (
                       <div
                         key={index}
                        style={{
                          backgroundImage: `url(${API_MATERIAL}/sections_sliders/media/${window.screen.width < 768 && banner['file_mobile'] ? banner['file_mobile'] : banner['file_desktop']})`,
                          width:'100%'
                        }}
                        className="container-banner"
                       >
                         <div className="texto-app">
                          <h2 className="first">
                            {
                              banner['title'].includes('Bienvenido a Asma Zero') ?
                                <>
                                  {`¡Bienvenido a Asma Zero ${selectedCountry}!`}
                                </>
                              :
                                <>
                                  {banner['title']}
                                </>
                            }

                          </h2> <br />
                          <h3 className="second">{banner['title2']}</h3>
                          {banner['button_text'] ? (
                            <>
                              {
                                banner['volanta'] ?
                                    <p style={{color:'#068F8D', fontWeight:'600', marginTop: '1rem'}}>{banner['volanta']}</p>
                                    :null
                              }
                              { banner['url_canonical'].indexOf('https') > -1  ?
                                  (
                                      <a href={`${banner['url_canonical']}`} target={"_blank"}>
                                        <button style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                                          {banner['button_text']}
                                          {
                                            banner['file_icon'] ?
                                                <img src={`${API_MATERIAL}/sections_sliders/media/${banner['file_icon']}`} alt={"Banner"}/>
                                                : null
                                          }
                                        </button>
                                      </a>
                                  )
                                  :
                                  (
                                      <Link to={`${banner['url_canonical']}`}>
                                        <button style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                                          {banner['button_text']}
                                          {
                                            banner['file_icon'] ?
                                                <img src={`${API_MATERIAL}/sections_sliders/media/${banner['file_icon']}`} alt={"Banner"}/>
                                                : null
                                          }
                                        </button>
                                      </Link>
                                  )
                              }
                            </>
                          ) : (
                              <div className="transparente"></div>
                          )}

                         </div>

                       </div>
                     )
                   })
                 }
               </Swiper>
             )
            :
              <div
              style={{
                backgroundImage: `url(${API_MATERIAL}/sections_sliders/media/${window.screen.width < 768 && banners[0]['file_mobile'] ? banners[0]['file_mobile'] : banners[0]['file_desktop']})`,
                width:'100%'
              }}
              className="container-banner"
              >
                <div className="texto-app">
                  <h1 id="first" style={{textAlign:'right',width:`${banners[0]['title'].length > 40 ? '50%':''}`}}>
                    {banners[0]['title'].includes('Bienvenido a Asma Zero') ?
                      <>
                      {`¡Bienvenido a Asma Zero ${selectedCountry}!`}
                      </>
                    : <>
                        {banners[0]['title']}
                      </>
                    }
                  </h1> <br />
                  <h1 id="second" style={{width:`${window.screen.width < 768 ? '100%' : '100%'}`,marginTop:'', textAlign:'end'}}>{banners[0]['title2']}</h1>
                  {banners[0]['button_text'] ? (
                    <div>
                      <div className="texto-app">
                        {
                          banners[0]['volanta'] ?
                              <p style={{color:'#068F8D', fontWeight:'600', fontSize:'0.8rem'}}>{banners[0]['volanta']}</p>
                              :null
                        }
                      </div>
                      <a href={`${banners[0]['url_canonical']}`}>
                        <button style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:'1.2rem'}}>
                          {banners[0]['button_text']}
                          {
                            banners[0]['file_icon'] ?
                              <img src={`${API_MATERIAL}/sections_sliders/media/${banners[0]['file_icon']}`}/>
                            : null
                          }
                        </button>
                      </a>
                    </div>
                  ) : (
                      <div className="transparente"></div>
                  )}

                </div>

              </div>
          }
          <div className="container row flagContainer" style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%', top:'1%'}}>
            <div className="flags">
              <img src="/img/flag-col.png" alt="Colombia" className={`${country.selectedCountry == "CO" ? 'selectedFlag' : ''}`} width={25} height={20} onClick={handleSelectedCountry} id="CO"/>
              <p>CO</p>
            </div>
            <div className="flags">
              <img src="/img/flag-ec.png" alt="Ecuador" className={`${country.selectedCountry == "EC" ? 'selectedFlag' : ''}`}  width={25} height={20} onClick={handleSelectedCountry} id="EC"/>
              <p>EC</p>
            </div>
            <div className="flags">
              <img src="/img/flag-peru.png" alt="Colombia" className={`${country.selectedCountry == "PE" ? 'selectedFlag' : ''}`} width={25} height={20} onClick={handleSelectedCountry} id="PE"/>
              <p>PE</p>
            </div>
          </div>


        </>
      )}
      <div className="container-scroll-down">
        <div className="mouse"></div>
      </div>
      <Modal
        centered
        show={showBanner}
        onHide={handleFalseBanner}
        className="banner-home-modal"
        size="xl">
        <img src="/img/az-popup-coronavirus.jpg" alt="" />
      </Modal>
      <ModalIngresar
        ingresar={true}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </main>
  );
}

import { FETCH_COUNTRY, SET_SELECTED_COUNTRY } from "../constants";
import React from 'react'
import { Redirect } from 'react-router-dom'

const setCountry = country => ({
  type: FETCH_COUNTRY,
  country
});
export const setSelectedCountry = (selected) =>({
  type: SET_SELECTED_COUNTRY,
  selected
})

export const fetchCountry = () => dispatch => {
  fetch('https://get.geojs.io/v1/ip/country.json')
    .then(res => res.json())
    .then(data => {
      dispatch(setCountry(data));
      switch (data.country) {
        case "EC":
          console.log("ESTOY EN ECUADOR !");
          dispatch(setSelectedCountry(data.country));
          localStorage.setItem("pais","EC");
          break;
        case "PE":
          console.log("ESTOY EN PERU !");
          dispatch(setSelectedCountry(data.country));
          localStorage.setItem("pais","PE");
          break;
        case "CO":
          console.log("ESTOY EN COLOMBIA !");
          dispatch(setSelectedCountry(data.country));
          localStorage.setItem("pais","CO");
          break;
        default:
          console.log("ESTOY EN COLOMBIA !");
          dispatch(setSelectedCountry("CO"));
          localStorage.setItem("pais","CO");
          break;
      }

    })
    .catch(err => {
      console.error(err);
      //throw new Error(err);
    });
};




import {
  FETCH_EVENTS
} from "../constants";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return Object.assign({}, state, {
        allEvents: action.events,
        disloggedEvents: action.events.filter(event => event["professional"] == "0")
      });
    default:
      return state;
  }
};
export const URL = window.location.origin + "/"
export const HOME = window.location.origin
export const API_AWS =
  'https://3no8ss9wr1.execute-api.us-east-1.amazonaws.com/prod';
export const API_TRACKING = 'https://nfj1tgjhs7.execute-api.us-east-1.amazonaws.com/prod/'
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_SECTIONS = 'FETCH_SECTIONS';
export const FETCH_RESOURCES = 'FETC_RESOURCES';
export const FETCH_SLIDERS = 'FETCH_SLIDERS'
export const SET_SINTOMAS = 'SET_SINTOMAS';
export const SET_LIMITACION = 'SET_LIMITACION';
export const SET_NECESIDAD = 'SET_NECESIDAD';
export const SET_DESPERTAR = 'SET_DESPERTAR';
export const SET_DESTINATARIO = 'SET_DESTINATARIO';
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const FETCH_EXPERTS = 'FETCH_EXPERTS';
export const RESET_TEST = 'RESET_TEST';
export const FETCH_PEOPLE = 'FETCH_PEOPLE';
export const FETCH_MAPS = 'FETCH_MAPS';
export const FETCH_COUNTRY = 'FETCH_COUNTRY';
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY"
export const SET_FILTER_MAP = 'SET_FILTER_MAP';
export const SET_GEOLOCATION = 'SET_GEOLOCATION';
export const SET_PROFESIONAL_MAP = 'SET_PROFESIONAL_MAP';
export const SET_USER_STATE = 'SET_USER_STATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const SET_VALID_USER = 'SET_VALID_USER';
export const SET_MATCH = 'SET_MATCH';
export const SET_URL_BACK = "SET_URL_BACK"
export const SET_MENU_CLASS = 'SET_MENU_CLASS'
export const SET_COOKIE = "SET_COOKIE"

// PROD CONSTANTS
export const API = 'https://api.asmazero.com/api/';
export const API_MATERIAL = 'https://backend.asmazero.com/material'
export const API_BACKEND = 'https://backend.asmazero.com/'

const AUTH_URL_BASE = 'https://api.cloud.astrazeneca.com';
export const AUTH_CLIENTID = 'c6e9300d-ef81-4462-ab4d-2ebee8fc6d7f';
export const AUTH_CLIENTSECRET = 'SfN05U4ArGemsVXoAI2lPzkfcP';
// export const AUTH_URL_BACK = encodeURIComponent('http://localhost:3002/entrenamiento-medico/rescate-y-mantenimiento');
export const AUTH_URL =
  AUTH_URL_BASE +
  '/identity/authorize?client_id=' +
  AUTH_CLIENTID +
  '&response_type=code&scope=openid%20profile%20email%20address%20phone&state=abc' +
  '&app=azmedicines-ar&redirect_uri=';
export const AUTH_URL_REGISTER =
  AUTH_URL_BASE +
  '/identity/authorize?client_id=' +
  AUTH_CLIENTID +
  '&response_type=code&scope=openid%20profile%20email%20address%20phone&state=abc&screenToRender=traditionalRegistration' +
  '&app=azmedicines-ar&redirect_uri=';
export const AUTH_URL_TOKEN =
  AUTH_URL_BASE + '/services/v1/identity/openid/token';
export const AUTH_URL_USER =
  AUTH_URL_BASE + '/services/v1/identity/openid/userinfo';

// QA CONSTANTS
/*export const API = 'https://backend-qa.asmazero.com/api/'; //Api
export const API_MATERIAL = 'https://backend-qa.asmazero.com/material'
export const API_BACKEND = 'https://backend-qa.asmazero.com/'*/

/*const AUTH_URL_BASE = 'https://api-int.qa.cloud.astrazeneca.com';
export const AUTH_CLIENTID = '9b8a28be-e002-4283-8460-cc997b33ae1c';
export const AUTH_CLIENTSECRET = 'kKdi7ENRdH4L2uGytMjq5ehc6s';
// export const AUTH_URL_BACK = encodeURIComponent('http://localhost:3002/entrenamiento-medico/rescate-y-mantenimiento');
export const AUTH_URL =
  AUTH_URL_BASE +
  '/identity/authorize?client_id=' +
  AUTH_CLIENTID +
  '&response_type=code&scope=openid%20profile%20email%20address%20phone&state=abc' +
  '&app=azmedicines-ar&redirect_uri=';
export const AUTH_URL_REGISTER =
  AUTH_URL_BASE +
  '/identity/authorize?client_id=' +
  AUTH_CLIENTID +
  '&response_type=code&scope=openid%20profile%20email%20address%20phone&state=abc&screenToRender=traditionalRegistration' +
  '&app=azmedicines-ar&redirect_uri=';
export const AUTH_URL_TOKEN =
  AUTH_URL_BASE + '/services/v1/identity/openid/token';
export const AUTH_URL_USER =
  AUTH_URL_BASE + '/services/v1/identity/openid/userinfo';*/

// Plans
export const RECEIVE_PLANS = 'RECEIVE_PLANS';
export const RECEIVE_PLAN = 'RECEIVE_PLAN';
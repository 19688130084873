export const testimoniosJSON = [
  {
    id: 1,
    name: "Mariano",
    videoId: "Ukc4oNPmc8Y",
    videoURL: "https://www.youtube-nocookie.com/embed/Ukc4oNPmc8Y",
    frase: "Todo tiene una solución, pero siempre manteniendo la obediencia.",
    content:
      "Mariano tiene 46 años y 41 de ellos los pasó conviviendo con su asma. Por su trabajo como técnico en refrigeración está constantemente en contacto con gases de todo tipo. A lo largo de los años tuvo algunos problemas respiratorios graves y, en tres ocasiones, internaciones. Hoy tiene su asma controlado y lleva una vida totalmente normal con su trabajo, su familia y sus mascotas.",
    image1: "/img/testimonio-2-1.jpg",
    image2: "/img/testimonio-2-2.jpg",
  },
  {
    id: 2,
    name: "Belén",
    videoId: "NH11l_uUiBc",
    videoURL: "https://www.youtube-nocookie.com/embed/NH11l_uUiBc",
    frase:
      "A veces se notan más síntomas y a veces menos, y eso no significa que dejes de tener la enfermedad.",
    content:
      "Belén tiene 25 años y es asmática desde los 21.  La primera vez que identificó su asma logró controlarlo con un tratamiento médico, pero una vez que se sintió mejor lo abandonó y tuvo una recaída. Hoy tiene su condición bajo control y lleva su vida con total normalidad.",
    image1: "/img/testimonio-1-1.png",
    image2: "/img/testimonio-1-2.png",
  },
  {
    id: 3,
    name: "Vanesa",
    videoId: "WMUcqOxVhgY",
    videoURL: "https://www.youtube-nocookie.com/embed/WMUcqOxVhgY",
    frase:
      "En momentos más sensibles he estado con más síntomas pero sino en principio llevo una vida normal.",
    content:
      "Vanesa trabaja en un Colegio, ha sido asmática desde pequeña, y tiene una hermana gemela asmática. Hoy va al neumonólogo y lleva una vida normal.",
    image1: "/img/testimonio-4-1.jpg",
    image2: "/img/testimonio-4-2.png",
  },
  {
    id: 4,
    name: "Elizabeth",
    videoId: "t0cHQagaU38",
    videoURL: "https://www.youtube-nocookie.com/embed/t0cHQagaU38",
    frase: "Ser asmático no es ser enfermo, es un modo de ser en este mundo.",
    content:
      "Elizabeth tiene 43 años y convive con el asma desde la crisis económica del 2001. Tiene dos hijas, también asmáticas, a las que define como luchadoras: el asma no les impide hacer ballet, natación, correr y llevar una vida completamente normal, como la que lleva ella.",
    image1: "/img/testimonio-3-1.jpg",
    image2: "/img/testimonio-3-2.png",
  },
];

export const recursosJSON = [
  {
    image: "/img/recursos-mapa.png",
    title: "Mapa Asma Zero",
    copete: "Encontrá un especialista cerca tuyo. ",
    link: "/mapa",
    id: 1,
    planDeAccion: false,
    download: false,
  },
  // {
  //   image: "/img/recursos-polvo.png",
  //   title: "Uso de dispositivo en Polvo Seco",
  //   copete: "",
  //   link: "https://www.youtube.com/watch?v=09DABIDhMdg",
  //   id: 2,
  //   planDeAccion: true,
  //   download: false,
  // },
  // {
  //   image: "/img/recursos-aerosol.png",
  //   title: "Uso de dispositivo en Aerosol - Video",
  //   copete: "",
  //   link: "https://www.youtube.com/watch?v=_j0auWmeHPA",
  //   id: 3,
  //   planDeAccion: true,
  //   download: false,
  // },
  {
    image: "/img/recursos-whatsapp.png",
    title: "Enfermería Online",
    copete: "",
    link:
      "https://api.whatsapp.com/send?phone=5491138703406&text=Hola%20Asma%20Zero",
    id: 4,
    planDeAccion: false,
    download: false,
  },
  {
    image: "/img/recursos-flyer.png",
    title: "Qué es el asma - Flyer",
    copete: "",
    link: "/pdfs/que_es_el_asma.pdf",
    id: 5,
    planDeAccion: false,
    download: true,
    downloadName: "Que-es-el-asma-flyer",
  },
  {
    image: "/img/recursos-plan.png",
    title: "Plan de Acción",
    copete: "",
    link: "https://asmazero.com.ar/nota/la-importancia-del-plan-de-accion-en-asma",
    id: 6,
    planDeAccion: true,
    download: true,
    downloadName: false,
  },
  {
    image: "/img/recursos-afiche.png",
    title: "Uso de Dispositivos - Afiche",
    copete:
      "Cómo usar los dispositivos en aerosol y polvo seco en formato Afiche.",
    link: "/pdfs/uso_de_dispositivo.jpg",
    id: 7,
    planDeAccion: false,
    download: true,
    downloadName: "uso-de-dispositivos",
  },
  {
    image: "/img/recursos-que-es-el-asma.png",
    title: "Qué es el asma - Afiche",
    copete: "Información clave sobre asma en formato Afiche.",
    link: "/pdfs/que-es-el-asma-afiche.pdf",
    id: 8,
    planDeAccion: false,
    download: true,
    downloadName: "Que-es-el-asma-afiche",
  },
];

export const eventos = [
  {
    id_event: "1",
    name:
      "Webcast - Avances en el tratamiento de la EPOC - Prof. Mona Bafadhel",
    fecha: "10 de Marzo",
    place: "Webcast en Vivo",
    horario: "11HS",
    detail:
      "1. La carga de síntomas y exacerbaciones para los pacientes \n 2. Fenotipos, biomarcadores, endotipos y rasgos tratables de la EPOC \n 3. Recomendaciones GOLD",
    directed: "Profesionales de la Salud. Traducción al español disponible.",
    need_logged: "1"
  },
  {
    id_event: "2",
    name:
      "Webcast - El rol del ICS en el tratamiento de la EPOC - Dr. David Halpin",
    fecha: "15 de Abril",
    place: "Webcast en Vivo",
    horario: "10HS",
    detail:
      "1. La carga de síntomas y exacerbaciones para los pacientes \n 2. Fenotipos, biomarcadores, endotipos y rasgos tratables de la EPOC \n 3. Recomendaciones GOLD",
    directed: "Profesionales de la Salud. Traducción al español disponible.",
    need_logged: "1"
  },
  {
    id_event: "3",
    name:
      "Webcast - Trabajar con una guía de tratamiento paso a paso en entornos médicos - Dr. John Haugney",
    fecha: "15 de Junio ",
    place: "Webcast en Vivo",
    horario: "10HS",
    detail:
      "1.  Algunos paradigmas en Asma. \n 2.  Los enfoques actuales son inadecuados. \n 3.  Tratamiento de acuerdo a las guías. \n  4.  Cambie sus prácticas y las de sus pacientes para obtener mejores resultados.",
    directed: "Profesionales de la Salud. Traducción al español disponible.",
    need_logged: "1"
  },
];

import {
  URL,
  SET_URL_BACK,
  RESET_USER_DATA,
  SET_USER_DATA,
  SET_USER_STATE,
  SET_MATCH,
  SET_VALID_USER,
  SET_COOKIE
} from "../constants";

const initialState = {
  validUser: false,
  urlBack: URL,
  authData: null,
  authState: null,
  match: null,
  mtdCookie: null
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return Object.assign({}, state, {
        authData: action.authData
      });
    case SET_USER_STATE:
      return Object.assign({}, state, {
        authState: action.authState
      });
    case RESET_USER_DATA:
      return initialState;
    case SET_VALID_USER:
      return Object.assign({}, state, {
        validUser: action.validUser
      });
    case SET_MATCH:
      return Object.assign({}, state, {
        match: action.match
      });
    case SET_URL_BACK:
      return Object.assign({}, state, {
        urlBack: action.url
      })
    case SET_COOKIE:
      return Object.assign({}, state, {
        mtdCookie: action.cookie
      })
    default:
      return state;
  }
};

export default user;
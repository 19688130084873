import {
  FETCH_EVENTS,
  FETCH_MAPS,
  FETCH_SECTIONS,
  FETCH_NOTES,
  FETCH_SLIDERS,
  API,
  FETCH_QUESTIONS,
  FETCH_EXPERTS
} from "../constants";
import axios from "axios";

const setNotes = notes => ({
  type: FETCH_NOTES,
  notes
});

const setEvents = events => ({
  type: FETCH_EVENTS,
  events
});

const setSections = sections => ({
  type: FETCH_SECTIONS,
  sections
});

const setMaps = (maps) => ({
  type: FETCH_MAPS,
  maps,
});

const setSliders = (sliders) => ({
  type: FETCH_SLIDERS,
  sliders
})

const setQuestions = (questions) => ({
  type: FETCH_QUESTIONS,
  questions
});

const setExperts = experts => ({
  type: FETCH_EXPERTS,
  experts
});


export const fetchData = () => dispatch => {
  fetch(API)
    .then(res => res.json())
    .then(data => {
      console.log(data)
      dispatch(setSliders(data["sliders"]))
      dispatch(setSections(data["sections"]))
      dispatch(setNotes(data["notes"]));
      dispatch(setEvents(data["events"]))
      dispatch(setMaps(data["mapa"]))
      dispatch(setQuestions(data["questions"]))
      dispatch(setExperts(data["authors"]))
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    });
};
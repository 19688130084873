import React from "react";
import AddToCalendar from "react-add-to-calendar";
import ReactHtmlParser from "react-html-parser";

// LIBRERÍA DE NPM REACT-ADD-TO-CALENDAR

const ButtonCalendarComponent = (props) => {


  const descriptionToString = (detail) => {
    if (detail) {
      return ReactHtmlParser(detail)['0']['props']['children'][0]
    } else {
      return ''
    }
  }

  let event = {
    title: props.title,
    description: descriptionToString(props.detail),
    location: props.place,
    startTime: props.start,
    endTime: "2016-09-16T21:45:00-04:00"
  };

  let icon = { 'calendar-plus-o': 'left' };


  return (
    <AddToCalendar
      event={event}
      buttonTemplate={icon}
      displayItemIcons={false}
      buttonLabel="AGREGAR AL CALENDARIO"
    />
  );
};

export default ButtonCalendarComponent;

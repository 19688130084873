import {RECEIVE_PLANS, RECEIVE_PLAN, API_AWS} from '../constants';

const storePlans = (data) => ({
  type: RECEIVE_PLANS,
  data,
});
const storePlan = (data) => ({
  type: RECEIVE_PLAN,
  data,
});

export const loadPlans = (user) => (dispatch) => {
  let route =
    user.userType === 'hsp'
      ? '/v1/plansbyuser/' + user.id
      : '/v1/plansbypatient/' + user.email;
  console.log('loadPlans', API_AWS + route);

  fetch(API_AWS + route)
    .then((res) => res.json())
    .then((data) => {
      dispatch(storePlans(data));
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
};

export const storeNewPlan = (plan) => (dispatch) => {
  console.log('newPlan', API_AWS + '/v1/plans');
  fetch(API_AWS + '/v1/plans', {
    method: 'POST',
    body: JSON.stringify(plan),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch(storePlan(data[0]));
    })
    .catch((err) => {
      console.log('newPlan', err);
      throw new Error(err);
    });
};

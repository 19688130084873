import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { closeSidebar, toggleMenuRecursos } from '../store/actions'
import { API_MATERIAL, URL } from '../store/constants'


export default function SubMenuRecursos(props) {

    const subMenuRecursosClass = useSelector((state) => state.menu.subMenuRecursos, shallowEqual)
    const selectedCountry = useSelector((state)=> state.country.selectedCountry);

    const dispatch = useDispatch();


    const handleCloseSidebar = () => {
        dispatch(closeSidebar())
    }

    const handleToggleMenu = () => {
        dispatch(toggleMenuRecursos(subMenuRecursosClass))
    }

    const filterResources = (originalArray, prop) => {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            if (lookupObject[i]["name"] != "test") {
                newArray.push(lookupObject[i]);
            }
        }
        return newArray;
    };


    return (
        <nav style={{ backgroundColor: `${props.background}` }} className={subMenuRecursosClass} id="navigation">
            {props.resources.length && (
                <div className="menu-principal-container">
                    <div className="sub-menu-container-recursos">
                        <h1 className="sub-menu-title">RECURSOS</h1>
                        <div className="sub-menu-ss2020-container">
                            <ul id="menu-ss2020" className="sub-menu-slider-right">
                                {filterResources(props.resources, "id_note_resource").filter(resource=> resource[selectedCountry.toLowerCase()] == '1').map((recurso) => (
                                    <li className="recurso" >

                                        {recurso.url_canonical.length > 0 ? (
                                            <a
                                                style={{
                                                    color: "#10144b",
                                                }}
                                                href={recurso.url_canonical}
                                                target="_blank"
                                            >
                                                <div className="link-recursos">
                                                    <img className="imagen-recurso" src="/img/icono-link-blanco.png" alt="" />
                                                    <h1>{recurso.name}</h1>
                                                </div>
                                            </a>
                                        ) : (
                                                <a
                                                    style={{
                                                        color: "#10144b",
                                                    }}
                                                    href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                                    download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                                    target="_blank"
                                                >
                                                    <div className="link-recursos">
                                                        <img className="imagen-recurso" src="/img/icono-descarga-blanco.png" alt="" />
                                                        <h1>{recurso.name}</h1>
                                                    </div>
                                                </a>
                                            )}

                                    </li>
                                ))}
                                {/* {sections.filter((section) => section['parent_id_section'] == '14' && section['status'] == '1').sort(function (a, b) {
                                    return (a['field_order'] - b['field_order'])
                                }).map((section) => (
                                    <li className="sub-menu-item">
                                        <Link
                                            className="nav-link"
                                            aria-current="page"
                                            onClick={handleCloseSidebar}
                                            title={section['name']}
                                            to={`/educacion/${section['slug']}`}
                                        >
                                            {section['name']}
                                        </Link>
                                    </li>
                                ))} */}
                            </ul>
                        </div>
                        <div
                            onClick={handleToggleMenu}
                            className="swiper-button-prev swiper-button-white"
                        >
                            <h2 className="sub-menu-recursos-back-button">
                                <a onClick={handleToggleMenu}>Volver</a>
                            </h2>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
}

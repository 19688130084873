import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  setSintomas,
  setLimitacion,
  setDespertar,
  setNecesidad,
  setDestinatarioRedux
} from '../store/actions/test';
import YouTube from 'react-youtube-embed';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import Footer from './Footer';
import Menu from './Menu';
import Button from './ButtonMenu';
import { codigoPacientes } from '../codigos';
import { resetTest } from '../store/actions/test'
import { tracking } from '../store/actions/user'
import { API_TRACKING } from '../store/constants'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'

export default function Evaluacion(props) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  const clases = {
    btnSintomasSi: 'btn',
    btnSintomasNo: 'btn',
    btnDespertarSi: 'btn',
    btnDespertarNo: 'btn',
    btnNecesidadSi: 'btn',
    btnNecesidadNo: 'btn',
    btnLimitacionSi: 'btn',
    btnLimitacionNo: 'btn',
    btnPersonal: 'btn',
    btnTercero: 'btn',
  };
  const [clasesBotones, setClasesBotones] = useState(clases);

  const handleChangeTrue = (setFuncion, btnclassSi, btnClassNo) => {
    if (clasesBotones[btnclassSi] == 'btn') {
      dispatch(setFuncion(true));
      setClasesBotones((prevState) => {
        return {
          ...prevState,
          [btnclassSi]: 'btn fondo-verde',
          [btnClassNo]: 'btn',
        };
      });
    } else {
      dispatch(setFuncion(null));
      setClasesBotones((prevState) => {
        return { ...prevState, [btnclassSi]: 'btn' };
      });
    }
  };

  const handleChangeFalse = (setFuncion, btnclassSi, btnClassNo) => {
    if (clasesBotones[btnClassNo] == 'btn') {
      dispatch(setFuncion(false));
      setClasesBotones((prevState) => {
        return {
          ...prevState,
          [btnClassNo]: 'btn fondo-bordo',
          [btnclassSi]: 'btn',
        };
      });
    } else {
      dispatch(setFuncion(null));
      setClasesBotones((prevState) => {
        return { ...prevState, [btnClassNo]: 'btn' };
      });
    }
  };

  const test = useSelector((state) => state.test);
  const [verResultadoClass, setVerResultadoClass] = useState('disabled');

  const verResultadoClick = () => {
    if (
      test.sintomas == null ||
      test.limitacion == null ||
      test.necesidad == null ||
      test.despertar == null ||
      (userState && destinatario == '')
    ) {
      setVerResultadoClass('active');
    } else {
      props.resultTest();
      enviarDatos();
      setVerResultadoClass('disabled');
    }
  };

  const calcularResultado = () => {
    let respuestas = [
      test.sintomas,
      test.limitacion,
      test.necesidad,
      test.despertar,
    ];
    let count = 0;
    respuestas.map((respuesta) => {
      if (respuesta == true) {
        count += 1;
      }
    });
    if (count == 0) {
      return 'Buen control del Asma';
    } else if (count > 0 && count < 3) {
      return 'Control parcial del Asma';
    } else {
      return 'Control insuficiente del Asma';
    }
  };

  const getUser = () => {
    if (userState) {
      if (destinatario == 'personal') {
        return 'invitado';
      } else {
        return user.name;
      }
    }
  };

  const enviarDatos = async () => {
    let datos = {
      user: getUser(),
      resultado: calcularResultado(),
      formName: 'eval'
    };

    const rawResponse = await fetch(API_TRACKING, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        datos
      })
    });

    const content = await rawResponse.text()


    // axios.post(
    //   'https://vjy58n8yh1.execute-api.us-east-1.amazonaws.com/default/tracking-prod-hello',
    //   JSON.stringify({ datos }),
    // );
  };

  useEffect(() => {
    dispatch(resetTest())
    if (userState) {
      tracking(user['user_id'], '34', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/evaluacion");

  }, [])
  const questions = useSelector((state) => state.test.questions);


  const [destinatario, setDestinatario] = useState('');

  const handleChangePersonal = (btnPersonal, btnTercero) => {
    if (clasesBotones[btnPersonal] == 'btn') {
      setDestinatario('personal');
      dispatch(setDestinatarioRedux('personal'))
      setClasesBotones((prevState) => {
        return {
          ...prevState,
          [btnPersonal]: 'btn fondo-verde',
          [btnTercero]: 'btn',
        };
      });
    } else {
      setDestinatario('');
      dispatch(setDestinatarioRedux(''))
      setClasesBotones((prevState) => {
        return { ...prevState, [btnPersonal]: 'btn' };
      });
    }
  };

  const handleChangeTercero = (btnTercero, btnPersonal) => {
    if (clasesBotones[btnTercero] == 'btn') {
      setDestinatario('tercero');
      dispatch(setDestinatarioRedux('tercero'))
      setClasesBotones((prevState) => {
        return {
          ...prevState,
          [btnTercero]: 'btn fondo-verde',
          [btnPersonal]: 'btn',
        };
      });
    } else {
      setDestinatario('');
      dispatch(setDestinatarioRedux(''))
      setClasesBotones((prevState) => {
        return { ...prevState, [btnTercero]: 'btn' };
      });
    }
  };

  return (
    <div className={internaClass}>
      <Helmet>
        <title>Evaluación de control</title>
        <meta name="description" content="Evaluación de control" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            HERRAMIENTAS / Evaluación de Control
          </p>
          <h1 className="text-center">Evaluación de Control de asma</h1>
        </div>
      </main>

      <section className="evaluacion-preguntas  margin-bottom-7">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-5">
              <p className="titulo texto-bordo mb-4">
                En las últimas 4 semanas, tuviste / ha tenido el paciente…
              </p>
              <img src="img/frase-linea.svg" className="w-100"></img>
            </div>
            <div className="col-md-7 col-lg-7">
              <div className="row">
                {userState && (
                  <div className="destinatario col-md-12">
                    <h3>Destinatario del test:</h3>
                    <div className="buttons">
                      <button
                        className={clasesBotones.btnPersonal}
                        onClick={() =>
                          handleChangePersonal('btnPersonal', 'btnTercero')
                        }>
                        Personal
                      </button>
                      <button
                        className={clasesBotones.btnTercero}
                        onClick={() =>
                          handleChangeTercero('btnTercero', 'btnPersonal')
                        }>
                        Tercero
                      </button>
                    </div>
                  </div>
                )}

                <div className="col-md-5">
                  <div className="pregunta">
                    <div className="container-pregunta-numero">
                      <p className="numero">1.</p>
                      {questions && (
                        <p className="texto">{questions[0]['name']}</p>
                      )}
                    </div>
                    <div className="respuesta">
                      <button
                        onClick={() =>
                          handleChangeTrue(
                            setSintomas,
                            'btnSintomasSi',
                            'btnSintomasNo',
                          )
                        }
                        className={clasesBotones.btnSintomasSi}>
                        SI
                      </button>
                      <button
                        onClick={() =>
                          handleChangeFalse(
                            setSintomas,
                            'btnSintomasSi',
                            'btnSintomasNo',
                          )
                        }
                        className={clasesBotones.btnSintomasNo}>
                        NO
                      </button>
                    </div>
                  </div>
                </div>

                <div className="offset-md-2 col-md-5">
                  <div className="pregunta">
                    <div className="container-pregunta-numero">
                      <p className="numero">2.</p>
                      {questions && (
                        <p className="texto">{questions[1]['name']}</p>
                      )}

                    </div>
                    <div className="respuesta">
                      <button
                        onClick={() =>
                          handleChangeTrue(
                            setDespertar,
                            'btnDespertarSi',
                            'btnDespertarNo',
                          )
                        }
                        className={clasesBotones.btnDespertarSi}>
                        SI
                      </button>
                      <button
                        onClick={() =>
                          handleChangeFalse(
                            setDespertar,
                            'btnDespertarSi',
                            'btnDespertarNo',
                          )
                        }
                        className={clasesBotones.btnDespertarNo}>
                        NO
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="pregunta">
                    <div className="container-pregunta-numero">
                      <p className="numero">3.</p>
                      {questions && (
                        <p className="texto">{questions[2]['name']}</p>
                      )}

                    </div>
                    <div className="respuesta">
                      <button
                        onClick={() =>
                          handleChangeTrue(
                            setNecesidad,
                            'btnNecesidadSi',
                            'btnNecesidadNo',
                          )
                        }
                        className={clasesBotones.btnNecesidadSi}>
                        SI
                      </button>
                      <button
                        onClick={() =>
                          handleChangeFalse(
                            setNecesidad,
                            'btnNecesidadSi',
                            'btnNecesidadNo',
                          )
                        }
                        className={clasesBotones.btnNecesidadNo}>
                        NO
                      </button>
                    </div>
                  </div>
                </div>

                <div className="offset-md-2 col-md-5">
                  <div className="pregunta">
                    <div className="container-pregunta-numero">
                      <p className="numero">4.</p>
                      {questions && (
                        <p className="texto">{questions[3]['name']}</p>
                      )}

                    </div>
                    <div className="respuesta">
                      <button
                        onClick={() =>
                          handleChangeTrue(
                            setLimitacion,
                            'btnLimitacionSi',
                            'btnLimitacionNo',
                          )
                        }
                        className={clasesBotones.btnLimitacionSi}>
                        SI
                      </button>
                      <button
                        onClick={() =>
                          handleChangeFalse(
                            setLimitacion,
                            'btnLimitacionSi',
                            'btnLimitacionNo',
                          )
                        }
                        className={clasesBotones.btnLimitacionNo}>
                        NO
                      </button>
                    </div>
                  </div>
                </div>

                <div style={{ height: '5rem' }} className="col-12">
                  <button
                    onClick={verResultadoClick}
                    className="btn ver-resultado">
                    VER RESULTADO
                  </button>
                  <p className={verResultadoClass}>
                    Se deben responder todas las preguntas
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="evaluacion-video  margin-bottom-7">
        <div className="container">
          <div className="row">
            <div className="col-md-8 position-relative">
              <YouTube aspectRatio="16:9" id={'nXSamkfOAwY'} />
            </div>
            <div className="col-md-4 descripcion">
              <hr className="fondo-azul"></hr>
              <h4 className="name">Dra. Analía Barro</h4>
              <p>Video sobre Evaluación de control GINA</p>
            </div>
          </div>

          <div className="descripcion-assessment">
            <p style={{ marginTop: '3rem' }} className="descripcion-assessment">
              Información basada en “GINA Assessment of Asthma Control".
              Disponible en www.ginasthma.com. Esta herramienta es utilizada
              para obtener una orientación respecto al nivel de control de la
              patología.{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                Esta no es una herramienta de diagnóstico. Siempre se debe
                consultar a un médico para obtener un diagnóstico o un nivel de
                control certero sobre la enfermedad.
              </span>
            </p>
          </div>
        </div>
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

import React from "react";
import { connect } from "react-redux";
import CirculoAsmaZero from "../components/CirculoAsmaZero";

class CirculoAsmaZeroContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let matchSection = this.props.match.params.section;
    let circuloAsmaZero = "nav-link";
    let circuloAsmaZeroKids = "nav-link";
    let asocArgMedicinaResp = "nav-link";
    let SocArgMedicina = "nav-link";
    let alergiaInmonologia = "nav-link";
    let astrazeneca = "nav-link";
    let pageImage = false;
    let celImage = false;
    let href = false;
    let expertosAsmaZero = "nav-link";

    let selectTitle = function (params) {
      if (params === "circulo-asma-zero") {
        circuloAsmaZero = "nav-link-selected";
        circuloAsmaZeroKids = "nav-link";
        expertosAsmaZero = "nav-link";
        asocArgMedicinaResp = "nav-link";
        SocArgMedicina = "nav-link";
        alergiaInmonologia = "nav-link";
        astrazeneca = "nav-link";
        pageImage = "/img/circulo-asmazero.png";
        return "Círculo Asma Zero";
      } else if (params === "circulo-asma-zero-kids") {
        circuloAsmaZero = "nav-link";
        circuloAsmaZeroKids = "nav-link-selected";
        expertosAsmaZero = "nav-link";
        asocArgMedicinaResp = "nav-link";
        SocArgMedicina = "nav-link";
        alergiaInmonologia = "nav-link";
        astrazeneca = "nav-link";
        pageImage = "/img/asmazero-kids.png";
        return "Círculo Asma Zero Kids";

      } else if (params === "expertos-asma-zero") {
        circuloAsmaZero = "nav-link";
        circuloAsmaZeroKids = "nav-link";
        expertosAsmaZero = "nav-link-selected";
        asocArgMedicinaResp = "nav-link";
        SocArgMedicina = "nav-link";
        alergiaInmonologia = "nav-link";
        astrazeneca = "nav-link";
        pageImage = "/img/expertos.png";
        return "Expertos Asma Zero";


      } else if (params === "aamr") {
        circuloAsmaZero = "nav-link";
        circuloAsmaZeroKids = "nav-link";
        expertosAsmaZero = "nav-link";
        asocArgMedicinaResp = "nav-link-selected";
        SocArgMedicina = "nav-link";
        alergiaInmonologia = "nav-link";
        astrazeneca = "nav-link";
        pageImage = "/img/quienes-somos-aamr.png";
        celImage = "/img/aaamr-cel.png";
        href = "https://www.aamr.org.ar/";
        return "Asociación Argentina de Medicina Respiratoria";
      } else if (params === "sam") {
        circuloAsmaZero = "nav-link";
        circuloAsmaZeroKids = "nav-link";
        expertosAsmaZero = "nav-link";
        asocArgMedicinaResp = "nav-link";
        SocArgMedicina = "nav-link-selected";
        alergiaInmonologia = "nav-link";
        astrazeneca = "nav-link";
        pageImage = "/img/quienes-somos-sam.png";
        celImage = "/img/sam-cel.png";
        href = "http://www.sam.org.ar/";
        return "Sociedad Argentina de Medicina";
      } else if (params === "aaaeic") {
        circuloAsmaZero = "nav-link";
        circuloAsmaZeroKids = "nav-link";
        expertosAsmaZero = "nav-link";
        asocArgMedicinaResp = "nav-link";
        SocArgMedicina = "nav-link";
        alergiaInmonologia = "nav-link-selected";
        astrazeneca = "nav-link";
        pageImage = "/img/asocarg.png";
        celImage = "/img/aaaeic-cel.png";
        href = "https://www.alergia.org.ar/";
        return "Asociación Argentina de Alergia e Inmunología Clínica";
      } else {
        circuloAsmaZero = "nav-link";
        circuloAsmaZeroKids = "nav-link";
        asocArgMedicinaResp = "nav-link";
        expertosAsmaZero = "nav-link";
        SocArgMedicina = "nav-link";
        alergiaInmonologia = "nav-link";
        astrazeneca = "nav-link-selected";
        pageImage = "/img/quienes-somos-astrazeneca.png";
        celImage = "/img/astrazeneca-cel.png";
        href = "https://www.astrazeneca.com/country-sites/argentina.html";
        return "AstraZeneca";
      }
    };

    let setIdSection = function (matchParams) {
      if (matchParams === "circulo-asma-zero") {
        return 18;
      } else if (matchParams == "circulo-asma-zero-kids") {
        return 21;
      } else if (matchParams == "aaaeic") {
        return 22;
      } else if (matchParams == "aamr") {
        return 19;
      } else if (matchParams == "sam") {
        return 20;
      } else if (matchParams == "astrazeneca") {
        return 23;
      }
    };

    let filterPeople = function (array, id) {
      let arr = [];

      for (let i = 0; i < array.length; i++) {
        if (array[i]["id_section"] == id) {
          arr.push(array[i]);
        }
      }
      return arr;
    };

    let title = selectTitle(matchSection);
    let sectionId = setIdSection(matchSection);

    let peopleSection = filterPeople(this.props.people.people, sectionId);

    return (
      <CirculoAsmaZero
        expertosAsmaZero={expertosAsmaZero}
        match={this.props.match.params.section}
        circuloAsmaZero={circuloAsmaZero}
        circuloAsmaZeroKids={circuloAsmaZeroKids}
        expertosAsmaZero={expertosAsmaZero}
        asocArgMedicinaResp={asocArgMedicinaResp}
        SocArgMedicina={SocArgMedicina}
        astrazeneca={astrazeneca}
        alergiaInmonologia={alergiaInmonologia}
        peopleSection={peopleSection}
        title={title}
        pageImage={pageImage}
        celImage={celImage}
        href={href}
        props={this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  people: state.people,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, null)(CirculoAsmaZeroContainer);

import React from 'react';

export default function LineamientoPregunta(props) {
  return (
    <div>
      <div className="lineamientos-pregunta">
        <h4 className="bold">{props.pregunta}</h4>
        <div
          className={props.buttons.length === 2 ? 'buttons' : 'buttons-triple'}>
          {props.buttons.map((item, index) => (
            <button
              className={[
                props.buttons.length === 2 && index === 0
                  ? 'button-verde'
                  : 'button-gris',
                index === props.answer && 'active',
              ].join(' ')}
              onClick={() => props.setAnswer(index)}>
              {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import store from './store/index';
import {
  Provider
} from 'react-redux';
import Main from './containers/Main';
import * as serviceWorker from './serviceWorker';

// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-K46DRV3'
// }

// TagManager.initialize(tagManagerArgs)

ReactDOM.render( 
  <Provider store = {store} >
  < Main / >
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React from "react";
import { connect } from "react-redux";
import Nota from "../components/Nota";
import { testimoniosJSON } from "../json";

class NotaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let matchSection = this.props.match.params.section;

    let selectNote = function (array, params) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].slug === params) {
          return array[i];
        }
      }
    };

    let filterSection = function (array, id) {
      let arr = [];

      for (let i = 0; i < array.length; i++) {
        if (array[i]["id_section"] == id) {
          arr.push(array[i]);
        }
      }
      return arr;
    };

    let selectTitleSection = function (id) {
      let params = {
        title: "",
        slug: ""
      };
      if (id == 29) {
        params.title = "MANEJANDO EL ASMA";
        params.slug = "manejando-el-asma";
        return params;
      } else if (id == 27) {
        params.title = "COMPRENDIENDO EL ASMA";
        params.slug = "comprendiendo-el-asma";
        return params;
      } else if (id == 28) {
        params.title = "DIAGNOSTICANDO EL ASMA";
        params.slug = "diagnosticando-el-asma";
        return params;
      } else if (id == 30) {
        params.title = "INHALADORES Y OTROS TRATAMIENTOS";
        params.slug = "inhaladores-y-otros-tratamientos";
        return params;
      } else if (id == 31) {
        params.title = "ASMA EN PEDIATRÍA Y ADOLESCENCIA";
        params.slug = "asma-en-pediatria-y-adolescencia";
        return params;
      } else if (id == 32) {
        params.title = "RECURSOS PARA PACIENTES";
        params.slug = "recursos-para-pacientes";
        return params;
      }
    };

    let selectIdSection = function (note) {
      if (note) {
        return note.id_section;
      }
    };

    let note = selectNote(this.props.notes.notes, this.props.match.params.slug);
    let idSection = selectIdSection(note);
    let titleSection = selectTitleSection(idSection);
    let notesSection = filterSection(this.props.notes.notes, idSection);

    return (
      <Nota
        note={note}
        notesSection={notesSection}
        titleSection={titleSection}
        matchSection={matchSection}
        props={this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  notes: state.notes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, null)(NotaContainer);

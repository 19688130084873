import React from 'react';
import ModalIngresar from './ModalIngresar';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { setUrlBack, closeSidebar, toggleMenuHerramientas } from '../store/actions';
import { readCookie } from '../components/Cookie'
import { URL } from '../store/constants';

export default function SubMenuHerramientas(props) {
  const [modalIngresar, setModalIngresar] = React.useState(false);
  const userState = useSelector((state) => state.user.authState);
  const subMenuHerramientasClass = useSelector((state) => state.menu.subMenuHerramientas)
  const country = useSelector((state)=>state.country, shallowEqual)
  const [proximamenteModal, setProximamenteModal] = React.useState(false);
  const handleTrueProximamenteModal = () => setProximamenteModal(true);
  const handleFlaseProximamenteModal = () => setProximamenteModal(false);
  const dispatch = useDispatch();
  let cookieToken = readCookie('azmed_user_token')

  const handleCloseSidebar = () => {
    dispatch(closeSidebar())
  }

  const handleToggleMenu = () => {
    dispatch(toggleMenuHerramientas(subMenuHerramientasClass))
  }

  return (
    <nav className={subMenuHerramientasClass} id="navigation">
      {props.sections.length && (
        <div className="menu-principal-container">
          <div className="sub-menu-container-herramientas">
            <h1 className="sub-menu-title">HERRAMIENTAS</h1>
            <div className="sub-menu-ss2020-container">
              <ul id="menu-ss2020" className="sub-menu-slider-right">
                <li className="sub-menu-item">
                  <Link
                    onClick={handleCloseSidebar}
                    title="Herramientas"
                    to="/mapa"
                    className="nav-link"
                    aria-current="page">
                    Mapa Asma Zero
                  </Link>
                </li>
                {/* <li className="sub-menu-item">
                  <Link
                    onClick={handleCloseSidebar}
                    title="Entrenamiento Médico"
                    to="/evaluacion"
                    className="nav-link"
                    aria-current="page">
                    Evaluación de Control
                  </Link>
                </li> */}
                {/*{
                  country.selectedCountry !== "CO" ?

                    <li
                      className="sub-menu-item">
                      <Link
                        onClick={handleCloseSidebar}
                        title="Medí Tu Dependencia"
                        to="/herramientas/medi-tu-dependencia/patients"
                        className="nav-link"
                        aria-current="page">
                        Mide Tu Abuso de los Inhaladores
                      </Link>
                    </li>
                    :null
                } */}
                {/* <li
                    className="sub-menu-item">
                  <Link
                      onClick={handleCloseSidebar}
                      title="Medí Tu Dependencia"
                      to="/herramientas/medi-tu-dependencia/patients"
                      className="nav-link"
                      aria-current="page">
                    Mide Tu Abuso de los Inhaladores
                  </Link>
                </li> */}
                {
                  /*country.selectedCountry === "CO" ?
                    
                    <li className="sub-menu-item">
                      <a href="https://www.asmae.co/home/lungprint/experience.html" target="_blank" className="nav-link">Asma-e</a>
                    </li>

                  :null*/
                }
              </ul>
            </div>
            <div
              onClick={handleToggleMenu}
              className="swiper-button-prev herramientas swiper-button-white">
              <h2
                onClick={handleToggleMenu}
                className="sub-menu-back-button-herramientas">
                Volver
              </h2>
            </div>
            <div className="menu-redes">
              {' '}
              <a
                href="https://www.instagram.com/otgwesteurope/"
                target="_blank">
                <i className="fab fa-instagram"></i>
              </a>{' '}
              <a
                href="https://www.facebook.com/OTG.West.Europe/"
                target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>{' '}
              <a
                href="https://www.youtube.com/user/OTGWestEurope"
                target="_blank">
                <i className="fab fa-youtube"></i>
              </a>{' '}
            </div>
          </div>
        </div>
      )}
      {/* <ModalIngresar
        profesionales={profesionalesTrue}
        show={modalIngresar}
        onHide={() => setModalIngresar(false)}
      /> */}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={proximamenteModal}
        onHide={handleFlaseProximamenteModal}>
        <Modal.Body className="ingresar-modal">
          <img src="/img/icon-luna.svg" alt="" />
          <div className="container">
            <p className="texto-verde">ASMA ZERO / CONTENIDO EXCLUSIVO</p>
            <h1>PRÓXIMAMENTE</h1>
            <p>Este contenido se encuentra en desarrollo</p>
          </div>
        </Modal.Body>
      </Modal>
      <ModalIngresar
        show={modalIngresar}
        onHide={() => setModalIngresar(false)}
        ingresar={false}
      />
    </nav>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'
import { API_MATERIAL } from '../store/constants'


export default function NoteSeccionHome(props) {
    return (

        <div className="category">
            <div className="title-button">
                <Link style={{ textDecoration: 'none' }} to={props.link}>
                    <h1 className="category-title texto-verde">
                        {props.title}
                    </h1>
                </Link>
            </div>
            <div className="content-and-button">
                <div className="desktop-button-mas button-mas-notas">
                    <Link to={props.link}>
                        <button className="mas-notas">+ NOTAS</button>
                    </Link>
                </div>
                {props.array.length > 0 && (
                    <div className="row container-nota">
                        {props.array.slice(0, 4).map((nota) => (
                            <div className="col-md-3 nota">
                                {!(nota.author == null) ? (
                                    <img
                                        className="imagen-perfil-nota"
                                        src={`${API_MATERIAL}/notes_authors/big/${nota.author["file"]}`}
                                        alt=""
                                    />
                                ) : (
                                        <img
                                            className="imagen-perfil-nota"
                                            src="/img/user-doctor.png"
                                            alt=""
                                        />
                                    )}
                                <div className="author-content">
                                    {!(nota.author == null) ? (
                                        <p className="name texto-verde">{nota.author.name}</p>
                                    ) : (
                                            <p className="name texto-verde">Asma Zero</p>
                                        )}
                                    <Link
                                        style={{ textDecoration: "none", color: "#10144b" }}
                                        to={`/${props.section}/${nota["slug"]}`}
                                    >
                                        <h1>{nota.name}</h1>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
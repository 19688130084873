import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Menu from './Menu';
import Button from './ButtonMenu';
import { useSelector, shallowEqual } from 'react-redux';
import { URL, HOME } from '../store/constants';
import Helmet from 'react-helmet'
import { tracking } from '../store/actions/user'


export default function PageError() {

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);

  useEffect(() => {
    if (userState) {
      tracking(user['user_id'], '8', '', user['name'], window.location.href)
    }
    // ReactGa.pageview(`${URL}mapa`)

  }, [])

  return (
    <div className={`${internaClass} page-error`}>
      <Helmet>
        <title>Asmazero | Error</title>
        <meta name="description" content="Asmazero | Error" />
      </Helmet>
      <div className={overlayClass}></div>

      <header>
        <div id="header-main" className="margin-bottom-7">
          <nav>
            <div className="navbar-left">
              <Link to="/" className="logo">
                {' '}
                <img src="/img/logo-asmazero-color.png" className="logo-color" alt="Logo asmazero"/>{' '}
              </Link>
            </div>
          </nav>
        </div>
        <Menu />
      </header>
      <Button />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            LO SENTIMOS
          </p>
          <h1 className="text-center">La página solicitada, no existe</h1>
          <p className="texto-azul" >Lo invitamos a seguir navegando el sitio</p>
          <a className="page-error" href={HOME}>
            <button className="page-error-button" >IR A LA PÁGINA PRINCIPAL</button>
          </a>
        </div>
      </main>


      <Footer />

    </div>
  );
}

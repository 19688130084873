import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Autocomplete from 'react-google-autocomplete';
const marketCat = {
  '1': 'Neumonólogos',
  '2': 'Alergistas',
  '3': 'Instituciones',
};

const FiltrosMapa = (props) => {
  return (
    <div className="container position-relative">
      <div className="row mapa-filtros justify-content-center">
        {/* <div className="col-md-2 mb-1">
          <div className="dropdown w-100">
            <NavDropdown
              onSelect={props.setFilter}
              title={
                props.filter !== false ? marketCat[props.filter] : 'Filtros'
              }
              id="nav-dropdown">
              <NavDropdown.Item eventKey={false}>Sin Filtros</NavDropdown.Item>
              {Object.keys(marketCat).map((i) => (
                <NavDropdown.Item key={i} eventKey={i}>
                  {marketCat[i]}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </div>
        </div> */}
        {/* <div className="col-md-3 mb-1">
          <div className="buscador position-relative">
            <input
              type="search"
              className="form-control profesionales fondo-azul"
              id="profecionales"
              placeholder="Profesionales de la salud"
              onChange={(event) =>
                event.target.value == ''
                  ? props.setSearch(false)
                  : props.setSearch(event.target.value)
              }></input>{' '}
            {props.search === false && (
              <img src="img/icono-lupa.png" className="icono-lupa"></img>
            )}{' '}
          </div>
        </div> */}
        {/* <div className="col-md-4 mb-1">
          <div className="form-group m-0">
            <div className="buscar position-relative"></div>
            <Autocomplete
              className="localizacion form-control"
              placeholder="Ingresá datos de localización"
              onPlaceSelected={(place) => {
                if (place.geometry) {
                  props.setRegion(
                    props.generateRegion({
                      latitude: place.geometry.location?.lat(),
                      longitude: place.geometry.location?.lng(),
                    }),
                  );
                }

                // console.log(place);
              }}
              types={['address']}
              componentRestrictions={{country: 'ar'}}
            />
            <img src="img/icono-lupa-azul.png" className="icono-lupa"></img>{' '}
          </div>
        </div> */}
        <div className="col-md-5 mb-2">
          <button
            onClick={() => props.geolocation()}
            type="button"
            className="btn ubicacion  w-100">
            Usar ubicación actual
            <img
              src="img/icono-ubicacion-azul.png"
              className="icono-ubicacion" ></img>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltrosMapa;

import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import MenuEducacion from './MenuEducacion';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import { API_MATERIAL, URL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser';
import Menu from './Menu';
import Button from './ButtonMenu';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import Helmet from 'react-helmet'
import { tracking } from '../store/actions/user'
import ReactGa from 'react-ga'
import {setSelectedCountry} from "../store/actions";
import RecursosEducacion from "./RecursosEducacion";

export default function Educacion(props) {

    const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
    const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

    const userState = useSelector((state) => state.user.authState, shallowEqual);
    const user = useSelector((state) => state.user.authData, shallowEqual);
    const resources = useSelector((state) => state.resourcesReducer.resources, shallowEqual);
    const matchSection = props.match
    const notes = useSelector((state) => state.notes.notes, shallowEqual);
    const sections = useSelector((state) => state.sections.sections, shallowEqual);
    const selectedSectionName = sections.filter((section) => section['slug'] == matchSection);
    const selectedSectionId = sections.filter((section) => section['slug'] == matchSection);

    const dispatch = useDispatch();
    const country = useSelector((state)=> state.country, shallowEqual);
    const [selectedCountry,setSelected] = useState('');
    const pais = country.selectedCountry.toLowerCase();

    useEffect(() => {
        if (userState) {
            tracking(user['user_id'], selectedSectionId[0]['id_section'], '', user['name'], window.location.href)
        }
        ReactGa.pageview(`/educacion/${matchSection}`)

    }, [matchSection])


    useEffect(()=>{
        if (country.selectedCountry === "EC"){
            setSelected("Ecuador");
        }else if (country.selectedCountry === "PE"){
            setSelected("Perú");
        }else{
            setSelected("Colombia");
        }
    },[country])

    useEffect(() => {
        console.log('%c matchsection:','background: #222; color: #bada55',props.matchSection);
        console.log('%c ID Section:','background: #222; color: #bada55',selectedSectionId[0]['id_section']);
        console.log('%c Resources:','background: #222; color: #bada55',resources);
        console.log('%c Notas:','background: #222; color: #bada55',notes);
        console.log('%c Pais:','background: #222; color: #bada55',country.selectedCountry.toLowerCase());

        console.log(country.selectedCountry.toLowerCase() in resources[0]);
        console.log('Pais',pais);
    },[]);

    return (
        <div className={internaClass}>
            <Helmet>
                <title>Asma Zero - Educación</title>
                <meta name="description" content="Asma Zero - Educación" />
            </Helmet>
            <div className={overlayClass}></div>
            <header>
                <div id="header-main" className="margin-bottom-7">
                    <NavbarInterna props={props} />
                </div>
                <Menu />
            </header>
            <Button />

            <RedesIconos />

            <main id="interna-banner" className="margin-bottom-7">
                <div className="container">
                    <p className="miga-de-pan seccion text-center">
                        <Link style={{ textDecoration: 'none', color: '#35aea9' }} to="/">
                            ASMA ZERO /{' '}
                        </Link>
                        <Link
                            style={{ textDecoration: 'none', color: '#35aea9' }}
                            to="/educacion">
                            EDUCACIÓN
                        </Link>{' '}
                    </p>
                    {sections.length > 0 && (
                        <h1 className="text-center">{selectedSectionName[0]['name']}</h1>
                    )}
                </div>
            </main>

            <section className="container educacion-responsive-home diaro-asmazero-home">
                <div className="container-diario row">
                    <div className="col-md-9">
                        {props.matchSection !== 'recursos-pacientes' && props.matchSection !== 'el-asma-y-los-ninos' ? (
                            <div className="row">
                                {notes
                                    .filter(
                                        (note) => note['id_section'] === selectedSectionId[0]['id_section'],
                                    )
                                    .map((nota) => (
                                        <div key={nota.id_note} className="nota col-md-6">
                                            <Link to={`/nota/${nota['slug']}`}>
                                                {nota.author.file ? (
                                                    <img
                                                        className="imagen-perfil-nota"
                                                        src={`${API_MATERIAL}/notes_authors/big/${nota.author['file']}`}
                                                        alt={nota['slug'] + ' imagen'}
                                                    />
                                                ) : (
                                                    <img
                                                        className="imagen-perfil-nota"
                                                        src="/img/user-doctor.png"
                                                        alt="Imagen de doctor"
                                                    />
                                                )}
                                            </Link>
                                            <div className="responsive-educacion-title">
                                                <p className="name texto-bordo">{nota.author.name}</p>
                                                <Link
                                                    style={{ textDecoration: 'none', color: '#10144b' }}
                                                    to={`/nota/${nota['slug']}`}>
                                                    <h1 className="educacion-title-nota" >{nota.name}</h1>
                                                </Link>
                                            </div>
                                            <p>{nota.copete ? ReactHtmlParser(nota.copete.slice(0, 150)) : ''}...</p>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <RecursosEducacion
                                resources={resources}
                                section={props.matchSection}
                                pais={pais}
                            />
                        )}
                    </div>
                    <div className="col-md-3">
                        <MenuEducacion matchSection={props.matchSection} />
                    </div>
                </div>
            </section>
            <Footer codigo={codigoPacientes} />
        </div>
    );
}

import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { API_MATERIAL } from '../store/constants'


export default function HelmetMetaData(props) {
    let location = useLocation();
    let currentUrl = "http://www.asmazero.com" + location.pathname;
    let quote = props.quote !== undefined ? props.quote : "";
    let title = props.title !== undefined ? props.title : "Asma Zero";
    let image = props.image !== undefined ? props.image : `${API_MATERIAL}/settings/media/12062019_122116_13523847.png`;
    let description = props.description !== undefined ? props.description : "";
    let hashtag = props.hashtag !== undefined ? props.hashtag : "#asmazero";
    return (
        <Helmet>
            <title>{title}</title>
            <meta charset="utf-8" ></meta>
            <meta http-equiv="X-UA-Compatible" content="IE=edge" ></meta>
            <meta name="csrf_token" content=""></meta>
            <meta property="type" content="website" ></meta>
            <meta property="url" content={currentUrl} ></meta>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" ></meta>
            <meta name="msapplication-TileColor" content="#ffffff" ></meta>
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" ></meta>
            <meta name="theme-color" content="#ffffff" ></meta>
            <meta name="_token" content="" ></meta>
            <meta name="robots" content="noodp" ></meta>
            <meta property="title" content={title} ></meta>
            <meta property="quote" content={quote} ></meta>
            <meta name="description" content={description} ></meta>
            <meta property="image" content={image} ></meta>
            <meta property="og:locale" content="en_US" ></meta>
            <meta property="og:type" content="website" ></meta>
            <meta property="og:title" content={title} ></meta>
            <meta property="og:quote" content={quote} ></meta>
            <meta property="og:hashtag" content={hashtag} ></meta>
            <meta property="og:image" content={image} ></meta>
            <meta content="image/*" property="og:image:type" ></meta>
            <meta property="og:url" content={currentUrl} ></meta>
            <meta property="og:site_name" content="Asma Zero" ></meta>
            <meta property="og:description" content={description} ></meta>
        </Helmet>
    );
}
// ----------- IMPORTS --------

// LIBRERIAS 
import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGa from 'react-ga';
import { Utm } from 'utm-parameters-toolset'


// ACTIONS DE REDUX
import {
  fetchSections,
  fetchData,
  fetchEvents,
  fetchPeople,
  fetchMaps,
  fetchResources,
  fetchCountry,
  storeAuthData,
  storeAuthState,
  logout,
  tracking,
  receiveValidUser
} from '../store/actions';

// VARIABLES GLOBALES
import {
  AUTH_URL_TOKEN,
  AUTH_CLIENTID,
  AUTH_CLIENTSECRET,
  AUTH_URL_USER,
  HOME
} from '../store/constants';

// COMPONENTES
import HomeContainer from './HomeContainer';
import ContactoContainer from './ContactoContainer';
import DaleVozContainer from './DaleVozContainer'
import EvaluacionContainer from './EvaluacionContainer';
import NotaContainer from './NotaContainer';
import MapaContainer from './MapaContainer';
import TestimoniosContainer from './TestimoniosContainer';
import DiarioAsmaZeroContainer from './DiarioAsmaZeroContainer';
import ResultadoTestContainer from './ResultadoTestContainer';
import CirculoAsmaZeroContainer from './CirculoAsmaZeroContainer';
import PlanDeAccionContainer from './PlanDeAccionContainer';
import Lineamientos from '../components/Lineamientos';
import PageError from '../components/PageError';
import store from '../store';
import Home from '../components/Home';
import SingleTestimonioContainer from './SingleTestimonioContainer';
import EducacionContainer from './EducacionContainer';
import HomeEducacionContainer from './HomeEducacionContainer';
import HomeEntrenamiento from '../components/HomeEntrenamiento';
import EntrenamientoContainer from './EntrenamientoContainer';
import EntrenamientoMenu from '../components/EntrenamientoMenu';
import EventosPage from '../components/EventosPage';
import MediTuDependenciaResults from '../components/MediTuDependenciaResult'
import MediTuDependenciaPatients from '../components/MediTuDependenciaPatients'
import MediTuDependenciaHcp from '../components/MediTuDependenciaHcp'
import { readCookie, setcookie, delcookie } from '../components/Cookie';
import ScrollTop from '../components/ScrollTop';
import MediTuDependencia from '../components/MediTuDependencia';
import DefaultPage from '../components/DefaultPage';
import CalculadoraCorticoidesContainer from './CalculadoraCorticoidesContainer'
import Concluciones from '../components/Concluciones';
import TestAsmaE from '../components/TestAsmaE';
import PageRedirect from "../components/PageRedirect";

//-----------------------------------------------------------------------------


// Los parámetros UTM son parámetros de URL que usa Google para medir la efectividad de campañas de marketing de URLs únicas.
const utm = new Utm({
  domain: HOME,  // support subdomain
  expires: 365,
})



class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.AuthInit = this.AuthInit.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
  }

  // El método componentDidMount() se ejecuta después que la salida del componente ha sido renderizada en el DOM
  componentDidMount() {
    !this.props.user.authState && this.AuthInit();

    // Actions de redux que van a buscar el contenido de la aplicación a la API
    store.dispatch(fetchData());
    store.dispatch(fetchPeople());
    store.dispatch(fetchResources());
    store.dispatch(fetchCountry());

    // Inicializo la librería React-ga con el UA de astrazeneca
    ReactGa.initialize('UA-159130046-2')

    // Configuración UTM
    utm.store()  // simply store everything from window.location to cookies
    utm.get()    // get everything from cookies as object
    utm.clear()  // clear storage
  }




  // ------------------- AUTH CONFIGURACIÓN --------------------

  readGet(o) {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  }

  // BUSCA EL TOKEN QUE VUELVE DEL LOGIN EXTERNO DE ASTRAZENECA
  async fetchUserInfo(token) {
    let result = await fetch(AUTH_URL_USER + '?alt=json&access_token=' + token)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        delcookie('azmed_user_token');
        this.props.logout();
      });
    return result;
  }

  async AuthInit() {
    //console.log('Main - AuthInit');

    let getCode = this.readGet('code');
    //console.log(getCode)
    let token = null;
    let cookieToken = readCookie('azmed_user_token');
    //console.log(cookieToken);
    if (cookieToken != null) {
      let userInfo = await this.fetchUserInfo(cookieToken);
      if (userInfo.hasOwnProperty('email')) {
        this.props.storeAuthData(userInfo);
        //console.log('POR SETEAR EL AUTHSTATE');
        this.props.storeAuthState(true);
      }
    }

    if (getCode != null) {
      //console.log('Main - AuthInit', getCode);
      window.history.replaceState({}, document.title, '/');
      let params = {
        grant_type: 'authorization_code',
        code: getCode,
        client_id: AUTH_CLIENTID,
        client_secret: AUTH_CLIENTSECRET,
        redirect_uri: encodeURIComponent(this.props.user.urlBack),
      };
      let myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      let urlencoded = new URLSearchParams();
      Object.keys(params).map((key) => urlencoded.append(key, params[key]));
      token = await fetch(AUTH_URL_TOKEN, {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
      })
        .then((res) => res.json())
        .catch((err) =>
          console.log('Main - ERR AuthInit fetch(AUTH_URL_TOKEN', err),
        );
      if (token.hasOwnProperty('access_token')) {
        console.log('Main - AuthInit has token', token);
        delcookie('azmed_user_token');
        setcookie('azmed_user_token', token.access_token, 1 * 1 * 1 * 1e3);
        let userInfo = await this.fetchUserInfo(token.access_token);
        if (userInfo.hasOwnProperty('email')) {
          // CARGO LA INFO DEL USER EN EL ESTADO DE REDUX
          this.props.storeAuthData(userInfo);
          this.props.storeAuthState(true);
          this.props.receiveValidUser(false);
          tracking(userInfo['user_id'], '3', '', userInfo['name'], '')
        }
      }
    }
  }

  /// --------------- AUTH CONFIGURATION END ------------------------------



  render() {
    // CONFIGURACIÓN DE LAS RUTAS Y DE CADA COMPONENTE QUE VA A SER RENDERIZADO EN CADA RUTA
    return (
      <div>
        <Router>
          <ScrollTop>
            <Switch>

              <Route
                exact
                path="/"
                render={() => <HomeContainer />}
              />
              <Route
                exact
                path="/eventos.php"
                render={() => <Redirect to={{ pathname: "/eventos", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/index.php"
                render={() => <Redirect to={{ pathname: "/", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/seccion-lista/herramientas/"
                render={() => <Redirect to={{ pathname: "/", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/test_de_control_de_inflamacion.php"
                render={() => <Redirect to={{ pathname: "/evaluacion", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/recursos_para_profesionales.php"
                render={() => <Redirect to={{ pathname: "/entrenamiento-medico/recursos-profesionales", state: { status: 301 } }} />}
              />
              <Route
                path="/diario-asmazero/notas/"
                render={() => <Redirect to={{ pathname: "/diario-asmazero", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/especialista_buscador.php"
                render={() => <Redirect to={{ pathname: "/mapa", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/seccion-lista/diario-asmazero/"
                render={() => <Redirect to={{ pathname: "/diario-asmazero", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/seccion-lista/quienes-somos/"
                render={() => <Redirect to={{ pathname: "/quienes-somos/circulo-asma-zero", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/contacto.php"
                render={() => <Redirect to={{ pathname: "/contacto", state: { status: 301 } }} />}
              />
              <Route
                exact
                path="/contacto"
                render={(props) => <ContactoContainer {...props} />}
              />
              <Route
                exact
                path="/dalevozatuspulmones"
                render={(props) => <DaleVozContainer {...props} />}
              />
              <Route
                exact
                path="/eventos"
                render={(props) => (
                  <EventosPage {...props} />
                )}
              />
              <Route
                exact
                path="/eventos/:id/:slug"
                render={(props) => (
                  <EventosPage {...props} />
                )}
              />
              <Route
                exact
                path="/evaluacion"
                render={(props) => (
                  <EvaluacionContainer {...props} />
                )}
              />
              <Route
                exact
                path="/resultado-test"
                render={(props) => (
                  <ResultadoTestContainer {...props} />
                )}
              />
              <Route
                exact
                path="/nota/:slug"
                render={(props) => (
                  <NotaContainer {...props} />
                )}
              />
              <Route
                exact
                path="/entrenamiento/:slug"
                render={(props) => (
                  <EntrenamientoContainer {...props} />
                )}
              />
              <Route
                exact
                path="/entrenamiento-medico/:slug"
                render={(props) => (
                  <HomeEntrenamiento {...props} />
                )}
              />
              <Route
                exact
                path="/mapa"
                render={(props) => <MapaContainer {...props} />}
              />
              <Route
                exact
                path="/herramientas/medi-tu-dependencia"
                render={(props) => <MediTuDependencia {...props} />}
              />
              <Route
                exact
                path="/herramientas/medi-tu-dependencia/results/:id"
                render={(props) => <MediTuDependenciaResults {...props} />}
              />
              <Route
                exact
                path="/herramientas/medi-tu-dependencia/patients"
                render={(props) => <MediTuDependenciaPatients {...props} />}
              />
              <Route
                exact
                path="/herramientas/medi-tu-dependencia/hcp"
                render={(props) => <MediTuDependenciaHcp {...props} />}
              />
              <Route
                exact
                path="/testimonios"
                render={(props) => <TestimoniosContainer {...props} />}
              />
              <Route
                exact
                path="/herramientas/plan-de-accion"
                render={(props) => (
                  <PlanDeAccionContainer {...props} />
                )}
              />
              <Route
                exact
                path="/herramientas/lineamientos-diagnostico"
                render={(props) => (
                  <Lineamientos {...props} />
                )}
              />
              <Route
                exact
                path="/herramientas/medidor-corticoides-sistemicos"
                render={(props) => (
                  <CalculadoraCorticoidesContainer {...props} />
                )}
              />
              <Route
                exact
                path="/herramientas/medidor-corticoides-sistemicos/conclusiones"
                render={(props) => (
                  <Concluciones {...props} />
                )}
              />
              <Route
                exact
                path="/diario-asmazero"
                render={(props) => <DiarioAsmaZeroContainer {...props} />}
              />
              <Route
                exact
                path="/educacion"
                render={(props) => (
                  <HomeEducacionContainer {...props} />
                )}
              />
              <Route
                exact
                path="/entrenamiento-medico"
                render={(props) => (
                  <EntrenamientoMenu {...props} />
                )}
              />
              <Route
                exact
                path="/educacion/:section"
                render={(props) => (
                  <EducacionContainer {...props} />
                )}
              />
              <Route
                exact
                path="/quienes-somos/:section"
                render={(props) => (
                  <CirculoAsmaZeroContainer {...props} />
                )}
              />
              <Route
                exact
                path="/testimonios/:name"
                render={(props) => (
                  <SingleTestimonioContainer {...props} />
                )}
              />
              <Route
                  exact
                  path="/exclusivo"
                  component={PageRedirect}
              />
              <Route path="*" component={PageError} />
            </Switch>
          </ScrollTop>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sections: state.sections,
  notes: state.notes,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchData()),
  fetchSections: () => dispatch(fetchSections()),
  fetchPeople: () => dispatch(fetchPeople()),
  storeAuthData: (data) => dispatch(storeAuthData(data)),
  storeAuthState: (state) => dispatch(storeAuthState(state)),
  receiveValidUser: (data) => dispatch(receiveValidUser(data)),
  logout: (state) => dispatch(logout(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

import React, { useEffect } from "react";
import NavbarInterna from "./NavbarInterna";
import RedesIconos from "./IconosRedesInterna";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { codigoPacientes } from "../codigos";
import Menu from './Menu';
import Button from './ButtonMenu';
import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL, URL } from '../store/constants'
import { tracking } from '../store/actions/user'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'



const HomeEducacion = (props) => {
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const resources = useSelector((state) => state.resourcesReducer.resources, shallowEqual);
  const sections = useSelector((state) => state.sections.sections, shallowEqual);
  const notes = useSelector((state) => state.notes.notes, shallowEqual)
  const selectedCountry = useSelector((state)=> state.country.selectedCountry);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  useEffect(() => {
    if (userState) {
      tracking(user['user_id'], '14', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/educacion")

  }, [])

  return (
    <div className={internaClass}>
      <Helmet>
        <title>Asma Zero - Educación</title>
        <meta name="description" content="Asma Zero - Educación" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna
            props={props}
          />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner-educacion">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            <Link
              style={{ textDecoration: "none", color: "#35aea9" }}
              to="/"
            >
              ASMA ZERO{" "}
            </Link>{" "}
                  /{" "}
             EDUCACIÓN</p>
          <h1 className="text-center">Educación</h1>
        </div>
      </main>
      <section className="container home-educacion">
        {sections.filter((section) => section['parent_id_section'] == '14' && section['status'] == '1' && section[selectedCountry.toLowerCase()] == '1').sort(function (a, b) {
          return (a['field_order'] - b['field_order'])
        }).map((section) => (
          <div>
            {section['slug'] == 'recursos-pacientes' ? (
              <div className="category">
                <div className="title-button">
                  <Link to="/educacion/recursos-pacientes">
                    <h1 className="category-title texto-verde">
                      RECURSOS PARA PACIENTES
                </h1>
                  </Link>
                </div>
                <div className="content-and-button">
                  <div className="desktop-button-mas button-mas-notas">
                    <Link to="/educacion/recursos-pacientes">
                      <button className="mas-notas">+ RECURSOS</button>
                    </Link>
                  </div>
                  {resources && (
                    <div
                      style={{ marginTop: "1rem" }}
                      className="row container-recurso"
                    >
                      {resources.filter(recurso => recurso.need_logged == "0" && recurso.status == 1 && recurso[selectedCountry.toLowerCase()] == '1').slice(0, 4).map((recurso) => (
                        <div
                          key={recurso.id_note_resource}
                          className="recurso col-12 col-xs-6 col-sm-6 col-md-3"
                        >
                          <img className="imagen-recurso" src={`${API_MATERIAL}/notes_resources/small/${recurso.photo}`} alt="" />
                          {recurso.url_canonical ? (
                            <div>
                              {recurso.url_canonical.length > 0 ? (
                                <a target="_blank" href={recurso.url_canonical} >
                                  <h1 className="title">{recurso.name}</h1>
                                </a>
                              ) : (
                                  <a
                                    download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                    target="_blank"
                                    href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`} >
                                    <h1 className="title">{recurso.name}</h1>
                                  </a>
                                )}
                            </div>
                          ) : (
                              <div>
                                <a
                                  download={`${API_MATERIAL}/notes_resources/media/${recurso.file}`}
                                  target="_blank"
                                  href={`${API_MATERIAL}/notes_resources/media/${recurso.file}`} >
                                  <h1 className="title">{recurso.name}</h1>
                                </a>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
                <div className="category">
                  <div className="title-button">
                    <Link to={`/educacion/${section['slug']}`}>
                      <h1 className="category-title texto-verde">
                        {section['name']}
                      </h1>
                    </Link>
                  </div>
                  <div className="content-and-button">
                    <div className="desktop-button-mas button-mas-notas">
                      <Link to={`/educacion/${section['slug']}`}>
                        <button className="mas-notas">+ NOTAS</button>
                      </Link>
                    </div>

                    <div className="row container-nota">
                      {notes.filter((note) => note['id_section'] == section['id_section'] && note[selectedCountry.toLowerCase()] == '1').slice(0, 4).map((nota) => (
                        <div className="col-md-3 nota">
                          <img
                            className="imagen-perfil-nota"
                            src={`${API_MATERIAL}/notes_authors/big/${nota.author["file"]}`}
                            alt=""
                          />
                          <div className="author-content">
                            <p className="name texto-verde">{nota.author.name}</p>
                            <Link
                              style={{ textDecoration: "none", color: "#10144b" }}
                              to={`/nota/${nota["slug"]}`}
                            >
                              <h1>{nota.name}</h1>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>
              )}
          </div>
        ))}
        <div className="category">
          <h1 className="texto-verde"></h1>
        </div>
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
};

export default HomeEducacion;

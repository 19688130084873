import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import NavbarInterna from "./NavbarInterna";
import RedesIconos from "./IconosRedesInterna";
import ShareButton from "./ShareButtonComponent";
import MenuEntrenamiento from "./MenuEntrenamiento";
import RecursosButton from './RecursosButton';
import SubMenuRecursos from './SubMenuRecursos'
import Recursos from './RecursosComponent'
import Footer from "./Footer";
import Menu from './Menu';
import Button from './ButtonMenu';
import { codigoMedicos } from "../codigos";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { receiveValidUser, receiveMatch } from '../store/actions/user'
import { readCookie } from '../components/Cookie'
import { API_MATERIAL, URL } from '../store/constants'
import { tracking, setUrlBack } from '../store/actions/user'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'




export default function Entrenamiento(props) {

  const resources = useSelector((state) => state.resourcesReducer.resources, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const match = props.props.match.params.slug;

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  const dispatch = useDispatch()

  let findResources = function (array) {
    let resources = array.slice(0, 4);
    return resources;
  };

  let filterNotesSection = function (array, note) {
    let arr = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].id_note !== note.id_note) {
        arr.push(array[i]);
      }
    }
    return arr;
  };


  const readGet = (o) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  }
  let getCode = readGet('code')


  useEffect(() => {
    let cookieToken = readCookie('azmed_user_token')
    if (cookieToken == null && getCode == null) {
      dispatch(receiveValidUser(true))
      dispatch(setUrlBack(`${URL}entrenamiento/${match}`))
      props.props.history.push("/")
    }
    if (userState) {
      tracking(user['user_id'], props.note['section']['id_section'], props.note['id_note'], user['name'], window.location.href)
    }
    ReactGa.pageview(`/entrenamiento/${match}`)
  }, [match])

  const [showNextNote, setShowNextNote] = useState(true);
  const handleTrueShowNextNote = () => setShowNextNote(true);

  const selectNextNote = () => {
    let indexNote = props.notesSection.indexOf(props.note);
    let nextNote = props.notesSection[indexNote + 1];
    if (nextNote) {
      return nextNote.slug;
    } else {
      setShowNextNote(false);
    }
  };

  const entrenamientoSection = useSelector((state) => state.sections.sections.filter((section) => section['parent_id_section'] == '36' && section['status'] == '1')).sort(function (a, b) {
    return (a['field_order'] - b['field_order'])
  })
  const allNotes = useSelector((state) => state.notes.notes);

  const generateArraySections = (array, property) => {
    let newArray = []
    array.map(section => {
      newArray.push(Number(section[property]))
    })
    return newArray
  }

  const idSectionsArray = generateArraySections(entrenamientoSection, 'id_section')

  const selectNextSection = () => {
    let nextSection = Number(props.note['id_section']) + 1
    while (!idSectionsArray.includes(nextSection)) {
      nextSection++
    }

    let nextNoteSection = allNotes.filter(note => note['id_section'] == nextSection)

    let nextSlug;
    if (nextNoteSection.length > 0) {
      nextSlug = '/entrenamiento/' + nextNoteSection[0]['slug']
    }
    if (nextSlug) {
      return nextSlug
    } else {
      return '/entrenamiento-medico/recursos-profesionales'
    }
  };



  return (
    <div className={internaClass}>
      <Helmet>
        <title>{props.note && `${props.note.section['name']} - ${props.note.name}`}</title>
        <meta name="description" content={props.note && props.note.copete} />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna
            props={props}
          />
        </div>
        <Menu />
      </header>
      <Button />
      {props.note.resources.length > 0 && (
        <RecursosButton
          entrenamiento={true}
        />
      )}

      <RedesIconos />
      <section id="nota" className="nota">
        <div className="container">
          {props.note && (
            <div className="div">
              <p className="miga-de-pan seccion text-center texto-verde">
                <Link
                  style={{ textDecoration: "none", color: "#35aea9" }}
                  to="/"
                >
                  ASMA ZERO{" "}
                </Link>{" "}
                /{" "}
                <Link to="/entrenamiento-medico" style={{ textDecoration: "none", color: "#35aea9" }}>
                  ENTRENAMIENTO MÉDICO{" "}
                </Link>
                /{" "}
                <Link
                  style={{ textDecoration: 'none', color: '#35aea9' }}
                  to={`/entrenamiento-medico/${props['note']['section']['slug']}`}>
                  {props.titleSection.title}{' '}
                </Link>
              </p>
            </div>
          )}
          <div className="row">
            {props.note && (
              <div className="nota-title col-md-12">
                <h1 className="title-nota text-center mb-5">
                  {props.note.name}
                </h1>
              </div>
            )}
            {props.note && (
              <div className="article col-md-9">
                <article>
                  {props.note.author !== null && (
                    <div className="author-detail-container">
                      {" "}
                      <img
                        className="imagen-perfil-nota"
                        src={
                          !props.note.author
                            ? "/img/user-doctor.png"
                            : `${API_MATERIAL}/notes_authors/big/${props.note.author["file"]}`
                        }
                      ></img>
                      <div className="perfil-detail">
                        <strong className="name-autor">
                          {props.note.author.name}
                        </strong>
                        <br></br> {ReactHtmlParser(props.note.author.detail)}
                        <br></br>
                      </div>
                      <div href="#" className="compartir-nota">
                        <ShareButton
                          id="button-compartir"
                          body={'Para ver la nota haga clic en la siguiente URL '}
                          url={`${URL}entrenamiento/${props.note["slug"]}`}
                          quote={props.note.name}
                          email={true}
                        />
                      </div>
                    </div>
                  )}
                  <hr></hr>
                  <div className="entrenamiento-nota row">
                    <div className="entrenamiento-copete offset-md-2 col-md-10">
                      <p className="copete" >{ReactHtmlParser(props.note.copete)} </p>
                      <div className="nota-video">
                        {ReactHtmlParser(props.note.video)}
                      </div>
                      <div className="entrenamiento-detail">
                        {ReactHtmlParser(props.note.detail)}
                      </div>
                    </div>
                  </div>
                </article>
                {showNextNote ? (
                  <div className="proxima-nota-button">
                    <Link to={`/entrenamiento/${selectNextNote()}`}>
                      <button>Próxima Nota</button>
                    </Link>
                  </div>
                ) : (
                    <div className="div">
                      {props.note.id_section != "24" && (
                        <div className="proxima-nota-button">
                          <Link
                            onClick={handleTrueShowNextNote}
                            to={selectNextSection()}
                          >
                            <button>Próxima Sección</button>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
            <div className="col-md-3 sidebar">

              {props.note && (
                <div>
                  {props.note.resources.length > 0 && (
                    <Recursos
                      slug={"entrenamiento-medico/recursos-para-profesionales"}
                      resources={props.note.resources}
                    />
                  )}
                </div>
              )}


              {props.note && (
                <div>
                  {props.notesSection.length > 1 && (
                    <div>
                      <Link style={{
                        color: "#840729",
                      }} to={`/entrenamiento-medico/${props.notesSection[0]['section']['slug']}`} >
                        <h4>EN ESTA SECCION</h4>
                      </Link>
                    </div>
                  )}
                  <div className="mas-notas">
                    {props.note &&
                      filterNotesSection(props.notesSection, props.note).map(
                        (note) => (
                          <div className="note">
                            <Link
                              style={{
                                color: "#10144b",
                              }}
                              to={`/entrenamiento/${note["slug"]}`}
                            >
                              <h1>{note.name}</h1>
                            </Link>
                            {/* <p className="texto-bordo">{note.author.name}</p> */}
                          </div>
                        )
                      )}
                  </div>
                </div>
              )}

              {props.notesSection.length > 0 && (
                <MenuEntrenamiento notesSection={props.notesSection} />
              )}


            </div>
          </div>
        </div>
        <SubMenuRecursos
          resources={props.note.resources}
          background={"#9C9D9D"}
        />
      </section>
      <Footer codigo={codigoMedicos} />
    </div>
  );
}

import React from "react";
import { connect } from "react-redux";
import Entrenamiento from "../components/Entrenamiento";

class EntrenamientoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let selectNote = function (array, params) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].slug === params) {
          return array[i];
        }
      }
    };

    let filterSection = function (array, id) {
      let arr = [];

      for (let i = 0; i < array.length; i++) {
        if (array[i]["id_section"] == id) {
          arr.push(array[i]);
        }
      }
      return arr;
    };

    let selectTitleSection = function (id) {
      let params = {
        title: "",
        slug: ""
      };
      if (id == 38) {
        params.title = "Rescate Anti-Inflamatorio & Mantenimiento";
        params.slug = "rescate-y-mantenimiento";
        return params;
      } else if (id == 39) {
        params.title = "Simplificar el Tratamiento del Asma";
        params.slug = "simplificar-tratamiento-asma";
        return params;
      } else if (id == 37) {
        params.title = "Transición SABA a Rescate Anti-Inflamatorio";
        params.slug = "saba-transicion";
        return params;
      } else if (id == 40) {
        params.title = "Manejo de Crisis Asmática";
        params.slug = "manejo-de-crisis-asmatica";
        return params;
      } else if (id == 41) {
        params.title = "Material Para Educar Pacientes";
        params.slug = "educando-pacientes";
        return params;
      }
    };

    let selectIdSection = function (note) {
      if (note) {
        return note.id_section;
      }
    };

    let note = selectNote(this.props.notes.notes, this.props.match.params.slug);
    let idSection = selectIdSection(note);
    let titleSection = selectTitleSection(idSection);
    let notesSection = filterSection(this.props.notes.notes, idSection);

    return (
      <Entrenamiento
        note={note}
        notesSection={notesSection}
        titleSection={titleSection}
        props={this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  notes: state.notes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, null)(EntrenamientoContainer);

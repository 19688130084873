import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'

export default function Perfil(props) {
    const user = useSelector((state) => state.user.authData)
    const userState = useSelector((state) => state.user.authState);


    /*
    city: "CABA"
dob: ""
email: "facumlafuente@gmail.com"
email_verified: 1
externalId: "N1234"
family_name: "Lafuente"
gender: "prefer not to answer"
given_name: "Facundo"
name: "Facundo Lafuente"
partner: "JANRAIN"
partnerId: "8d7fb888-1c10-41f1-9685-b8f7ae9d088d"
phone_number: null
postalCode: ""
preferred_username: "Facundo.Lafuente"
state: "Buenos Aires"
street1: ""
sub: "8d7fb888-1c10-41f1-9685-b8f7ae9d088d"
user_id: "8d7fb888-1c10-41f1-9685-b8f7ae9d088d"
    */

    if (userState) {
        return (
            <Modal
                keyboard
                enforceFocus
                aria-labelledby="contained-modal-title-vcenter"
                centered
                {...props}

            >

                <Modal.Body className="ingresar-modal" >
                    <div className="container">
                        <p className="texto-verde">ASMA ZERO / PERFIL</p>
                        <h1>Perfil</h1>
                        <label htmlFor="name">
                            Nombre:
                        <input
                                type="text"
                                id="name"
                                name="nombre"
                                value={user.given_name}
                            />
                        </label>
                        <label htmlFor="apellido">
                            Apellido:
                        <input
                                type="text"
                                id="apellido"
                                name="apellido"
                                value={user.family_name}
                            />
                        </label>
                        <label htmlFor="email">
                            E-mail:
                        <input
                                name="email"
                                type="email"
                                id="email"
                                value={user.email}
                            />
                        </label>
                        <label htmlFor="matricula">
                            Matrícula: MN1234 o MP1234
                        <input
                                type="text"
                                id="matricula"
                                name="matricula"
                                value={user.externalId}
                            />
                        </label>
                        <label htmlFor="ciudad">
                            Ciudad:
                        <input
                                type="text"
                                id="ciudad"
                                name="ciudad"
                                value={user.state}
                            />
                        </label>
                    </div>
                </Modal.Body>
            </Modal>
        )
    } else {
        return (
            <Modal></Modal>
        )
    }
}
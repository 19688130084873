import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {useDispatch, useSelector} from 'react-redux';
import ModalIngresar from './ModalIngresar';
import {receiveValidUser} from '../store/actions';
import {AUTH_URL, URL, AUTH_URL_REGISTER, HOME} from '../store/constants';

export default function ModalValidar(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const urlBack = useSelector((state) => state.user.urlBack);

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(receiveValidUser(false));
    window.location = URL;
    // console.log('redirecto to: ', AUTH_URL);
  };

  const URL_TO_LOGIN = AUTH_URL + encodeURIComponent(urlBack);
  const URL_REGISTER = AUTH_URL_REGISTER + encodeURIComponent(urlBack);

  const singin = () => {
    window.location = URL_TO_LOGIN;
    // console.log('redirecto to: ', AUTH_URL);
  };

  const singUp = () => {
    window.location = URL_REGISTER;
    // console.log('redirecto to: ', AUTH_URL_REGISTER);
  };

  return (
    <div>
      <Modal
        keyboard
        enforceFocus
        aria-labelledby="contained-modal-title-vcenter"
        centered
        {...props}
        className="modal-validar">
        {/* <Modal.Header className="header" closeButton >
                    <h4>La sección que estás intentando acceder es solo para <br></br> <strong>Profesionales de la salud</strong></h4>
                </Modal.Header> */}
        <Modal.Body className="ingresar-modal modal-body">
          <img src="/img/candado.svg" alt="" />
          <div className="container">
            <p className="miga-de-pan texto-verde">
              ASMA ZERO / CONTENIDO EXCLUSIVO
            </p>
            <h1>
              LA SECCIÓN QUE ESTAS INTENTANDO ACCEDER ES SOLO PARA<br></br>{' '}
              <strong>PROFESIONALES DE LA SALUD</strong>
            </h1>
            <p>
              Si sos un <strong>Profesional de la Salud,</strong> ingresá con tu
              cuenta de AZMED:
            </p>
            <div className="container-buttons">
              <button
                type="submit"
                className="ingresar"
                onClick={() => singin()}>
                INGRESAR
              </button>
              <button
                type="submit"
                className="registrar"
                onClick={() => singUp()}>
                REGISTRARME
              </button>
            </div>
            {/* <button onClick={() => gotoLogin()}>Iniciar Sesión</button> */}
            <p>No sos profesional de la Salud? Continuá navegando desde:</p>
            <a onClick={() => closeModal()} href={HOME}>
              www.AsmaZero.com.ar
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <ModalIngresar show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

import React from "react";
import HomeEducacion from "../components/HomeEducacion";
import { connect } from "react-redux";

class HomeEducacionContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <HomeEducacion
        props={this.props}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(null, null)(HomeEducacionContainer);

import React from "react";
import { connect } from "react-redux";
import Contacto from "../components/Contacto";

class ContactoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Contacto
          props={this.props}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(null, null)(ContactoContainer);

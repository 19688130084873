import {
    SET_MENU_CLASS
} from '../constants'

export const setMenuClass = data => ({
    type: SET_MENU_CLASS,
    data
})

export const closeSidebar = () => dispatch => {
    console.log('closeSidebar')
    dispatch(setMenuClass({
        homeClass: 'home',
        internaClass: 'interna',
        overlayClass: 'overlay',
        buttonTogglerClass: 'navbar-toggler',
        menuClass: 'menu-principal',
        subMenuEducacion: 'sub-menu-educacion',
        subMenuHerramientas: 'sub-menu-herramientas',
        subMenuQuienesSomos: 'sub-menu-qs',
        subMenuDiario: 'sub-menu-diario',
        subMenuEntrenamiento: 'sub-menu-entrenamiento',
        subMenuRecursos: 'sub-menu-recursos',
        subMenuAsmaE: 'sub-menu-asma-e '

    }))
}

export const toggleNavigation = (homeClass, internaClass) => dispatch => {
    //console.log('toggleNavigation', homeClass, internaClass)
    if (
        homeClass === 'home' ||
        internaClass === 'interna'
    ) {
        dispatch(setMenuClass({
            homeClass: 'nav-open',
            internaClass: 'interna nav-open',
            overlayClass: 'overlay active',
            buttonTogglerClass: 'navbar-toggler open',
        }))
    } else {
        dispatch(setMenuClass({
            homeClass: 'home',
            internaClass: 'interna',
            overlayClass: 'overlay',
            buttonTogglerClass: 'navbar-toggler',
            subMenuEducacion: 'sub-menu-educacion',
            subMenuHerramientas: 'sub-menu-herramientas',
            subMenuQuienesSomos: 'sub-menu-qs',
            subMenuDiario: 'sub-menu-diario',
            subMenuEntrenamiento: 'sub-menu-entrenamiento',
            subMenuAsmaE: 'sub-menu-asma-e'
        }))
    }
}

export const toggleMenuQs = (subMenuQuienesSomos) => dispatch => {
    if (subMenuQuienesSomos === 'sub-menu-qs') {
        dispatch(setMenuClass({
            subMenuQuienesSomos: 'sub-menu-qs-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuQuienesSomos: 'sub-menu-qs'
        }))
    }
}

export const toggleMenuHerramientas = (subMenuHerramientas) => dispatch => {
    if (subMenuHerramientas === 'sub-menu-herramientas') {
        dispatch(setMenuClass({
            subMenuHerramientas: 'sub-menu-herramientas-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuHerramientas: 'sub-menu-herramientas'
        }))
    }
}

export const toggleMenuEducacion = (subMenuEducacion) => dispatch => {
    console.log('toggleMenuEducacion')

    if (subMenuEducacion === 'sub-menu-educacion') {
        dispatch(setMenuClass({
            subMenuEducacion: 'sub-menu-educacion-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuEducacion: 'sub-menu-educacion'
        }))
    }
}

export const toggleMenuDiario = (subMenuDiario) => dispatch => {
    if (subMenuDiario === 'sub-menu-diario') {
        dispatch(setMenuClass({
            subMenuDiario: 'sub-menu-diario-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuDiario: 'sub-menu-diario'
        }))
    }
}

export const toggleMenuEntrenamiento = (subMenuEntrenamiento) => dispatch => {
    if (subMenuEntrenamiento === 'sub-menu-entrenamiento') {
        dispatch(setMenuClass({
            subMenuEntrenamiento: 'sub-menu-entrenamiento-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuEntrenamiento: 'sub-menu-entrenamiento'
        }))
    }
}

export const toggleMenuRecursos = (subMenuRecursos) => dispatch => {
    if (subMenuRecursos === 'sub-menu-recursos') {
        dispatch(setMenuClass({
            subMenuRecursos: 'sub-menu-recursos-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuRecursos: 'sub-menu-recursos'
        }))
    }
}

export const toggleMenuAsmaE = (subMenuAsmaE) => dispatch =>{
    if (subMenuAsmaE === 'sub-menu-asma-e') {
        dispatch(setMenuClass({
            subMenuAsmaE: 'sub-menu-asma-e-open'
        }))
    } else {
        dispatch(setMenuClass({
            subMenuAsmaE: 'sub-menu-asma-e'
        }))
    }
}
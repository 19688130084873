import React, {useState, useEffect} from 'react';
import MapaComponent from './MapaComponent';
import {useSelector, shallowEqual} from 'react-redux';
import FiltrosMapa from './FiltrosMapa';
import {Link} from 'react-router-dom';
import _ from 'lodash';

const parseLatLng = (value, fixed = 5) =>
  Number.parseFloat(value).toFixed(fixed);
const generateRegion = ({latitude, longitude}) => ({
  center: {
    lat: latitude,
    lng: longitude,
  },
  bounds: {
    ne: {lat: latitude - 0.025, lng: longitude - 0.06},
    nw: {lat: latitude - 0.025, lng: +longitude + +0.06},
    se: {lat: +latitude + +0.025, lng: longitude - 0.06},
    sw: {lat: +latitude + +0.025, lng: +longitude + +0.06},
  },
});


export default function MapaHome(props) {
  const [filter, setFilter] = useState(false);
  const selectedCountry = useSelector((state)=>state.country.selectedCountry,shallowEqual)
  const country = useSelector((state)=>state.country.country,shallowEqual)
  const [markerSelect, setMarkerSelect] = useState(false);
  const [search, setSearch] = useState(false);
  const [regionCountry, setRegionCountry] = useState({
    latitude: 4.670854285606273,
    longitude: -74.09740264696222
  });
  const [region, setRegion] = useState(
    generateRegion(regionCountry),
  );
  if (filter === 'false') setFilter(false);
  // console.log('Mapa Home - Region - ', region);

  useEffect(()=>{
    if( (selectedCountry != "") || (selectedCountry != null) ){
      switch (selectedCountry) {
        case "CO":
          setRegion({...region,
            center:{
            lat: 4.670854285606273,
            lng: -74.09740264696222}
          })
          break;
        case "EC":
          setRegion({...region,
            center:{
              lat:-0.24082119357330267,
              lng: -78.49910341086805
            }
          })
          break;
        case "PE":
          setRegion({...region,
            center:{
              lat:-12.0780783741817,
              lng: -77.029469791063
            }
          })
          break;
      }
    }
  },[selectedCountry])

  let {maps} = useSelector(({maps}) => maps, shallowEqual);

  let markers = maps ? maps : []

  /* let markers = [] */,
    bounds = region.bounds,
    boundLat = [bounds.ne.lat, bounds.nw.lat, bounds.se.lat, bounds.sw.lat],
    boundLng = [bounds.ne.lng, bounds.nw.lng, bounds.se.lng, bounds.sw.lng],
    maxLat = _.max(boundLat),
    minLat = _.min(boundLat),
    maxLng = _.max(boundLng),
    minLng = _.min(boundLng);
  // Filter by Category
  markers =
    filter !== false && markers.length > 0
      ? markers.filter(
          ({id_map_category}) => id_map_category === String(filter),
        )
      : markers;
  // Search
  let markersResult =
    search.length > 1 && markers.length > 0
      ? markers.filter(
          ({name, address, province}) =>
            name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            (address &&
              address.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            (province &&
              province.toLowerCase().indexOf(search.toLowerCase()) !== -1),
        )
      : markers;
  // Filter by view
  // markers =
  //   !!search === false && markers.length > 0
  //     ? markers.filter((m) => {
  //       let lat = parseLatLng(m.latitude);
  //       let lng = parseLatLng(m.length);
  //       return minLat < lat && lat < maxLat && minLng < lng && lng < maxLng;
  //     })
  //     : markers;
  // markers = markers.length > 200 ? markers.slice(0, 200) : markers;
  // markersResult =
  //   markersResult.length > 40 ? markersResult.slice(0, 40) : markersResult;
  const geolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setRegion({
          ...region,
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    }
  };
  // console.log('Mapa Home - markers', markers.length);
  // console.log('Mapa Home - markersResult', markersResult.length);
  return (
    <>
      <section id="home-mapa">
        <div className="header-map">
          {/* <p className="texto-verde">ENCONTRÁ UN ESPECIALISTA CERCA TUYO</p>
          <Link
            style={{
              textDecoration: 'none',
              color: '#10144b',
            }}
            to="/mapa"
            className="link-azul">
          </Link> */}
            <h1>Mapa Asma Zero</h1>
        </div>
        <FiltrosMapa
          /* filter={filter}
          setFilter={setFilter} */
         /*  search={search}
          setSearch={setSearch} */
          setRegion={setRegion}
          generateRegion={generateRegion}
          geolocation={() => geolocation()}
        />
      </section>
      <div style={{height: '600px', width: '100%'}} className="map-component">
        <MapaComponent
          region={region}
          setRegion={setRegion}
          markers={markers}
          markerSelect={markerSelect}
          setMarkerSelect={setMarkerSelect}
        />
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import RedesIconos from './IconosRedesInterna';
import NavbarInterna from './NavbarInterna';
import LineamientosPregunta from './LineamientosPregunta';
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import LineamientosCuadro from './LineamientosCuadro';
import LineamientosResultado from './LineamientosResultado';
import { readCookie } from '../components/Cookie'
import { receiveValidUser, tracking, setUrlBack } from '../store/actions/user'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { URL } from '../store/constants'
import Helmet from 'react-helmet'
import ReactGa from 'react-ga'


const questions = {
  0: {
    question: '¿El paciente cuenta con Espirometría?',
    option: ['Si', 'No'],
    next: 1,
  },
  1: {
    question:
      '¿Presenta el paciente los síntomas típico de asma? disnea, sibilancias, opresión torácica, tos',
    option: ['Si', 'No'],
    next: {
      0: 2,
      1: 3,
    },
  },
  2: {
    question: '¿Tiene diagnóstico previo de asma?',
    option: ['Si', 'No'],
    next: {
      0: 3,
      1: 5,
    },
  },
  3: {
    question: '¿Utiliza tratamiento para el asma?',
    option: ['Si', 'No'],
    next: {
      0: 4,
      1: 'severity',
    },
  },
  4: {
    question: '¿Utiliza tratamiento para el asma?',
    option: ['¿ICS SOLO?', '¿ICS+LABA? ', '¿SOLO SABA A DEMANDA U OTROS?'],
    next: {
      0: { result: [2, 3, 6, 5] },
      1: { result: [2, 3, 6, 5] },
      2: 'severity',
    },
  },
  5: {
    question:
      'Evaluar antecedentes personales y/o familiares vinculados al asma',
    option: ['Si', 'No'],
    next: {
      0: 'severity',
      1: 'severity',
    },
  },
  6: {
    question:
      'Espirometría con evaluación de respuesta broncodilatadora (BD) Realizar entre los 15 a 30 min luego de la administración de 400μg de salbutamol (4 puff)',
    option: [
      'PATRÓN NO OBSTRUCTIVO VEF1 /CVF ≥ 0.7',
      'PATRÓN NORMAL ',
      'PATRÓN OBSTRUCTIVO VEF1 /CVF ˂ 0.7',
    ],
    next: {
      0: { result: [0] },
      1: { result: [0, 4, 5] },
      2: 7,
    },
  },
  7: {
    question: 'Patrón obstructivo VEF1 /CVF ˂ 0.7',
    option: [
      'RESPUESTA BD POSITIVA Δ VEF1 ≥ 12 % Y 200 ML',
      'RESPUESTA BD NEGATIVA Δ VEF1 ˂ 12 %',
    ],
    next: {
      0: { result: [4, 5] },
      1: { result: [0] },
    },
  },
  result: [
    {
      // 0
      icon: 'mapa',
      title: 'Evaluar otros diagnosticos o derivar al especialista',
      url: '/mapa',
    },
    {
      // 1
      icon: 'estetoscopio',
      title: 'Evaluar severidad',
      url: '/nota/que-hacer-crisis-asmatica',
    },
    {
      // 2
      icon: 'estetoscopio',
      title: 'Evaluar nivel de control',
      url: '/evaluacion',
    },
    {
      // 3
      icon: 'planilla',
      title: 'Evaluar adherencia de tratamiento',
      url: '/entrenamiento/la-consulta',
    },
    {
      // 4
      icon: 'tratamiento',
      title: 'Iniciar tratamiento controlador',
      url: '/entrenamiento/como-iniciar-tratamiento',
    },
    {
      // 5
      icon: 'plan',
      title: 'Realizar plan de acción',
      url: '/herramientas/plan-de-accion',
    },
    {
      // 6
      icon: 'asma',
      title: 'Evaluar maniobra inhalatoria',
      url: '/nota/tipos-de-tratamientos',
    },
    {
      // 7
      title: 'Analizar espirometría',
      url: '/nota/espirometria',
      icon: 'espirometria'
    },
  ],
};

export default function Lineamientos(props) {
  const user = useSelector((state) => state.user.authData, shallowEqual);

  const [step, setStep] = useState(0);
  const [firstAnswer, setFirstAnswer] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [result, setResult] = useState(null);
  const dispatch = useDispatch()

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)

  let question = questions[step];

  console.log('Lineamientos -- ', {
    step,
    firstAnswer,
    answer,
    result,
    question,
  });
  const nextStep = () => {
    console.log('nextStep');
    if (step === 'severity') {
      if (firstAnswer === 1) return setResult([4, 5, 7]);
      else {
        setStep(6);
        return setAnswer(null);
      }
    }
    if (answer === null) return alert('Necesitas escoger una respuesta.');
    if (Number.isInteger(question.next)) {
      if (step === 0) setFirstAnswer(answer);
      setStep(question.next);
      return setAnswer(null);
    }
    if (!question.next[answer]) return alert('No se encuentra paso a seguir.');
    if (question.next[answer].hasOwnProperty('result'))
      setResult(question.next[answer].result);
    else {
      setStep(question.next[answer]);
      setAnswer(null);
    }
  };

  // VALIDO QUE ESTE REGISTRADO

  const readGet = (o) => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
          window.location.href,
        ) || [null, ''])[1].replace(/\+/g, '%20'),
      ) || null
    );
  }
  let getCode = readGet('code')


  useEffect(() => {
    let cookieToken = readCookie('azmed_user_token')
    // console.log(cookieToken)
    if (cookieToken == null && getCode == null) {
      dispatch(receiveValidUser(true))
      dispatch(setUrlBack(`${URL}herramientas/lineamientos-diagnostico`))
      props.history.push("/")
    }
    if (user) {
      tracking(user['user_id'], '46', '', user['name'], window.location.href)
    }
    ReactGa.pageview("/herramientas/lineamientos-diagnostico")

  }, [])

  return (
    <div className={internaClass}>
      <Helmet>
        <title>Lineamientos de Diagnóstico</title>
        <meta name="description" content="Lineamientos de Diagnóstic" />
      </Helmet>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna
            props={props}
          />
        </div>
        <Menu />
      </header>
      <Button />

      <RedesIconos />

      <main id="interna-banner" className="margin-bottom-7">
        <div className="container">
          <p className="miga-de-pan seccion text-center">
            HERRAMIENTAS / LINEAMIENTOS DE DIAGNÓSTICO
          </p>
          <h1 className="text-center">Lineamientos de diagnóstico</h1>
          <p className="descripcion">
            Esta no es una herramienta de diagnóstico, sino lineamientos de
            orientación basados en GINA (http://www.ginasthma.org/) y GEMA
            (https://www.gemasma.com/). Siempre se debe consultar a un médico
            para obtener un diagnóstico o un nivel de control certero sobre la
            enfermedad.
          </p>
        </div>
      </main>

      <section>
        <div className="container">
          {result === null ? (
            <>
              <div className="lineamientos-container row">
                <div className="texto-bordo col-md-4">
                  <h3>Responda estas preguntas con su paciente</h3>
                  <img src="/img/frase-linea-bordo.png" alt="" />
                </div>
                {step !== 'severity' && (
                  <div className="col-md-8 preguntas-container">
                    <LineamientosPregunta
                      pregunta={question.question}
                      buttons={question.option}
                      setAnswer={setAnswer}
                      answer={answer}
                    />
                  </div>
                )}
              </div>
              {step === 'severity' && (
                <div className="container-cuadro">
                  <LineamientosCuadro />
                </div>
              )}
              <div className="row button-container">
                <div className="col-md-4"></div>
                <div className="col-md-8 lineamientos-button">
                  <button onClick={() => nextStep()}>CONTINUAR</button>
                </div>
              </div>
            </>
          ) : (
              <div className="lineamientos-container">
                <LineamientosResultado
                  resultadoTexto={'Siguientes pasos'}
                  resultados={questions.result.filter((item, index) =>
                    result.includes(index),
                  )}
                />
              </div>
            )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

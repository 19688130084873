import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import ShareButton from './ShareButtonComponent';
import MenuEducacion from './MenuEducacion';
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import YouTube from 'react-youtube-embed';
import Recursos from './RecursosComponent';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { API_MATERIAL, URL, HREF } from '../store/constants'
import { tracking, receiveValidUser, setUrlBack } from '../store/actions/user'
import Helmet from "react-helmet";
import ReactGa from 'react-ga'
import { readCookie } from '../components/Cookie';






export default function Concluciones(props) {
    const userState = useSelector((state) => state.user.authState, shallowEqual);
    const user = useSelector((state) => state.user.authData, shallowEqual);

    const dispatch = useDispatch();


    const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
    const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)


    const readGet = (o) => {
        return (
            decodeURIComponent(
                (new RegExp('[?|&]' + o + '=([^&;]+?)(&|#|;|$)').exec(
                    window.location.href,
                ) || [null, ''])[1].replace(/\+/g, '%20'),
            ) || null
        );
    };
    let getCode = readGet('code');

    const validUser = () => {
        let cookieToken = readCookie('azmed_user_token');
        // console.log(cookieToken)
        if (cookieToken == null && getCode == null) {
            dispatch(receiveValidUser(true));
            dispatch(setUrlBack(`${URL}herramientas/medidor-corticoides-sistemicos`));
            props.history.push('/');
        }
    };

    useEffect(() => {
        // validUser();
        if (user) {
            tracking(user['user_id'], '56', '', user['name'], window.location.href);
        }

    }, [])




    return (
        <div className={internaClass}>
            <Helmet>
                <title>Medidor de riesgos asociado a los Corticoides Sistémicos</title>
                <meta name="description" content="Medidor de riesgos asociado a los Corticoides Sistémicos" />
            </Helmet>
            <div className={overlayClass}></div>
            <header>
                <div id="header-main" className="margin-bottom-7">
                    <NavbarInterna props={props} />
                </div>
                <Menu />
            </header>
            <Button />


            <RedesIconos />
            <section id="nota" className="nota concluciones-container">
                <div className="container">

                    <div style={{ paddingRight: '1rem' }} className="div">

                        <p className="miga-de-pan seccion text-center texto-verde">
                            <Link
                                style={{ textDecoration: 'none', color: '#35aea9' }}
                                to="/">
                                HERRAMIENTAS{' '}
                            </Link>{' '}
                  /{' '}
                            <Link
                                style={{ textDecoration: 'none', color: '#35aea9', textTransform: 'uppercase' }}
                                to="/diario-asmazero">
                                Medidor de riesgos asociado a los Corticoides Sistémicos{' '}
                            </Link>
                        </p>

                    </div>


                    <div className="row">

                        <div className="nota-title col-md-12">
                            <h1 className="title-nota text-center ">Medidor de riesgos asociado a los Corticoides Sistémicos</h1>
                        </div>


                        <div className="article col-md-9">
                            <article>
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4 className="titulo texto-bordo mb-4">
                                            ¿Estamos realizando un uso correcto de los CS en asma?
              </h4>
                                        <img src="/img/frase-linea-bordo.png" className="w-100"></img>
                                    </div>
                                </div>
                                <div className="container-text">
                                    <p>
                                        Los cursos cortos de corticoides sistémicos (CS) son muy efectivos para la resolución de los síntomas agudos de asma y para el manejo de las exacerbaciones; sin embargo, los beneficios deben ser equilibrados con el impacto de sus efectos secundarios, por lo cual resulta primordial evitar su uso indiscriminado. <strong>
                                        Si bien los efectos adversos de los CS usados a largo plazo son ampliamente reconocidos, parece haber una percepción en la comunidad médica de que su uso en ciclos cortos carece de efectos secundarios.
                                            </strong>  
                                    </p>

                                   <div className="estategias-container">
                                   <div className="row">
                                    <div className="col-md-9">
                                        <h5 className="titulo texto-bordo mb-4">
                                            Por ello es necesario implementar estrategias para:
              </h5>
                                        <img src="/img/frase-linea-bordo.png" className="w-100"></img>
                                    </div>
                                </div>
                                <div className="container-list">
                                    <ul>
                                        <li>Identificar a los pacientes en riesgo de sobrexposición </li>
                                        <li>Concientizar sobre riesgos inmediatos y acumulativos del empleo de CS</li>
                                        <li>Promover el diagnóstico y manejo de las comorbilidades relacionada a su uso</li>
                                        <li>Instaurar medidas de tratamiento consideradas “ahorradoras de esteroides”</li>
                                    </ul>
                                </div>
                                   </div>
                                    <p>
                                        Cada vez es mayor la evidencia que muestra que los cursos cortos de CS se asocian con un mayor riesgo de eventos adversos (metabólicos, oculares, cardiovasculares, infecciosos, de desarrollo, etc.) independientemente de la severidad del asma.  Por su rápida acción y fácil acceso (bajo costo y posibilidad de adquirirlo sin prescripción) los CS se utilizan como medicación de rescate, ya sea indicado por los médicos y/o en gran parte auto-administrado por los pacientes; no solo para las exacerbaciones o los síntomas graves, sino también moderados e incluso leves. La prescripción excesiva de CS es un indicador de mal control del asma que debemos aprender a reconocer.
                                    </p>
                                    <p>
                                        Además de los riesgos para la salud de los ciclos cortos de CS, debe tenerse en cuenta el impacto económico que el manejo de las comorbilidades y/o complicaciones relacionadas con su consumo ocasionan en el sistema de salud.
                                    </p>
                                    <p>
                                       
                                    Si bien los beneficios de los cursos cortos de CS superan los riesgos en los pacientes con asma aguda, es importante evitar el uso inadecuado de estos tratamientos. Lograr este equilibrio entre eficacia y seguridad es primordial; de lo contrario, los pacientes con asma soportan no solo la carga de la enfermedad, sino también la de las comorbilidades relacionadas con los esteroides
                                          
                                    </p>
                                </div>
                            </article>

                        


                        </div>

                        <div className="col-md-3 sidebar">

                            <div className="container-image">
                                <img src="/img/foto-conclusion.png" alt="" />
                            </div>

                        </div>
                        <div className="col-md-12">
                            <div className="bibliografia">
                                <h6>Bibliografía</h6>
                                <p>
                                    1) Bloechliger M, Reinau D, Spoendlin J, et al. Adverse events profile of oral corticosteroids among asthma patients in the UK: cohort study with a nested case-control analysis. Respir Res. 2018;19(1):75.
                                    <br />
                                    2) Price DB, Trudo F, Voorham J, et al. Adverse outcomes from initiation of systemic corticosteroids for asthma: long-term observational study. J Asthma Allergy. 2018;11:193-204.
                                    <br />
                                    3) Sullivan PW, Ghushchyan VH, Globe G, Schatz M. Oral corticosteroid exposure and adverse effects in asthmatic patients. J Allergy Clin Immunol. 2018;141(1):110-116.
                                    <br />
                                    4) Al Efraij K, Johnson KM, Wiebe D, Sadatsafavi M, FitzGerald JM. A systematic review of the adverse events and economic impact associated with oral corticosteroids in asthma. J Asthma. 2019;56(12):1334-1346.
                                    <br />
                                    5) Voorham J, Xu X, Price DB, Golam S, et al. Healthcare resource utilization and costs associated with incremental systemic corticosteroid exposure in asthma. Allergy. 2019;74(2):273-283.
                                    <br />
                                    6) Price D, Castro M, Bourdin A, et al. Short-course systemic corticosteroids in asthma: striking the balance between efficacy and safety. Eur Respir Rev 2020; 29: 190151

                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
                        <div className="col-md-12 fondo-verde container-volver-calculadora">
                            <Link to="/herramientas/medidor-corticoides-sistemicos" >
                                <button>VOLVER A LA CALCULADORA</button>
                            </Link>
                        </div>
            <Footer fondoAzul={true} codigo={codigoPacientes} />
        </div>
    );
}

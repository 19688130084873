// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f373a7e3-3d1c-49e0-970f-32f2bb398adf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EBjjRzx2F",
    "aws_user_pools_web_client_id": "6l2rnkv0t4qb4a9s6kr0dh0pcl",
    "oauth": {}
};


export default awsmobile;

import React from 'react'

export default function IconoTratamiento() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 100 100"
        >
            <path
                id="Trazado_1"
                d="M77.1,27.4c-1.3-4.5-4.9-8-9.4-9.2c-0.2-0.1-0.4-0.2-0.6-0.3c-1-0.2-1.9-0.3-2.9-0.3H23.9  c-0.8,0-1.5,0.7-1.5,1.5V81c0,0.8,0.7,1.5,1.5,1.5h42.9c0.8,0,1.5-0.7,1.5-1.5V44C75.3,41.8,79.2,34.4,77.1,27.4L77.1,27.4z   M51,28.2c-1.7,7.3,2.9,14.5,10.2,16.2c1,0.2,2,0.3,3,0.3c0.4,0,0.7,0,1.1,0l0,34.8H25.4V20.6h30.4C53.3,22.5,51.6,25.1,51,28.2  L51,28.2z M66.8,41.5c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.2,0.2-1.8,0.2h0c-5.9,0-10.7-4.8-10.7-10.7  c0-5.3,3.8-9.7,9-10.6c0.6-0.1,1.1-0.1,1.7-0.1c0.8,0,1.5,0.1,2.3,0.2l0.5,0.1c5.7,1.5,9.1,7.4,7.6,13.1  C73.5,37.6,70.6,40.5,66.8,41.5L66.8,41.5z M67,41.9L67,41.9z M72.3,33.8v-5.4c0-0.7-0.5-1.3-1.2-1.4l-2.8,0v-2.6  c0-0.8-0.6-1.4-1.4-1.4h-5.4c-0.8,0-1.4,0.6-1.4,1.4v2.6h-2.6c-0.8,0-1.4,0.6-1.4,1.4v5.4c0,0.8,0.6,1.4,1.4,1.4h2.6v2.6  c0,0.8,0.6,1.4,1.4,1.4h5.4c0.8,0,1.4-0.6,1.4-1.4v-2.6h2.6C71.7,35.2,72.3,34.6,72.3,33.8L72.3,33.8z M61.5,32.4h-2.6v-2.6h2.6  c0.8,0,1.4-0.6,1.4-1.4v-2.6h2.6v2.6c0,0.8,0.6,1.4,1.4,1.4h2.6v2.6h-2.6c-0.8,0-1.4,0.6-1.4,1.4v2.6h-2.6v-2.6  C62.9,33.1,62.2,32.4,61.5,32.4L61.5,32.4z M31.1,32.8c0-0.8,0.7-1.5,1.5-1.5H47c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5H32.6  C31.8,34.4,31.1,33.7,31.1,32.8L31.1,32.8z M31.1,39.7c0-0.8,0.7-1.5,1.5-1.5H47c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5H32.6  C31.8,41.2,31.1,40.5,31.1,39.7L31.1,39.7z M32.6,45h23.4c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5H32.6c-0.8,0-1.5-0.7-1.5-1.5  C31.1,45.7,31.8,45,32.6,45L32.6,45z M57.6,53.4c0,0.8-0.7,1.5-1.5,1.5H32.6c-0.8,0-1.6-0.6-1.6-1.5c0-0.8,0.6-1.6,1.5-1.6  c0,0,0.1,0,0.1,0h23.4C56.9,51.9,57.6,52.6,57.6,53.4L57.6,53.4z M57.6,60.3c0,0.8-0.7,1.5-1.5,1.5H32.6c-0.8,0-1.6-0.6-1.6-1.5  c0-0.8,0.6-1.6,1.5-1.6c0,0,0.1,0,0.1,0h23.4C56.9,58.8,57.6,59.5,57.6,60.3L57.6,60.3z M57.6,67.2c0,0.8-0.7,1.5-1.5,1.5H32.6  c-0.8,0-1.5-0.6-1.6-1.5c0-0.8,0.6-1.5,1.5-1.6c0,0,0.1,0,0.1,0h23.4C56.9,65.6,57.6,66.3,57.6,67.2L57.6,67.2z"
            ></path>
        </svg>
    )
}
import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { closeSidebar, toggleMenuEducacion } from '../store/actions'

export default function SubMenuEducacion(props) {

  const sections = useSelector((state) => state.sections.sections, shallowEqual)
  const subMenuEducacionClass = useSelector((state) => state.menu.subMenuEducacion, shallowEqual)
  const selectedCountry = useSelector((state)=> state.country.selectedCountry);
  const dispatch = useDispatch();

  const handleCloseSidebar = () => {
    dispatch(closeSidebar())
  }

  const handleToggleMenu = () => {
    dispatch(toggleMenuEducacion(subMenuEducacionClass))
  }

  console.log('sections:',sections)
  console.log('selected country:',selectedCountry.toLowerCase())

  return (
    <nav className={subMenuEducacionClass} id="navigation">
      {props.sections.length && (
        <div className="menu-principal-container">
          <div className="sub-menu-container-educacion">
            <h1 className="sub-menu-title">EDUCACIÓN</h1>
            <div className="sub-menu-ss2020-container">
              <ul id="menu-ss2020" className="sub-menu-slider-right">
                {sections.filter((section) => section['parent_id_section'] === '14' && section['status'] === '1' && section[selectedCountry.toLowerCase()] == '1').sort(function (a, b) {
                  return (a['field_order'] - b['field_order'])
                }).map((section) => (
                  <li key={section['id_section']} className="sub-menu-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      onClick={handleCloseSidebar}
                      title={section['name']}
                      to={`/educacion/${section['slug']}`}
                    >
                      {section['name']}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div
              onClick={handleToggleMenu}
              className="swiper-button-prev swiper-button-white"
            >
              <h2 className="sub-menu-educacion-back-button">
                <a onClick={handleToggleMenu}>Volver</a>
              </h2>
            </div>
            <div className="menu-redes">
              {" "}
              <a
                href="https://www.instagram.com/otgwesteurope/"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>{" "}
              <a
                href="https://www.facebook.com/OTG.West.Europe/"
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>{" "}
              <a
                href="https://www.youtube.com/user/OTGWestEurope"
                target="_blank"
              >
                <i className="fab fa-youtube"></i>
              </a>{" "}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser';
import { Link } from 'react-router-dom';
import NavbarInterna from './NavbarInterna';
import RedesIconos from './IconosRedesInterna';
import RecursosButton from './RecursosButton';
import SubMenuRecursos from './SubMenuRecursos'
import ShareButton from './ShareButtonComponent';
import MenuEducacion from './MenuEducacion';
import HelmetMetaData from './HelmetMetaData'
import Menu from './Menu';
import Button from './ButtonMenu';
import Footer from './Footer';
import { codigoPacientes } from '../codigos';
import YouTube from 'react-youtube-embed';
import Recursos from './RecursosComponent';
import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL, URL } from '../store/constants'
import { tracking } from '../store/actions/user'
import Helmet from "react-helmet";
import ReactGa from 'react-ga'

export default function Nota(props) {
  const userState = useSelector((state) => state.user.authState, shallowEqual);
  const user = useSelector((state) => state.user.authData, shallowEqual);

  const overlayClass = useSelector((state) => state.menu.overlayClass, shallowEqual)
  const internaClass = useSelector((state) => state.menu.internaClass, shallowEqual)
  const educationSection = useSelector((state) => state.sections.sections.filter((section) => section['parent_id_section'] == '14' && section['status'] == '1')).sort(function (a, b) {
    return (a['field_order'] - b['field_order'])
  })
  const selectedCountry = useSelector((state)=>state.country.selectedCountry)
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let filterNotesSection = function (array, note) {
    let arr = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].id_note !== note.id_note) {
        arr.push(array[i]);
      }
    }
    return arr;
  };

  const testimoniosArray = useSelector((state) =>
    state.notes.notes.filter((note) => note.id_section == 25),
  );


  const [showNextNote, setShowNextNote] = useState(true);

  const handleTrueShowNextNote = () => setShowNextNote(true);

  const selectNextNote = () => {
    let indexNote = props.notesSection.indexOf(props.note);
    let nextNote = props.notesSection[indexNote + 1];
    if (nextNote) {
      return nextNote.slug;
    } else {
      setShowNextNote(false);
    }
  };

  const allNotes = useSelector((state) => state.notes.notes);

  const matchNote = props.props.match.params.slug

  const Note = allNotes.filter((note) => note['slug'] === matchNote)[0]


  const generateArraySections = (array, property) => {
    let newArray = []
    array.map(section => {
      newArray.push(Number(section[property]))
    })
    return newArray
  }

  const idSectionsArray = generateArraySections(educationSection, 'id_section')

  const selectNextSection = () => {
    let nextSection = Number(props.note['id_section']) + 1
    while (!idSectionsArray.includes(nextSection)) {
      nextSection++
    }

    let nextNoteSection = allNotes.filter(note => note['id_section'] == nextSection)

    let nextSlug;
    if (nextNoteSection.length > 0) {
      nextSlug = '/nota/' + nextNoteSection[0]['slug']
    }
    if (nextSlug) {
      return nextSlug
    } else {
      return '/educacion/recursos-pacientes'
    }
  };



  useEffect(() => {
    if (userState) {
      console.log('TRACKING')
      tracking(
        user['user_id'],
        props.note['section']['id_section'],
        props.note['id_note'],
        user['name'],
        window.location.href
      );
    }
    if (Note) {
      ReactGa.pageview(`/nota/${Note['slug']}`)
    }
  }, [matchNote]);

  return (
    <div className={internaClass}>
      <HelmetMetaData
        title={props.note && props.note.name}
        quote={props.note && props.note.copete}
      >
      </HelmetMetaData>
      <div className={overlayClass}></div>
      <header>
        <div id="header-main" className="margin-bottom-7">
          <NavbarInterna props={props} />
        </div>
        <Menu />
      </header>
      <Button />
      {Note && (
        <div>
          {Note['resources'].length > 0 && (
            <RecursosButton />
          )}
        </div>
      )}

      <RedesIconos />
      <section id="nota" className="nota">
        <div className="container">
          {props.note && (
            <div style={{ paddingRight: '1rem' }} className="div">
              {props.note.id_section == 24 && (
                <p className="miga-de-pan seccion text-center texto-verde">
                  <Link
                    style={{ textDecoration: 'none', color: '#35aea9' }}
                    to="/">
                    ASMA ZERO{' '}
                  </Link>{' '}
                    /{' '}
                  <Link
                    style={{ textDecoration: 'none', color: '#35aea9' }}
                    to="/diario-asmazero">
                    DIARIO ASMA ZERO{' '}
                  </Link>
                </p>
              )}
            </div>
          )}
          {props.note && (
            <div style={{ paddingRight: '1rem' }} className="div">
              {props.note.id_section != 24 && (
                <p className="seccion text-center texto-verde">
                  <Link
                    style={{ textDecoration: 'none', color: '#35aea9' }}
                    to="/">
                    ASMA ZERO{' '}
                  </Link>{' '}
                    /{' '}
                  <Link
                    style={{ textDecoration: 'none', color: '#35aea9' }}
                    to="/educacion">
                    EDUCACION{' '}
                  </Link>
                    /{' '}

                  <Link
                    style={{ textDecoration: 'none', color: '#35aea9' }}
                    to={`/educacion/${props['note']['section']['slug']}`}>
                    {props.titleSection.title}{' '}
                  </Link>

                </p>
              )}
            </div>
          )}
          <div className="row">
            {props.note && (
              <div className="nota-title col-md-12">
                <h1 className="title-nota text-center ">{props.note.name}</h1>
              </div>
            )}
            {props.note && (
              <div className="article col-md-9">
                <article>
                  <div className="author-detail-container">
                    {' '}
                    {props.note.author.file ? (
                      <img
                        className="imagen-perfil-nota"
                        src={`${API_MATERIAL}/notes_authors/big/${props.note.author['file']}`}
                        alt=""
                      />
                    ) : (
                        <img
                          className="imagen-perfil-nota"
                          src="/img/user-doctor.png"
                          alt=""
                        />
                      )}
                    <div className="perfil-detail">
                      <strong className="name-autor">
                        {props.note.author.name}
                      </strong>
                      <br></br> {ReactHtmlParser(props.note.author.detail)}
                      <br></br>
                    </div>
                    <div href="#" className="compartir-nota">
                      <ShareButton
                        id="button-compartir"
                        body={'Para ver la nota haga clic en la siguiente URL '}
                        url={`${URL}nota/${props.note['slug']}`}
                        quote={props.note.name}
                        facebook={true}
                        twitter={true}
                        email={true}
                        whatsapp={true}
                      />
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="offset-md-2 col-md-10">
                      <p
                        className="nota-copete"
                        style={{ color: '#10144b', fontSize: '1.3rem' }}>
                        {ReactHtmlParser(props.note.copete)}{' '}
                      </p>
                      <div className="nota-video">
                        {props.note.video && (
                          <YouTube
                            className="video-yotube-responsive"
                            aspectRatio="16:9"
                            id={props.note.video.slice(82, 93)}
                          />
                        )}
                      </div>
                      <div id="nota-detail">
                        {ReactHtmlParser(props.note.detail)}
                      </div>
                    </div>
                  </div>
                </article>
                {showNextNote ? (
                  <div className="proxima-nota-button">
                    <Link to={`/nota/${selectNextNote()}`}>
                      <button>Próxima Nota</button>
                    </Link>
                  </div>
                ) : (
                    <div className="div">
                      {props.note.id_section != '24' && (
                        <div className="proxima-nota-button">
                          <Link
                            onClick={handleTrueShowNextNote}
                            to={selectNextSection()}>
                            <button>Próxima Sección</button>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
            <div className="col-md-3 sidebar">
              {props.note && (
                <div>
                  {props.note.resources.length > 0 && (
                    <Recursos
                      slug={'educacion/recursos-para-pacientes'}
                      resources={props.note.resources}
                    />
                  )}
                </div>
              )}
              {props.note && (
                <div>
                  {props.note.id_section == 24 ? (
                    <div>
                      <Link
                        style={{
                          color: '#840729',
                        }}
                        to="/diario-asmazero">
                        <h4>MÁS NOTAS</h4>
                      </Link>
                      <div className="mas-notas">
                        {props.note &&
                          filterNotesSection(
                            props.notesSection,
                            props.note,
                          ).filter(note=> note[selectedCountry.toLowerCase()] == '1').map((note) => (
                            <div className="note">
                              <Link
                                style={{
                                  color: '#10144b',
                                }}
                                to={`/nota/${note['slug']}`}
                                onClick={handleTrueShowNextNote}>
                                <h1>{note.name}</h1>
                              </Link>
                              <p className="texto-bordo">{note.author.name}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                      <div>
                        {props.notesSection.length > 1 && (
                          <Link
                            style={{
                              color: '#840729',
                            }}
                            to={`/educacion/${props.notesSection[0]['section']['slug']}`}>
                            <h4>EN ESTA SECCION</h4>
                          </Link>
                        )}
                        <div className="mas-notas">
                          {props.note &&
                            filterNotesSection(
                              props.notesSection,
                              props.note,
                            ).filter(note=> note[selectedCountry.toLowerCase()] == '1').map((note) => (
                              <div className="note">
                                <Link
                                  onClick={handleTrueShowNextNote}
                                  style={{
                                    color: '#10144b',
                                  }}
                                  to={`/nota/${note['slug']}`}>
                                  <h1>{note.name}</h1>
                                </Link>
                                <p className="texto-bordo">{note.author.name}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                </div>
              )}
              {props.note && (
                <div>
                  {props.note.id_section != 24 && (
                    <MenuEducacion matchSection={props.note.section.slug} />
                  )}
                </div>
              )}

              {props.note && (
                <div>
                  {props.note.id_section == 24 && (
                    <div>
                      <Link
                        style={{
                          color: '#840729',
                        }}
                        to="/testimonios">
                        <h4>TESTIMONIOS</h4>
                      </Link>
                      {testimoniosArray.length > 0 && (
                        <div className="testimonios">
                          {testimoniosArray.map((testimonio) => (
                            <div className="testimonio">
                              <div className="imagen">
                                {' '}
                                <Link
                                  to={`/testimonios/${testimonio['author']['name']}`}>
                                  <img
                                    src={`${API_MATERIAL}/notes_authors/big/${testimonio['author']['file']}`}></img>{' '}
                                </Link>
                              </div>
                              <div className="texto">
                                <Link
                                  style={{
                                    color: '#10144b',
                                  }}
                                  to={`/testimonios/${testimonio['author']['name']}`}>
                                  <h5 className="texto-azul">
                                    {testimonio['author']['name']}
                                  </h5>
                                </Link>
                                <p>{ReactHtmlParser(testimonio['detail'])}</p>
                              </div>

                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {Note && (
          <SubMenuRecursos
            resources={Note['resources']}
            background={"#009993"}
          />

        )}
      </section>
      <Footer codigo={codigoPacientes} />
    </div>
  );
}

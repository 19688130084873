import {
    SET_MENU_CLASS
} from "../constants";

const initialState = {
    homeClass: 'home',
    internaClass: 'interna',
    overlayClass: 'overlay',
    buttonTogglerClass: 'navbar-toggler',
    menuClass: 'menu-principal',
    subMenuEducacion: 'sub-menu-educacion',
    subMenuHerramientas: 'sub-menu-herramientas',
    subMenuQuienesSomos: 'sub-menu-qs',
    subMenuDiario: 'sub-menu-diario',
    subMenuEntrenamiento: 'sub-menu-entrenamiento',
    subMenuRecursos: 'sub-menu-recursos',
    subMenuAsmaE: 'sub-menu-asma-e'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU_CLASS:
            return {
                ...state, ...action.data
            }
            default:
                return state;
    }
};